import React, { useEffect, useRef, useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Input,
    Flex,
    Heading,
    Textarea,
    Box,
    Tag,
    TagLabel,
    IconButton,
    Text,
    TagCloseButton,
    VStack,
    FormErrorMessage,
    Avatar,
    Image,
    Select,
} from "@chakra-ui/react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useClient } from "../../../context/ClientContext";
import { EditIcon } from "@chakra-ui/icons";
import { useEnv } from "../../../context/EnvContext";
import { CloseIcon } from "@chakra-ui/icons";

// Validation schema for main ClientForm
const validationSchema = Yup.object({
    name: Yup.string().required("* Name is required"),
    email: Yup.string().email("* Invalid email format").required("* Email is required"),
    contactNo: Yup.string().required("* Contact number is required"),
    noOfEmp: Yup.string(),
    estd: Yup.string(),
    details: Yup.string(),
    address: Yup.string(),
    country: Yup.string(),
    state: Yup.string(),
    city: Yup.string(),
    pincode: Yup.string(),
    contacts: Yup.array()
        .of(
            Yup.object().shape({
                name: Yup.string().required("* Name is required"),
                email: Yup.string().email("* Invalid email format").required("* Email is required"),
                contactNo: Yup.string().required("* Contact number is required"),
                role: Yup.string().required("* Role is required"),
            })
        )
        .min(1, "* At least one contact is required"),
});

// Validation schema for ContactsInput
const contactValidationSchema = Yup.object().shape({
    name: Yup.string().required("* Name is required"),
    email: Yup.string().email("* Invalid email format").required("* Email is required"),
    contactNo: Yup.string().required("* Contact number is required"),
    role: Yup.string().required("* Role is required"),
});

const ContactsInput = ({ contacts, setContacts }) => {
    const [inputValue, setInputValue] = useState({
        name: "",
        email: "",
        contactNo: "",
        role: "",
    });

    const [errors, setErrors] = useState({});
    const [editIndex, setEditIndex] = useState(-1);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({ ...prev, [name]: value }));

        // Validate the specific field on change
        try {
            contactValidationSchema.validateSyncAt(name, { [name]: value });
            setErrors((prev) => ({ ...prev, [name]: "" }));
        } catch (validationError) {
            setErrors((prev) => ({ ...prev, [name]: validationError.message }));
        }
    };

    const handleSubmit = () => {
        // Validate all fields before submission
        try {
            contactValidationSchema.validateSync(inputValue, { abortEarly: false });
            setErrors({});
            if (editIndex === -1) {
                setContacts([...contacts, inputValue]);
            } else {
                const updatedContacts = contacts.map((contact, index) =>
                    index === editIndex ? inputValue : contact
                );
                setContacts(updatedContacts);
                setEditIndex(-1);
            }
            handleClear();
        } catch (validationErrors) {
            const formattedErrors = {};
            validationErrors.inner.forEach((error) => {
                formattedErrors[error.path] = error.message;
            });
            setErrors(formattedErrors);
        }
    };

    const handleClear = () => {
        setEditIndex(-1);
        setInputValue({
            name: "",
            email: "",
            contactNo: "",
            role: "",
        });
        setErrors({});
    };

    const removeContact = (contactToRemove) => {
        setContacts(contacts.filter((contact) => contact !== contactToRemove));
    };

    const editContact = (index) => {
        setInputValue(contacts[index]);
        setEditIndex(index);
    };

    return (
        <FormControl id="contacts">
            <FormLabel mb={1} mt={2}>
                Handler Contacts
            </FormLabel>
            <Box p={2} border="1px" borderColor={"inherit"} borderRadius={"md"}>
                <VStack spacing={1} align="stretch">
                    <Flex gap={2}>
                        <FormControl id="name" isInvalid={!!errors.name}>
                            <FormLabel mb={1}>Name</FormLabel>
                            <Input
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={inputValue.name}
                                onChange={handleInputChange}
                            />
                            <FormErrorMessage fontSize={"xs"}>{errors.name}</FormErrorMessage>
                        </FormControl>
                        <FormControl id="role" isInvalid={!!errors.role}>
                            <FormLabel mb={1}>Role</FormLabel>
                            <Input
                                type="text"
                                name="role"
                                placeholder="Role"
                                value={inputValue.role}
                                onChange={handleInputChange}
                            />
                            <FormErrorMessage fontSize={"xs"}>{errors.role}</FormErrorMessage>
                        </FormControl>
                    </Flex>

                    <Flex gap={2} mb={1}>
                        <FormControl id="email" isInvalid={!!errors.email} mt={2}>
                            <FormLabel mb={1}>Email</FormLabel>
                            <Input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={inputValue.email}
                                onChange={handleInputChange}
                            />
                            <FormErrorMessage fontSize={"xs"}>{errors.email}</FormErrorMessage>
                        </FormControl>
                        <FormControl id="contactNo" isInvalid={!!errors.contactNo} mt={2}>
                            <FormLabel mb={1}>Contact Number</FormLabel>
                            <Input
                                type="text"
                                name="contactNo"
                                placeholder="Contact Number"
                                value={inputValue.contactNo}
                                onChange={handleInputChange}
                            />
                            <FormErrorMessage fontSize={"xs"}>{errors.contactNo}</FormErrorMessage>
                        </FormControl>
                    </Flex>

                    {editIndex === -1 ? (
                        <Button
                            colorScheme="green"
                            size="xs"
                            style={{
                                borderRadius: "20px",
                                transition: "background 0.3s ease",
                            }}
                            onClick={handleSubmit}
                        >
                            Add Contact
                        </Button>
                    ) : (
                        <Flex gap={1} w={"100%"}>
                            <Button
                                flex={1}
                                onClick={handleClear}
                                colorScheme="red"
                                size="xs"
                                style={{
                                    borderRadius: "20px",
                                    transition: "background 0.3s ease",
                                }}
                            >
                                Cancel Update
                            </Button>
                            <Button
                                flex={1}
                                colorScheme="orange"
                                size="xs"
                                style={{
                                    borderRadius: "20px",
                                    transition: "background 0.3s ease",
                                }}
                                onClick={handleSubmit}
                            >
                                Update Contact
                            </Button>
                        </Flex>
                    )}

                    <Box mt={1}>
                        {contacts.map((contact, index) => (
                            <Tag
                                key={index}
                                size="md"
                                borderRadius="sm"
                                variant="solid"
                                colorScheme="teal"
                                m={1}
                                w={"100%"}
                            >
                                <TagLabel w={"100%"}>
                                    <Flex direction={"column"} py={2} w={"100%"}>
                                        <Text fontSize={"md"} fontWeight="bold">
                                            {contact.name}
                                        </Text>
                                        <Text fontSize={"sm"}>{contact.email}</Text>
                                        <Text fontSize={"sm"}>{contact.contactNo}</Text>
                                        <Text fontSize={"sm"}>{contact.role}</Text>
                                    </Flex>
                                </TagLabel>
                                <IconButton
                                    size="xs"
                                    icon={<EditIcon />}
                                    onClick={() => editContact(index)}
                                    m={1}
                                />
                                <IconButton
                                    size="xs"
                                    icon={<TagCloseButton m={0} />}
                                    onClick={() => removeContact(contact)}
                                    mt={1}
                                />
                            </Tag>
                        ))}
                    </Box>
                </VStack>
            </Box>
        </FormControl>
    );
};

const ClientForm = ({
    isOpen,
    onClose,
    operation = "Add Client",
    client = {
        name: "",
        email: "",
        contactNo: "",
        noOfEmp: "",
        estd: "",
        details: "",
        address: "",
        country: "",
        state: "",
        city: "",
        pincode: "",
        contacts: [],
    },
}) => {
    const { backendUrl } = useEnv();
    const { addClient, updateClient } = useClient();
    const [logo, setLogo] = useState(null);
    const [logoDisplay, setLogoDisplay] = useState(null);
    const fileInputRef = useRef(null);
    const [status, setStatus] = useState(null);

    useEffect(() => {
        if (operation == "Update Client") {
            if (client?.logo) setLogoDisplay(`${backendUrl}${client?.logo}`);
            setStatus(client.status);
        }
    }, [operation]);

    const handleDPChange = (e) => {
        const uploadedFile = e.target.files[0];
        setLogo(uploadedFile);
        setLogoDisplay(URL.createObjectURL(uploadedFile));
    };

    const resetImage = () => {
        setLogo(null);
        setLogoDisplay(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = ""; // Reset the file input
        }
    };

    const handleOverallSubmit = (formData) => {
        let updatedClient = {
            ...formData,
            logo: logo ? logo : client.logo,
        };

        // console.log("updatedClient", updatedClient);

        if (operation === "Update Client") {
            updatedClient = {
                ...formData,
                logo: logo ? logo : client.logo,
                status: status,
            };
            updateClient(updatedClient, client._id, client.status);
        } else {
            addClient(updatedClient);
        }
        onClose();
        // console.log("main handle submit called");
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
            <ModalOverlay />
            <ModalContent overflow={"clip"} my={4}>
                <ModalCloseButton top={3} />
                <ModalBody mt={0}>
                    <Flex justify={"center"} mb={2} mt={2}>
                        <Heading as="h3" size="md">
                            {operation}
                        </Heading>
                    </Flex>

                    {operation == "Update Client" && (
                        <FormControl id="status" display={"flex"}>
                            <FormLabel mb={1} w={"140px"}>
                                Current Status:
                            </FormLabel>
                            <Select
                                name="status"
                                value={status}
                                onChange={(e) => {
                                    setStatus(e.target.value);
                                }}
                            >
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                            </Select>
                        </FormControl>
                    )}
                    <Formik
                        initialValues={{ ...client }}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            handleOverallSubmit(values);
                        }}
                    >
                        {({ resetForm, values, setFieldValue }) => (
                            <Form>
                                <Flex gap={2}>
                                    <FormControl id="name">
                                        <FormLabel mb={1} mt={2}>
                                            Name
                                        </FormLabel>
                                        <Field name="name" as={Input} bg={"gray.700"} />
                                        <ErrorMessage
                                            name="name"
                                            component="div"
                                            className="text-red-400 text-xs mt-1"
                                        />
                                    </FormControl>
                                    <Flex width={"100%"} gap={2} align={"flex-end"}>
                                        <FormControl id="logo" flexGrow={1}>
                                            <FormLabel mb={1}>Passphoto</FormLabel>
                                            <Input
                                                type="file"
                                                name="logo"
                                                onChange={handleDPChange}
                                                paddingTop={"4px"}
                                                ref={fileInputRef}
                                            />
                                        </FormControl>
                                        {logoDisplay && (
                                            <Flex position="relative" h={16} w={16}>
                                                <Avatar size={"lg"} src={logoDisplay} />
                                                <Button
                                                    colorScheme="teal"
                                                    size="xs"
                                                    onClick={resetImage}
                                                    top={1}
                                                    right={"120%"}
                                                    position="absolute"
                                                    variant="link"
                                                >
                                                    Clear Logo
                                                </Button>
                                            </Flex>
                                        )}
                                    </Flex>
                                </Flex>

                                <Flex gap={2}>
                                    <FormControl id="contactNo">
                                        <FormLabel mb={1} mt={2}>
                                            Contact Number
                                        </FormLabel>
                                        <Field name="contactNo" as={Input} bg={"gray.700"} />
                                        <ErrorMessage
                                            name="contactNo"
                                            component="div"
                                            className="text-red-400 text-xs mt-1"
                                        />
                                    </FormControl>
                                    <FormControl id="email">
                                        <FormLabel mb={1} mt={2}>
                                            Email
                                        </FormLabel>
                                        <Field
                                            name="email"
                                            as={Input}
                                            type="email"
                                            bg={"gray.700"}
                                        />
                                        <ErrorMessage
                                            name="email"
                                            component="div"
                                            className="text-red-400 text-xs mt-1"
                                        />
                                    </FormControl>
                                </Flex>

                                <Flex gap={2}>
                                    <FormControl id="noOfEmp">
                                        <FormLabel mb={1} mt={2}>
                                            No. of Employees
                                        </FormLabel>
                                        <Field name="noOfEmp" as={Input} bg={"gray.700"} />
                                        <ErrorMessage
                                            name="noOfEmp"
                                            component="div"
                                            className="text-red-400 text-xs mt-1"
                                        />
                                    </FormControl>

                                    <FormControl id="estd">
                                        <FormLabel mb={1} mt={2}>
                                            Established Year
                                        </FormLabel>
                                        <Field name="estd" as={Input} bg={"gray.700"} />
                                        <ErrorMessage
                                            name="estd"
                                            component="div"
                                            className="text-red-400 text-xs mt-1"
                                        />
                                    </FormControl>
                                </Flex>
                                <FormControl id="details">
                                    <FormLabel mb={1} mt={2}>
                                        Details
                                    </FormLabel>
                                    <Field name="details" as={Textarea} bg={"gray.700"} />
                                    <ErrorMessage
                                        name="details"
                                        component="div"
                                        className="text-red-400 text-xs mt-1"
                                    />
                                </FormControl>

                                <FormControl id="address">
                                    <FormLabel mb={1} mt={2}>
                                        Address
                                    </FormLabel>
                                    <Field name="address" as={Textarea} rows={2} bg={"gray.700"} />
                                    <ErrorMessage
                                        name="address"
                                        component="div"
                                        className="text-red-400 text-xs mt-1"
                                    />
                                </FormControl>

                                <Flex gap={2}>
                                    <FormControl id="city">
                                        <FormLabel mb={1} mt={2}>
                                            City
                                        </FormLabel>
                                        <Field name="city" as={Input} bg={"gray.700"} />
                                        <ErrorMessage
                                            name="city"
                                            component="div"
                                            className="text-red-400 text-xs mt-1"
                                        />
                                    </FormControl>

                                    <FormControl id="state">
                                        <FormLabel mb={1} mt={2}>
                                            State
                                        </FormLabel>
                                        <Field name="state" as={Input} bg={"gray.700"} />
                                        <ErrorMessage
                                            name="state"
                                            component="div"
                                            className="text-red-400 text-xs mt-1"
                                        />
                                    </FormControl>
                                </Flex>

                                <Flex gap={2}>
                                    <FormControl id="country">
                                        <FormLabel mb={1} mt={2}>
                                            Country
                                        </FormLabel>
                                        <Field name="country" as={Input} bg={"gray.700"} />
                                        <ErrorMessage
                                            name="country"
                                            component="div"
                                            className="text-red-400 text-xs mt-1"
                                        />
                                    </FormControl>

                                    <FormControl id="pincode">
                                        <FormLabel mb={1} mt={2}>
                                            Pincode
                                        </FormLabel>
                                        <Field name="pincode" as={Input} bg={"gray.700"} />
                                        <ErrorMessage
                                            name="pincode"
                                            component="div"
                                            className="text-red-400 text-xs mt-1"
                                        />
                                    </FormControl>
                                </Flex>
                                <FormControl id="contacts">
                                    <ContactsInput
                                        contacts={values.contacts}
                                        setContacts={(contacts) =>
                                            setFieldValue("contacts", contacts)
                                        }
                                    />
                                    <ErrorMessage
                                        name="contacts"
                                        component="div"
                                        className="text-red-400 text-xs mt-1"
                                    />
                                </FormControl>

                                <Flex justify={"center"} mb={2} mt={6} w={"100%"} gap={2}>
                                    <Button
                                        w={"100%"}
                                        type="button"
                                        colorScheme="gray"
                                        size="sm"
                                        style={{
                                            borderRadius: "20px",
                                            transition: "background 0.3s ease",
                                        }}
                                        onClick={() => resetForm()}
                                    >
                                        Reset
                                    </Button>
                                    <Button
                                        w={"100%"}
                                        type="submit"
                                        colorScheme="blue"
                                        size="sm"
                                        style={{
                                            borderRadius: "20px",
                                            transition: "background 0.3s ease",
                                        }}
                                    >
                                        {operation}
                                    </Button>
                                </Flex>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ClientForm;
