import axios from "axios";
import React, { createContext, useState, useContext, useMemo } from "react";
import { useEnv } from "./EnvContext";
import { useAuth } from "./AuthContext";
import useGlobalToast from "../Utils/GlobalFunctions/toast";
import { getStatusCtoS, getStatusStoC } from "../Utils/GlobalFunctions/GetStatus";

const JDContext = createContext();

export const JDContextProvider = ({ children }) => {
    const toast = useGlobalToast();
    const { backendUrl } = useEnv();
    const { userData } = useAuth();

    const [jobDescriptionData, setJobDescriptionData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingJD, setLoadingJD] = useState(false);
    const [error, setError] = useState(null);

    // handle matching candidates
    const [allMatchingJDs, setAllMatchingJDs] = useState([]);
    const [hasMoreMatchingJDs, setHasMoreMatchingJDs] = useState(true);
    const [matchingJDsPageNo, setMatchingJDsPageNo] = useState(1);

    // handle filtering JDs
    const [filteredJDs, setFilteredJDs] = useState([]);
    const [hasMoreFilteredJDs, setHasMoreFilteredJDs] = useState(true);
    const [filteredJDsPageNo, setFilteredJDsPageNo] = useState(1);

    // handle JDs
    const [allJDs, setAllJDs] = useState({
        all: [],
        closed: [],
        active: [],
        onHold: [],
        cancelled: [],
    });
    const [hasMoreJDs, setHasMoreJDs] = useState({
        all: true,
        closed: true,
        active: true,
        onHold: true,
        cancelled: true,
    });
    const [jDsPageNo, setJDsPageNo] = useState({
        all: 1,
        closed: 1,
        active: 1,
        onHold: 1,
        cancelled: 1,
    });

    const memoizedJDs = useMemo(() => allJDs, [allJDs]);
    const memoizedFilteredJds = useMemo(() => filteredJDs, [filteredJDs]);

    // Fetch All Matching JDs
    const fetchMatchingJDs = async (page = 1, limit = 30, updateData = false, candidateId) => {
        setLoading(true);
        try {
            const token = userData?.token;
            const response = await axios.get(
                `${backendUrl}/jd/all-percentage?candidateId=${candidateId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        page,
                        limit,
                    },
                }
            );

            const fetchedJDs = response.data;
            console.log("fetchedJDs", fetchedJDs);

            if (updateData) {
                if (fetchedJDs.length <= 0) {
                    setHasMoreMatchingJDs(false);
                } else {
                    setAllMatchingJDs((prevJDs) => [...prevJDs, ...fetchedJDs]);
                    setMatchingJDsPageNo((prevPage) => prevPage + 1);
                }
            } else {
                setAllMatchingJDs(fetchedJDs);
                setMatchingJDsPageNo(2);
                setHasMoreMatchingJDs(true);
            }

            if (fetchedJDs.length <= limit) {
                setHasMoreMatchingJDs(false);
            }

            toast.success(`JDs fetched successfully!`);
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch all job descriptions with infinite scrolling
    const fetchAllJD = async (status, page = 1, limit = 30, updateData = false) => {
        setLoading(true);
        try {
            if (!updateData && memoizedJDs[status].length > 0) {
                setLoading(false);
                return;
            }

            let requestStatus = getStatusStoC(status);

            const token = userData?.token;
            const response = await axios.get(`${backendUrl}/jd/all`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    status: requestStatus,
                    page,
                    limit,
                },
            });

            const fetchedJDs = response.data;
            console.log("fetchedJDs", fetchedJDs);

            if (updateData) {
                setAllJDs((prevState) => ({
                    ...prevState,
                    [status]: [...prevState[status], ...fetchedJDs],
                }));

                setJDsPageNo((prevState) => ({
                    ...prevState,
                    [status]: prevState[status] + 1,
                }));

                if (fetchedJDs.length < limit) {
                    setHasMoreJDs((prevState) => ({
                        ...prevState,
                        [status]: false,
                    }));
                }
            } else {
                setAllJDs((prevState) => ({
                    ...prevState,
                    [status]: fetchedJDs,
                }));

                setJDsPageNo((prevState) => ({
                    ...prevState,
                    [status]: 2,
                }));

                setHasMoreJDs((prevState) => ({
                    ...prevState,
                    [status]: fetchedJDs.length === limit,
                }));
            }

            toast.success(`${status} JDs fetched successfully!`);
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch job description by ID
    const fetchJDById = async (jdId) => {
        console.log("fetching candidate ", jdId);
        setLoading(true);
        setError(null);
        try {
            const token = userData?.token;
            const response = await axios.get(`${backendUrl}/jd`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    jdId,
                },
            });

            const fetchedJD = response.data;
            console.log("fetchedJD", fetchedJD);
            setJobDescriptionData(fetchedJD);

            // toast.success("Job description fetched successfully!");
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    // Add JD
    const addJD = async (jd) => {
        setLoading(true);
        setError(null);

        try {
            const token = userData?.token;
            const response = await axios.post(`${backendUrl}/jd`, jd, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const addJDRes = response.data;
            console.log("addJDRes", addJDRes);
            const addedJD = addJDRes.jd;

            const status = getStatusCtoS(addedJD.status);

            setAllJDs((prevState) => ({
                ...prevState,
                [status]: [addedJD, ...prevState[status]],
                all: [...prevState.all, addedJD],
            }));

            toast.success("New JD Added Successfully!");
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    // Update JD
    const updateJD = async (jd, jdId, jdPrevStatus) => {
        setLoading(true);
        setError(null);
        try {
            const token = userData?.token;
            const response = await axios.put(`${backendUrl}/jd?jdId=${jdId}`, jd, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const updateJDRes = response.data;
            console.log("Update jd response", response);

            const updatedJDData = updateJDRes.jd;
            const currStatus = getStatusCtoS(jd.status);
            const prevStatus = getStatusCtoS(jdPrevStatus);

            setAllJDs((prevJDs) => {
                const updatedJDs = { ...prevJDs };

                // Update the 'all' array
                updatedJDs.all = prevJDs.all.map((jd) => (jd._id === jdId ? updatedJDData : jd));

                if (currStatus == prevStatus) {
                    // Update the 'current status' array
                    updatedJDs[currStatus] = prevJDs[currStatus].map((jd) =>
                        jd._id === jdId ? updatedJDData : jd
                    );
                } else {
                    // Remove from Previous Staus array
                    updatedJDs[prevStatus] = prevJDs[prevStatus].filter((jd) => jd._id !== jdId);

                    // Add to current Status array
                    if (updatedJDs[currStatus]?.length > 0)
                        updatedJDs[currStatus] = [updatedJDData, ...prevJDs[currStatus]];
                }

                return updatedJDs;
            });

            toast.success("JD Updated Successfully!");
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    // Delete JD
    const deleteJD = async (jdId, jdPreviousStatus) => {
        // Prompt the user for confirmation
        const confirmation = window.prompt('Type "delete jd" to confirm the deletion:');

        if (confirmation !== "delete jd") {
            toast.error("Deletion cancelled or incorrect phrase entered.");
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const token = userData?.token;
            const response = await axios.delete(`${backendUrl}/jd?jdId=${jdId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log("Delete jd response", response.data);
            const status = getStatusCtoS(jdPreviousStatus);

            setAllJDs((prevJDs) => {
                const updatedJDs = { ...prevJDs };

                // Update the 'all' array
                updatedJDs.all = prevJDs.all.filter((jd) => jd._id !== jdId);

                // Update the 'current status' array
                updatedJDs[status] = prevJDs[status].filter((jd) => jd._id !== jdId);
                return updatedJDs;
            });

            toast.success("JD Deleted Successfully!");
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch filtered job descriptions
    const fetchFilteredJDs = async (
        filters,
        page = 1,
        limit = 30,
        forceFetch = false,
        updateData = false
    ) => {
        setLoading(true);
        setError(null);
        try {
            // If no forceFetch and filteredJDs already exist, return existing data
            if (!forceFetch && memoizedFilteredJds.length > 0 && page === 1) {
                setLoading(false);
                return;
            }

            const token = userData?.token;
            const response = await axios.get(`${backendUrl}/jd/filter`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    ...filters,
                    page,
                    limit,
                },
            });

            const fetchedJDs = response.data;
            console.log("fetchedFilteredJDs", fetchedJDs);

            if (page === 1 || forceFetch) {
                // If it's a fresh fetch or forced fetch, replace filteredJDs
                setFilteredJDs(fetchedJDs);
                setFilteredJDsPageNo(2);
                setHasMoreFilteredJDs(fetchedJDs.length === limit);
            } else {
                // Append to the existing filteredJDs
                setFilteredJDs((prev) => [...prev, ...fetchedJDs]);
                setFilteredJDsPageNo((prevPage) => prevPage + 1);
                setHasMoreFilteredJDs(fetchedJDs.length === limit);
            }

            toast.success("Filtered JDs fetched successfully!");
        } catch (err) {
            console.log("Error", err);
            let error = err.response?.data?.message || err.message || "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <JDContext.Provider
            value={{
                jobDescriptionData,
                setJobDescriptionData,
                loading,
                error,
                fetchAllJD,
                fetchJDById,
                allMatchingJDs,
                hasMoreMatchingJDs,
                matchingJDsPageNo,
                setAllMatchingJDs,
                fetchMatchingJDs,
                allJDs,
                hasMoreJDs,
                jDsPageNo,
                addJD,
                updateJD,
                deleteJD,
                fetchFilteredJDs,
                hasMoreFilteredJDs,
                filteredJDsPageNo,
                setFilteredJDsPageNo,
            }}
        >
            {children}
        </JDContext.Provider>
    );
};

export const useJD = () => {
    return useContext(JDContext);
};
