import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Tabs, TabList, TabPanels, Tab, TabPanel, Text, Icon } from "@chakra-ui/react";

import JDHeader from "./JDHeader";
import JobDescription from "./JobDescription";
import { tagColor } from "../../../Utils/GlobalFunctions/TagColor";
import { useJD } from "../../../context/JDContext";
import { useAuth } from "../../../context/AuthContext";
import DashboardJobDescription from "./DashboardJobDescription";
import { TfiDashboard } from "react-icons/tfi";
import { ImStatsBars } from "react-icons/im";
import { FaFilter } from "react-icons/fa";

const JdContainer = () => {
    const { fetchAllJD, loading, allJDs, hasMoreJDs, jDsPageNo } = useJD();
    const { userData } = useAuth();

    const [tabIndex, setTabIndex] = useState(0);
    const [showDashJDTab, setShowDashJDTab] = useState(false);
    const { status } = useParams();
    const navigate = useNavigate();
    const { pathname, state } = useLocation();
    const initialPath = pathname.split("/")[1];
    const { jdList } = state || [];

    console.log("jdList", jdList);
    useEffect(() => {
        console.log("userData**", userData);
        // if (!userData?.token) return;
        switch (status) {
            case "All":
                fetchAllJD("all", 1, 30);
                setTabIndex(0);
                break;
            case "Closed":
                fetchAllJD("closed", 1, 30);
                setTabIndex(1);
                break;
            case "Active":
                fetchAllJD("active", 1, 30);
                setTabIndex(2);
                break;
            case "OnHold":
                fetchAllJD("onHold", 1, 30);
                setTabIndex(3);
                break;
            case "Cancelled":
                fetchAllJD("cancelled", 1, 30);
                setTabIndex(4);
                break;
            case "dashboardData":
                setTabIndex(5);
                setShowDashJDTab(true);
                break;
            default:
                fetchAllJD("active", 1, 30);
                setTabIndex(2);
                break;
        }
    }, [status]);

    const handleTabsChange = (index) => {
        setShowDashJDTab(false);
        setTabIndex(index);
        const tabMapping = ["All", "Closed", "Active", "OnHold", "Cancelled"];
        navigate(`/${initialPath}/JobDescriptions/${tabMapping[index]}`);
    };

    const jobDescriptions = {};
    const [filteredData, setFilteredData] = useState([]);

    const filterData = (query) => {
        if (!jobDescriptions?.length > 0) return;
        console.log("query", query);
        if (!query) {
            setFilteredData(jobDescriptions);
            return;
        }

        const filtered = jobDescriptions?.filter((item) => {
            return deepSearch(item, query);
        });

        console.log("filtered", filtered);
        setFilteredData(filtered);
    };

    const deepSearch = (obj, query) => {
        for (const key in obj) {
            const value = obj[key];
            if (typeof value === "object") {
                if (deepSearch(value, query)) {
                    return true;
                }
            } else if (
                typeof value === "string" ||
                typeof value === "number" ||
                typeof value === "boolean"
            ) {
                if (String(value).toLowerCase().includes(query.toLowerCase())) {
                    return true;
                }
            } else if (Array.isArray(value)) {
                for (const item of value) {
                    if (typeof item === "object") {
                        if (deepSearch(item, query)) {
                            return true;
                        }
                    } else if (
                        typeof item === "string" ||
                        typeof item === "number" ||
                        typeof item === "boolean"
                    ) {
                        if (String(item).toLowerCase().includes(query.toLowerCase())) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    };

    const filterByStatus = (status) => {
        return filteredData.filter((item) => item.status === status);
    };

    useEffect(() => {
        if (jobDescriptions?.length > 0) setFilteredData(jobDescriptions);
    }, [jobDescriptions]);

    return (
        <>
            <Tabs isLazy variant="enclosed" index={tabIndex} onChange={handleTabsChange}>
                <JDHeader filterData={filterData} initialPath={initialPath} />
                <TabList>
                    <Tab>
                        <Text
                            bg={tagColor("").bg}
                            color={tagColor("").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            All
                        </Text>
                    </Tab>
                    <Tab>
                        <Text
                            bg={tagColor("green").bg}
                            color={tagColor("green").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            Closed
                        </Text>
                    </Tab>
                    <Tab>
                        <Text
                            bg={tagColor("blue").bg}
                            color={tagColor("blue").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            Active
                        </Text>
                    </Tab>
                    <Tab>
                        <Text
                            bg={tagColor("orange").bg}
                            color={tagColor("orange").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            On Hold
                        </Text>
                    </Tab>
                    <Tab>
                        <Text
                            bg={tagColor("red").bg}
                            color={tagColor("red").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            Cancelled
                        </Text>
                    </Tab>
                    {/* <Tab>
                        <Text
                            bg={tagColor("purple").bg}
                            color={tagColor("purple").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                            display={"flex"}
                            alignItems={"center"}
                            gap={1}
                        >
                            Filter
                            <FaFilter fontSize={"11px"} />
                        </Text>
                    </Tab> */}
                    {showDashJDTab && (
                        <Tab>
                            <Icon as={ImStatsBars} fontSize={"24px"} />
                        </Tab>
                    )}
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <JobDescription
                            data={allJDs.all}
                            fetchAllJD={fetchAllJD}
                            hasMore={hasMoreJDs.all}
                            pageNo={jDsPageNo.all}
                            status={"all"}
                            loading={loading}
                        />
                    </TabPanel>
                    <TabPanel>
                        <JobDescription
                            data={allJDs.closed}
                            fetchAllJD={fetchAllJD}
                            hasMore={hasMoreJDs.closed}
                            pageNo={jDsPageNo.closed}
                            status={"closed"}
                            loading={loading}
                        />
                    </TabPanel>
                    <TabPanel>
                        <JobDescription
                            data={allJDs.active}
                            fetchAllJD={fetchAllJD}
                            hasMore={hasMoreJDs.active}
                            pageNo={jDsPageNo.active}
                            status={"active"}
                            loading={loading}
                        />
                    </TabPanel>
                    <TabPanel>
                        <JobDescription
                            data={allJDs.onHold}
                            fetchAllJD={fetchAllJD}
                            hasMore={hasMoreJDs.onHold}
                            pageNo={jDsPageNo.onHold}
                            status={"onHold"}
                            loading={loading}
                        />
                    </TabPanel>
                    <TabPanel>
                        <JobDescription
                            data={allJDs.cancelled}
                            fetchAllJD={fetchAllJD}
                            hasMore={hasMoreJDs.cancelled}
                            pageNo={jDsPageNo.cancelled}
                            status={"cancelled"}
                            loading={loading}
                        />
                    </TabPanel>
                    {/* <TabPanel></TabPanel> */}
                    <TabPanel p={0} pt={2}>
                        {!jdList ? "No Data" : <DashboardJobDescription data={jdList} />}
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    );
};

export default JdContainer;
