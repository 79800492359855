import { Flex, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDashboard } from "../../../context/DashboardContext";
import JDStatus from "./JDStatus";
import EarningStatus from "./EarningStatus";
import ClosingStatus from "./ClosingStatus";
import RecruiterStatus from "./RecruiterStatus";
import JDList from "./JDList";
import ApplicationOnProcess from "../ClientTab/ApplicationOnProcess";
import {
    OverallApplicationStatistics,
    SingleRoundStatistics,
} from "../ClientTab/ApplicationStatistics";
import DailyPerformance from "./DailyPerformance";
import ClientStatus from "./ClientStatus";
import { useLocation } from "react-router-dom";
import APT from "./APT";

const Dashboard = ({ isCollapsed = true }) => {
    const {
        loading,
        fetchJDs,
        dashJDData,
        dashClientData,
        fetchClients,
        countApplicationsOnProcessByStatus,
        applicationsOnProcessByStatusCount,
        getApplicationStatistics,
        applicationsStatics,
        getPerDayApplicationNumbers,
        perDayApplicationNumbers,
        fetchEarnings,
        earnings,
        fetchClosings,
        closings,
        fetchRecruiterStatus,
        recruiterStatus,
        fetchTodaysWorkCount,
        todaysWorkCount,
    } = useDashboard();

    const { pathname } = useLocation();
    const initialPath = pathname.split("/")[1];

    console.log("todaysWorkCount", todaysWorkCount);

    useEffect(() => {
        fetchJDs();
        fetchClients();
        countApplicationsOnProcessByStatus();
        getApplicationStatistics();
        getPerDayApplicationNumbers();
        fetchEarnings();
        fetchClosings();
        fetchRecruiterStatus();
        fetchTodaysWorkCount();
    }, []);

    return (
        <>
            {loading ? (
                <Text>Loading</Text>
            ) : (
                <Flex
                    p={4}
                    bg={"#0F1014"}
                    gap={5}
                    textAlign={"left"}
                    fontFamily={"Inter, Helvetica, sans-serif"}
                    direction={"column"}
                    h={"100%"}
                    overflow={"auto"}
                >
                    <Flex w={"100%"} gap={5}>
                        <Flex gap={5} direction={"column"}>
                            <Flex gap={5}>
                                {/* JD Status */}
                                <JDStatus loading={loading} dashJDData={dashJDData} />

                                {initialPath == "admin_dashboard" ? (
                                    // Earning Status
                                    <EarningStatus loading={loading} earnings={earnings} />
                                ) : (
                                    // Applications to be Processed Today
                                    <APT loading={loading} todaysWorkCount={todaysWorkCount} />
                                )}
                            </Flex>
                            <Flex gap={5}>
                                {/* Closings */}
                                <ClosingStatus loading={loading} closings={closings} />

                                {/* Recruiter */}
                                <RecruiterStatus
                                    loading={loading}
                                    recruiterStatus={recruiterStatus}
                                />
                            </Flex>
                        </Flex>

                        {/* Application Stats */}
                        <ApplicationOnProcess
                            loading={loading}
                            applicationsOnProcessByStatusCount={applicationsOnProcessByStatusCount}
                        />

                        {/* JD List */}
                        <JDList
                            isCollapsed={isCollapsed}
                            loading={loading}
                            dashJDData={dashJDData}
                        />
                    </Flex>

                    <Flex w={"100%"} gap={5} align={"flex-start"}>
                        {/* Overall  Application Stats */}
                        <OverallApplicationStatistics
                            loading={loading}
                            applicationsStatics={applicationsStatics}
                        />

                        <Flex
                            direction={"column"}
                            align={"flex-start"}
                            gap={4}
                            justify={"space-between"}
                            flexGrow={1}
                        >
                            {/* iNDIVIDUAL Application Stats */}
                            <Flex
                                position={"relative"}
                                align={"center"}
                                gap={4}
                                justify={"space-between"}
                                w={"100%"}
                            >
                                {/* CV Process Status */}
                                <SingleRoundStatistics
                                    loading={loading}
                                    roundName={"CV Round"}
                                    roundData={applicationsStatics?.cvStatus}
                                />

                                {/* Interview Process Status */}
                                <SingleRoundStatistics
                                    loading={loading}
                                    roundName={"Interview Round"}
                                    roundData={applicationsStatics?.interviewStatus}
                                />

                                {/* Offer Process Status */}
                                <SingleRoundStatistics
                                    loading={loading}
                                    roundName={"Offer Round"}
                                    roundData={applicationsStatics?.offerStatus}
                                />

                                {/* Placement Process Status */}
                                <SingleRoundStatistics
                                    loading={loading}
                                    roundName={"Placement Round"}
                                    roundData={applicationsStatics?.placementStatus}
                                />

                                {/* Billing  Process Status */}
                                <SingleRoundStatistics
                                    loading={loading}
                                    roundName={"Billing Round"}
                                    roundData={applicationsStatics?.billingStatus}
                                />
                            </Flex>

                            <Flex
                                position={"relative"}
                                align={"center"}
                                gap={4}
                                justify={"space-between"}
                                w={"100%"}
                            >
                                {/* Daily Performance */}
                                <DailyPerformance
                                    loading={loading}
                                    perDayApplicationNumbers={perDayApplicationNumbers}
                                    isCollapsed={isCollapsed}
                                />

                                {/* Clients Status */}
                                <ClientStatus
                                    loading={loading}
                                    dashClientData={dashClientData}
                                    initialPath={initialPath}
                                />
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            )}
        </>
    );
};

export default Dashboard;
