import { Avatar, AvatarGroup, Box, Flex, Heading, Skeleton, Text, Tooltip } from "@chakra-ui/react";
import React from "react";

const RecruiterStatus = ({ loading, recruiterStatus }) => {
    console.log(recruiterStatus);
    return (
        <Box
            h={"200px"}
            minW={"200px"}
            bg={"#005f53"}
            borderRadius={"md"}
            p={4}
            position={"relative"}
        >
            {loading ? (
                <Skeleton h="100%" w="100%" borderRadius={"md"} />
            ) : (
                <>
                    <Heading as="h2" size="xl" textAlign={"left"} pl={1}>
                        {recruiterStatus?.totalActiveRecruiters}
                    </Heading>
                    <Heading as="h5" size="xs" textAlign={"left"} pl={1} color={"#ffffff9c"}>
                        Working Recruiters
                    </Heading>

                    <Box position={"absolute"} bottom={1} left={0} px={5} pb={3} w={"100%"}>
                        <Heading
                            as="h5"
                            size="xs"
                            textAlign={"left"}
                            pl={1}
                            color={"#ffffff9c"}
                            mb={1}
                        >
                            Heroes of Month
                        </Heading>

                        {recruiterStatus?.topRecruiters?.length == 0 ? (
                            <AvatarGroup size="md" max={3}>
                                <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
                                <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />
                                <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
                                <Avatar
                                    name="Prosper Otemuyiwa"
                                    src="https://bit.ly/prosper-baba"
                                />
                                <Avatar name="Christian Nwamba" src="https://bit.ly/code-beast" />
                            </AvatarGroup>
                        ) : (
                            <AvatarGroup size="md" max={3}>
                                {recruiterStatus?.topRecruiters.map((rec, index) => (
                                    <Avatar
                                        key={index}
                                        name="Ryan Florence"
                                        src="https://bit.ly/ryan-florence"
                                    />
                                ))}
                            </AvatarGroup>
                        )}
                    </Box>
                </>
            )}
        </Box>
    );
};

export default RecruiterStatus;
