// src/LoginForm.js
import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Text,
    InputGroup,
    InputRightElement,
    IconButton,
    Checkbox,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useAuth } from "../context/AuthContext";
import { useEnv } from "../context/EnvContext";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import useGlobalToast from "../Utils/GlobalFunctions/toast";

const LoginForm = () => {
    const navigate = useNavigate();
    const toast = useGlobalToast();

    const { key } = useEnv();
    console.log("Key", key);
    const { login, isLogingIn, userData } = useAuth();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(true);

    useEffect(() => {
        // Fetch Login Data from the Local Storage
        (function () {
            const loginCredentials = localStorage.getItem("loginCredential");

            if (loginCredentials) {
                try {
                    // Decrypt Login Credentials
                    const bytes = CryptoJS.AES.decrypt(loginCredentials, key);
                    const decryptedLoginData = JSON.parse(
                        bytes.toString(CryptoJS.enc.Utf8)
                    );

                    // Set email and password
                    setEmail(decryptedLoginData?.email || "");
                    setPassword(decryptedLoginData?.password || "");
                    setRememberMe(false);
                } catch (e) {
                    console.error("Failed to decrypt login credentials", e);
                }
            }
        })();

        // Check if user Data found, then redirect to home page
        if (userData) {
            toast.error(
                "Error!",
                "You are already logged in. Redirecting to the homepage."
            );
            navigate("/");
        }
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        login(email, password, rememberMe);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    return (
        <div className="flex flex-col items-center justify-center min-h-screen">
            <Text className=" mb-2 text-white font-bold text-2xl">
                Login Form
            </Text>
            <Box
                bg="#2D3748"
                p={6}
                rounded="md"
                width="100%"
                maxWidth="md"
                boxShadow="xl"
            >
                <form onSubmit={handleSubmit}>
                    <FormControl id="email" isRequired>
                        <FormLabel>Email</FormLabel>
                        <Input
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </FormControl>
                    <FormControl id="password" mt={4} isRequired>
                        <FormLabel>Password</FormLabel>
                        <InputGroup>
                            <Input
                                type={showPassword ? "text" : "password"}
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <InputRightElement>
                                <IconButton
                                    variant="ghost"
                                    aria-label={
                                        showPassword
                                            ? "Hide password"
                                            : "Show password"
                                    }
                                    icon={
                                        showPassword ? (
                                            <ViewOffIcon />
                                        ) : (
                                            <ViewIcon />
                                        )
                                    }
                                    onClick={togglePasswordVisibility}
                                />
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                    <FormControl display="flex" alignItems="center" mt={4}>
                        <Checkbox
                            id="rememberMe"
                            isChecked={rememberMe}
                            onChange={(e) => setRememberMe(e.target.checked)}
                        >
                            Remember Me
                        </Checkbox>
                    </FormControl>
                    <Button
                        colorScheme="teal"
                        width="full"
                        mt={4}
                        type="submit"
                        isLoading={isLogingIn}
                    >
                        Login
                    </Button>
                </form>
            </Box>
        </div>
    );
};

export default LoginForm;
