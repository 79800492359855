import { Box, Button, Flex, Heading, Input, Skeleton, Tooltip } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Carousel from "../../../Utils/GlobalComponents/Carousel";
import { bannerImages } from "../../../Utils/DataSets/bannerImages";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useAdmin } from "../../../context/AdminContext";
import { DeleteIcon } from "@chakra-ui/icons";
import { useAuth } from "../../../context/AuthContext";
import { useEnv } from "../../../context/EnvContext";
import { FaPlus, FaSave } from "react-icons/fa";

// Function to reorder items
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const CarouselSetting = () => {
    const { backendUrl } = useEnv();
    const { userData } = useAuth();
    const {
        addbannerImages,
        bannerPhotos,
        fetchBannerImages,
        deleteBannerPhoto,
        basicTools,
        loading,
        updateCarouselStack,
        fetchBasicTools,
    } = useAdmin();
    const inputRef = useRef(null);
    const [images, setImages] = React.useState([]);

    useEffect(() => {
        console.log("userData**", userData);
        fetchBannerImages();
        fetchBasicTools();
    }, []);

    useEffect(() => {
        setImages(basicTools?.carouselStack || []);
    }, [basicTools]);

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const { source, destination } = result;

        if (source.index === destination.index) return;

        const updatedImages = reorder(images, source.index, destination.index);
        setImages(updatedImages);
    };

    const handleAddImages = (event) => {
        const files = event.target.files;
        if (files.length > 10) {
            alert("You can only upload a maximum of 10 images.");
            return;
        }

        const imagesFormData = new FormData();
        for (let i = 0; i < files.length; i++) {
            imagesFormData.append("images", files[i]);
        }

        console.log("imagesFormData", imagesFormData.getAll("images")); // This will show the list of appended files

        // Call the addbannerImages() function
        addbannerImages(imagesFormData);
    };

    const openFilePicker = () => {
        inputRef.current.click();
    };

    const handleDeleteBanner = (photoId, photoURL) => {
        deleteBannerPhoto(photoId, photoURL);
    };

    const handleAddImageToCarouselStack = (photoURL) => {
        console.log("add photo", photoURL);

        setImages((prevState) => {
            // Check if the photoURL is already in the images array
            const imageIndex = prevState.indexOf(photoURL);

            // Create a new array with the photoURL removed if it exists
            const newState =
                imageIndex !== -1
                    ? prevState.filter((image, index) => index !== imageIndex)
                    : prevState;

            // Add the photoURL to the front of the new array
            return [photoURL, ...newState];
        });
    };

    const handleCarouselStackSave = () => {
        // save current stack
        console.log("save images", images);
        updateCarouselStack(images);
    };

    const handleRemoveimageFromStack = (image) => {
        console.log("remove image", image);
        setImages((prevState) => {
            // Find the index of the image to remove
            const imageIndex = prevState.indexOf(image);

            // Remove the image from the array and return the new state
            return prevState.filter((_, index) => index !== imageIndex);
        });
    };
    console.log("basicTools", basicTools);
    console.log("images", images);

    return (
        <Flex w={"100%"} h={"100%"}>
            <Box maxW={"80%"} h={"86%"} overflowY={"auto"}>
                <Carousel images={images} backendUrl={backendUrl} />
                <Box borderTopWidth={1} pt={2} mt={-3}>
                    <Heading as="h4" size="md" position={"relative"}>
                        Banner Photos Store
                        <Button
                            colorScheme="teal"
                            size="xs"
                            position={"absolute"}
                            top={"2px"}
                            right={2}
                            onClick={openFilePicker}
                        >
                            Add new Images
                        </Button>
                        <Input
                            type="file"
                            accept="image/*"
                            multiple
                            display="none"
                            ref={inputRef}
                            onChange={handleAddImages}
                        />
                    </Heading>
                    <Flex
                        w={"100%"}
                        mt={2}
                        p={2}
                        wrap={"wrap"}
                        gap={2}
                        justify={"space-between"}
                        id={"bannerPhotoStore"}
                    >
                        {loading ? (
                            <>
                                <Skeleton height="100px" zIndex={22} w={"30%"} flexGrow={1} />
                                <Skeleton height="100px" zIndex={22} w={"30%"} flexGrow={1} />
                                <Skeleton height="100px" zIndex={22} w={"30%"} flexGrow={1} />
                                <Skeleton height="100px" zIndex={22} w={"30%"} flexGrow={1} />
                                <Skeleton height="100px" zIndex={22} w={"30%"} flexGrow={1} />
                                <Skeleton height="100px" zIndex={22} w={"30%"} flexGrow={1} />
                            </>
                        ) : (
                            bannerPhotos.map((image, index) => (
                                <Box
                                    key={index}
                                    p={"2px"}
                                    border={"3px solid gray"}
                                    h={"auto"}
                                    w={"30%"}
                                    flexGrow={1}
                                    position={"relative"}
                                >
                                    <img
                                        src={`${backendUrl}${image.url}`}
                                        style={{ width: "100%" }}
                                    />
                                    <Flex
                                        position={"absolute"}
                                        top={1}
                                        right={1}
                                        direction={"column"}
                                    >
                                        <Tooltip
                                            hasArrow
                                            label="Delete Image"
                                            bg="red.300"
                                            color="black"
                                            placement="left"
                                        >
                                            <Button
                                                colorScheme="white"
                                                size="xs"
                                                variant="ghost"
                                                borderRadius={"full"}
                                                _hover={{ bg: "black", color: "#f25c5c" }}
                                                onClick={() => {
                                                    handleDeleteBanner(image._id, image.url);
                                                }}
                                            >
                                                <DeleteIcon
                                                    style={{
                                                        filter: "drop-shadow(0px 0px 8px black)",
                                                    }}
                                                />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip
                                            hasArrow
                                            label="Add Image to Carousel Stack"
                                            bg="#9AE6B4"
                                            color="black"
                                            placement="left"
                                        >
                                            <Button
                                                colorScheme="white"
                                                size="xs"
                                                variant="ghost"
                                                borderRadius={"full"}
                                                _hover={{ bg: "black", color: "#9AE6B4" }}
                                                onClick={() => {
                                                    handleAddImageToCarouselStack(image.url);
                                                }}
                                            >
                                                <FaPlus
                                                    style={{
                                                        filter: "drop-shadow(0px 0px 8px black)",
                                                    }}
                                                />
                                            </Button>
                                        </Tooltip>
                                    </Flex>
                                </Box>
                            ))
                        )}
                    </Flex>
                </Box>
            </Box>
            <Box flexGrow={1} borderLeftWidth={1} h={"86%"}>
                <Heading as="h4" size="md" position={"relative"} mb={1}>
                    Carousel Stack
                    <Tooltip
                        hasArrow
                        label="Save current stack"
                        bg="#9AE6B4"
                        color="black"
                        placement="top"
                    >
                        <Button
                            position={"absolute"}
                            right={1}
                            colorScheme="white"
                            size="xs"
                            variant="ghost"
                            borderRadius={"full"}
                            _hover={{ bg: "#9AE6B4", color: "black" }}
                            onClick={handleCarouselStackSave}
                        >
                            <FaSave
                                style={{
                                    filter: "drop-shadow(0px 0px 8px black)",
                                }}
                            />
                        </Button>
                    </Tooltip>
                </Heading>
                <Box p={2} pt={0} h={"96%"} boxSizing="border-box" overflowY={"auto"}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided) => (
                                <Box
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    id={"carouselStack"}
                                >
                                    {images?.map((image, index) => (
                                        <Draggable key={image} draggableId={image} index={index}>
                                            {(provided) => (
                                                <Box
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    p={"2px"}
                                                    border={"2px solid white"}
                                                    mb={2}
                                                    bg="gray.200"
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    cursor="move"
                                                    position={"relative"}
                                                >
                                                    <img
                                                        src={`${backendUrl}${image}`}
                                                        style={{ width: "100%" }}
                                                    />
                                                    <Tooltip
                                                        hasArrow
                                                        label="Remove image from stack"
                                                        bg="red.300"
                                                        color="black"
                                                        placement="left"
                                                    >
                                                        <Button
                                                            position={"absolute"}
                                                            right={1}
                                                            top={1}
                                                            colorScheme="white"
                                                            size="xs"
                                                            variant="ghost"
                                                            borderRadius={"full"}
                                                            _hover={{
                                                                bg: "black",
                                                                color: "#f25c5c",
                                                            }}
                                                            onClick={() => {
                                                                handleRemoveimageFromStack(image);
                                                            }}
                                                        >
                                                            <DeleteIcon
                                                                style={{
                                                                    filter: "drop-shadow(0px 0px 8px black)",
                                                                }}
                                                            />
                                                        </Button>
                                                    </Tooltip>
                                                </Box>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </Box>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Box>
            </Box>
        </Flex>
    );
};

export default CarouselSetting;
