import React, { useEffect, useState } from "react";

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Box,
    Text,
    Avatar,
    Flex,
    FormControl,
    FormLabel,
    Select,
    Textarea,
} from "@chakra-ui/react";
import { useEnv } from "../../../context/EnvContext";
import getGenderIcon from "../../../Utils/GlobalFunctions/GenderIcon";

const RecordOfferResponse = ({
    isOpen,
    onClose,
    application,
    recordCanOfferRes,
    isUpdatingResult,
}) => {
    const { backendUrl } = useEnv();
    const [formData, setFormData] = useState({ offerStatus: "Pending", remarks: "" });
    const [isDataUpdated, setIsDataUpdated] = useState(false);

    useEffect(() => {
        setFormData({
            offerStatus: application.offerDetails.offerStatus || "Pending",
            remarks: application.offerDetails.remarks || "",
        });
    }, [application]);

    useEffect(() => {
        if (
            !(
                formData.offerStatus == application.offerDetails.offerStatus &&
                (formData.remarks == application.offerDetails.remarks || formData.remarks == "")
            )
        ) {
            setIsDataUpdated(true);
        } else {
            setIsDataUpdated(false);
        }
    }, [formData]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFormReset = () => {
        setFormData({
            offerStatus: application.offerDetails.offerStatus || "Pending",
            remarks: application.offerDetails.remarks || "",
        });
    };
    const handleFormSubmit = async () => {
        console.log(formData);

        // call submit function
        const res = await recordCanOfferRes(
            application._id,
            formData,
            isUpdatingResult,
            application.offerDetails.offerStatus || "Pending"
        );
        console.log("res", res);
        if (res?.success) {
            onClose();
        }
    };

    return (
        <div>
            <Modal
                isLazy
                isOpen={isOpen}
                onClose={onClose}
                size={"xl"}
                style={{ minHeight: "100vh" }}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        {isUpdatingResult ? "Update" : "Record"} Candidate
                        <b>{application.candidate.stuId}</b> response to Offer{" "}
                        <b>{application.offerDetails.offerId}</b> for{" "}
                        <b>{application.applicationID}</b>
                        <Flex justify={"space-between"} w={"100%"} mt={2} gap={2}>
                            <Box background={"teal"} w={"50%"} px={2} py={1} borderRadius={"md"}>
                                <Text fontSize={"md"}>{application.jobDescription.jobId}</Text>
                                <Text
                                    fontSize={"sm"}
                                    color={"#c9c9c9"}
                                    style={{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        maxWidth: "200px",
                                    }}
                                >
                                    {application.jobDescription.jobTitle}
                                </Text>
                            </Box>{" "}
                            <Flex
                                align={"center"}
                                background={"teal"}
                                w={"50%"}
                                px={2}
                                py={1}
                                borderRadius={"md"}
                            >
                                <Avatar
                                    src={`${backendUrl}${application.candidate?.dp}`}
                                    size="sm"
                                    name={application.candidate.name}
                                    mr={2}
                                    background={"green"}
                                    color={"white"}
                                />

                                <Box
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Flex gap={1}>
                                        <Text
                                            fontSize={"sm"}
                                            style={{
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                maxWidth: "270px",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: "#c9c9c9",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {application.candidate.stuId}
                                            </span>
                                            {" - "}
                                            {application.candidate.name}
                                        </Text>
                                        {getGenderIcon(application.candidate?.gender)}
                                    </Flex>
                                    <Text
                                        fontSize={"sm"}
                                        color={"#c9c9c9"}
                                        style={{
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            maxWidth: "270px",
                                        }}
                                    >
                                        {application.candidate.email}
                                    </Text>
                                </Box>
                            </Flex>
                        </Flex>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody style={{ paddingBottom: "20px" }}>
                        {/* <hr /> */}
                        <FormControl id="offerStatus" isRequired>
                            <FormLabel>Candidate Response:</FormLabel>
                            <Select
                                name="offerStatus"
                                value={formData.offerStatus}
                                onChange={handleChange}
                            >
                                <option value="Pending">Pending</option>
                                <option value="Accepted">Accepted</option>
                                <option value="Rejected">Rejected</option>
                            </Select>
                        </FormControl>
                        <FormControl id="remarks" mt={3}>
                            <FormLabel>Remarks:</FormLabel>
                            <Textarea
                                name="remarks"
                                value={formData.remarks}
                                onChange={handleChange}
                                placeholder="Write your Remark Here"
                                size="sm"
                                borderRadius={"md"}
                            />
                        </FormControl>
                    </ModalBody>{" "}
                    <ModalFooter>
                        <Button colorScheme="red" mr={3} size="sm" onClick={handleFormReset}>
                            Reset
                        </Button>
                        <Button
                            colorScheme="blue"
                            size="sm"
                            onClick={handleFormSubmit}
                            isDisabled={!isDataUpdated}
                        >
                            Submit
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default RecordOfferResponse;
