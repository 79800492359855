import React, { useState, useEffect } from "react";

import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Badge,
    Text,
    Box,
    Tooltip,
    Flex,
    Skeleton,
    MenuDivider,
    Avatar,
} from "@chakra-ui/react";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverArrow,
    PopoverCloseButton,
} from "@chakra-ui/react";

import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import { GrMore } from "react-icons/gr";
import ClientDetails from "./ClientDetails";
import { FaSort } from "react-icons/fa";
import { MdMenu } from "react-icons/md";

import { formatToShortDate } from "../../../Utils/GlobalFunctions/FormatDate";
import { printSkills } from "../../../Utils/GlobalFunctions/PrintSkills";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAuth } from "../../../context/AuthContext";
import { useEnv } from "../../../context/EnvContext";
import { RiContactsBook3Fill } from "react-icons/ri";
import ClientForm from "./ClientForm";
import { useClient } from "../../../context/ClientContext";

const ClientBody = ({ data, fetchAllClient, hasMore, pageNo, status, loading }) => {
    const { backendUrl } = useEnv();
    const { userData } = useAuth();
    const { deleteClient } = useClient();
    const [isClientDetailsModalOpen, setIsClientDetailsModalOpen] = useState(false);
    const [isClientFormModalOpen, setIsClientFormModalOpen] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);
    const [sortedData, setSortedData] = useState(data);
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: null,
    });

    // Sort the data
    useEffect(() => {
        if (!Array.isArray(data)) {
            console.error("Data is not an array");
            return;
        }
        let sortedArray = [...data];
        if (sortConfig.key) {
            sortedArray?.sort((a, b) => {
                let aValue = a[sortConfig.key];
                let bValue = b[sortConfig.key];

                // Handle nested keys for companyName
                if (sortConfig.key === "clientDetails.client?.name") {
                    aValue = a.clientDetails.client?.name;
                    bValue = b.clientDetails.client?.name;
                }

                if (aValue < bValue) {
                    return sortConfig.direction === "ascending" ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === "ascending" ? 1 : -1;
                }
                return 0;
            });
        }
        setSortedData(sortedArray);
    }, [sortConfig, data]);

    const fetchMoreData = () => {
        console.log("fetching more data.......");
        fetchAllClient(status, pageNo, 30, true);
    };

    const requestSort = (key) => {
        let direction = "ascending";
        if (sortConfig.key === key && sortConfig.direction === "ascending") {
            direction = "descending";
        } else if (sortConfig.key === key && sortConfig.direction === "descending") {
            setSortConfig({ key: null, direction: null });
            return;
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            if (sortConfig.direction === "ascending") {
                return <TiArrowSortedUp cursor={"pointer"} />;
            } else if (sortConfig.direction === "descending") {
                return <TiArrowSortedDown cursor={"pointer"} />;
            }
        }
        return <FaSort cursor={"pointer"} />;
    };

    // Handle Client Details modal Preview
    const handleClientPreview = (client) => {
        setSelectedClient(client);
        setIsClientDetailsModalOpen(true);
    };

    const handleClientViewCloseModal = () => {
        setIsClientDetailsModalOpen(false);
        setSelectedClient(null);
    };

    // Handle Client Form modal Preview
    const handleClientForm = (client) => {
        setSelectedClient(client);
        setIsClientFormModalOpen(true);
    };

    const handleClientFormCloseModal = () => {
        setIsClientFormModalOpen(false);
    };
    return (
        <div
            style={{
                height: "75vh",
                overflow: "auto",
                position: "relative",
            }}
        >
            <InfiniteScroll
                dataLength={sortedData.length}
                next={fetchMoreData}
                hasMore={hasMore}
                height={"100%"}
                loader={
                    <>
                        <Skeleton height="30px" m={2} />
                        <Skeleton height="30px" m={2} />
                        <Skeleton height="30px" m={2} />
                    </>
                }
                endMessage={
                    <p style={{ textAlign: "center", marginTop: "5px" }}>
                        <b>End of the List</b>
                    </p>
                }
            >
                <TableContainer
                    style={{ maxHeight: "100%" }}
                    overflowY={sortedData.length < 5 ? "auto" : "clip"}
                    overflowX={sortedData.length < 5 ? "clip" : "clip"}
                >
                    <Table size="sm">
                        <Thead
                            style={{
                                position: "sticky",
                                top: 0,
                                backgroundColor: "#05080D",
                                zIndex: 1,
                            }}
                        >
                            <Tr>
                                <Th style={{ color: "white" }} px={0}>
                                    Sl
                                </Th>
                                <Th
                                    style={{ color: "white" }}
                                    onClick={() => requestSort("clientId")}
                                    pr={0}
                                >
                                    <Tooltip
                                        hasArrow
                                        label={
                                            <Flex align={"center"} direction={"column"}>
                                                <Text>Client Id</Text>
                                                <Text fontSize={"xs"} color={"#2b2a2a"}>
                                                    Click to view Client Details
                                                </Text>
                                            </Flex>
                                        }
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                    >
                                        <Flex
                                            alignItems={"center"}
                                            width={"100%"}
                                            justify={"space-between"}
                                        >
                                            Client Id {getSortIcon("clientId")}
                                        </Flex>
                                    </Tooltip>
                                </Th>
                                <Th
                                    style={{ color: "white" }}
                                    onClick={() => requestSort("name")}
                                    pr={0}
                                >
                                    {" "}
                                    <Tooltip
                                        hasArrow
                                        label={
                                            <>
                                                <Flex align={"center"}>
                                                    <Avatar
                                                        src=""
                                                        size="sm"
                                                        name="Logo"
                                                        mr={2}
                                                        background={"gray"}
                                                        color={"white"}
                                                    />
                                                    <Box
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                        }}
                                                    >
                                                        <Text
                                                            fontSize={"sm"}
                                                            style={{
                                                                textOverflow: "ellipsis",
                                                                overflow: "hidden",
                                                            }}
                                                        >
                                                            Client Name
                                                        </Text>
                                                        <Text
                                                            fontSize={"sm"}
                                                            color={"#2b2a2a"}
                                                            style={{
                                                                textOverflow: "ellipsis",
                                                                overflow: "hidden",
                                                                maxWidth: "180px",
                                                            }}
                                                        >
                                                            ESTD., No of Employees
                                                        </Text>
                                                    </Box>
                                                </Flex>
                                            </>
                                        }
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                    >
                                        <Flex
                                            alignItems={"center"}
                                            width={"100%"}
                                            justify={"space-between"}
                                        >
                                            Client {getSortIcon("name")}
                                        </Flex>
                                    </Tooltip>
                                </Th>

                                <Th style={{ color: "white" }} pr={0}>
                                    No of Employees
                                </Th>
                                <Th
                                    style={{ color: "white" }}
                                    onClick={() => requestSort("city")}
                                    pr={0}
                                >
                                    <Flex
                                        alignItems={"center"}
                                        width={"100%"}
                                        justify={"space-between"}
                                    >
                                        Address {getSortIcon("city")}
                                    </Flex>
                                </Th>
                                <Th
                                    style={{ color: "white" }}
                                    onClick={() => requestSort("status")}
                                    pr={0}
                                >
                                    <Flex
                                        alignItems={"center"}
                                        width={"100%"}
                                        justify={"space-between"}
                                    >
                                        Status {getSortIcon("status")}
                                    </Flex>
                                </Th>

                                <Th
                                    style={{
                                        color: "white",
                                    }}
                                    pr={0}
                                >
                                    <Tooltip
                                        hasArrow
                                        label='Click on respective "Menu Button" for more options.'
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                        maxW={"200px"}
                                    >
                                        <Box>
                                            <MdMenu />
                                        </Box>
                                    </Tooltip>
                                </Th>
                            </Tr>
                        </Thead>

                        <Tbody>
                            {sortedData &&
                                sortedData.map((client, index) => (
                                    <Tr key={index}>
                                        <Td px={0}>{index + 1}</Td>
                                        <Td
                                            style={{ cursor: "pointer" }}
                                            onClick={() => handleClientPreview(client)}
                                            pr={0}
                                        >
                                            {client?.clientId}
                                        </Td>
                                        <Td
                                            style={{
                                                maxWidth: "250px",
                                                overflow: "hidden",
                                            }}
                                        >
                                            <Flex
                                                justify={"space-between"}
                                                w={"100%"}
                                                align={"center"}
                                            >
                                                <Flex>
                                                    <Avatar
                                                        src={`${backendUrl}${client?.logo}`}
                                                        size="sm"
                                                        name={client?.name}
                                                        mr={2}
                                                        background={"green"}
                                                        color={"white"}
                                                    />

                                                    <Box
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                        }}
                                                    >
                                                        <Flex gap={1}>
                                                            <Text
                                                                fontSize={"sm"}
                                                                style={{
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                    maxWidth: "150px",
                                                                }}
                                                            >
                                                                {client?.name}
                                                            </Text>
                                                        </Flex>
                                                        {client?.estd && (
                                                            <Text
                                                                fontSize={"xs"}
                                                                color={"#a3a3a3"}
                                                                style={{
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                    maxWidth: "160px",
                                                                }}
                                                            >
                                                                estd.- {client?.estd}
                                                            </Text>
                                                        )}
                                                    </Box>
                                                </Flex>
                                                <Popover>
                                                    <PopoverTrigger>
                                                        <Box
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                            }}
                                                        >
                                                            <RiContactsBook3Fill
                                                                fontSize={"20px"}
                                                            />
                                                        </Box>
                                                    </PopoverTrigger>
                                                    <PopoverContent>
                                                        <PopoverArrow />
                                                        <PopoverCloseButton />
                                                        <PopoverHeader>Contacts</PopoverHeader>
                                                        <PopoverBody
                                                            style={{
                                                                lineHeight: "normal",
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                gap: "8px",
                                                                padding: "8px",
                                                            }}
                                                        >
                                                            {client?.contactNo && client?.email && (
                                                                <Box
                                                                    bg={"#181D25"}
                                                                    px={2}
                                                                    py={1}
                                                                    borderRadius={"md"}
                                                                >
                                                                    <Text
                                                                        fontSize={"sm"}
                                                                        style={{
                                                                            textOverflow:
                                                                                "ellipsis",
                                                                            overflow: "hidden",
                                                                            maxWidth: "150px",
                                                                        }}
                                                                    >
                                                                        {client?.contactNo}
                                                                    </Text>
                                                                    <Text
                                                                        fontSize={"xs"}
                                                                        color={"#a3a3a3"}
                                                                    >
                                                                        {client?.email}
                                                                    </Text>
                                                                </Box>
                                                            )}
                                                            {client?.contacts?.map(
                                                                (contact, index) => (
                                                                    <Flex
                                                                        key={index}
                                                                        direction={"column"}
                                                                        bg={"#181D25"}
                                                                        px={2}
                                                                        py={1}
                                                                        borderRadius={"md"}
                                                                    >
                                                                        <Text>
                                                                            <b>Name: </b>{" "}
                                                                            {contact.name}
                                                                        </Text>
                                                                        <Text>
                                                                            <b>Desg.: </b>{" "}
                                                                            {contact.role}
                                                                        </Text>
                                                                        <Text>
                                                                            <b>Email: </b>{" "}
                                                                            {contact.email}
                                                                        </Text>
                                                                        <Text>
                                                                            <b>Conatct no.: </b>{" "}
                                                                            {contact.contactNo}
                                                                        </Text>
                                                                    </Flex>
                                                                )
                                                            )}
                                                        </PopoverBody>
                                                    </PopoverContent>
                                                </Popover>
                                            </Flex>
                                        </Td>
                                        <Td>{client?.noOfEmp}</Td>
                                        <Td>
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <Flex gap={1}>
                                                    <Text fontSize={"xs"}>{client?.address}</Text>
                                                </Flex>
                                                <Text fontSize={"xs"} color={"#a3a3a3"}>
                                                    {client?.city}, {client?.state},{" "}
                                                    {client?.country} - {client?.pincode}
                                                </Text>
                                            </Box>
                                        </Td>
                                        <Td>
                                            {client?.status == "Active" && (
                                                <Badge variant="subtle" colorScheme="green">
                                                    Active
                                                </Badge>
                                            )}
                                            {client?.status == "Inactive" && (
                                                <Badge variant="subtle" colorScheme="orange">
                                                    Inactive
                                                </Badge>
                                            )}
                                        </Td>

                                        <Td>
                                            <Menu>
                                                <MenuButton>
                                                    <GrMore />
                                                </MenuButton>
                                                <MenuList minW={"50px"}>
                                                    <MenuItem
                                                        onClick={() => handleClientPreview(client)}
                                                    >
                                                        Client Details
                                                    </MenuItem>

                                                    <MenuItem
                                                        color={"#d8a953"}
                                                        onClick={() => handleClientForm(client)}
                                                    >
                                                        Update Client
                                                    </MenuItem>
                                                    {/* 
                                                    <MenuItem
                                                        onClick={() => {
                                                            deleteClient(client._id, client.status);
                                                        }}
                                                        color={"#f25c5c"}
                                                        cursor={loading ? "progress" : "pointer"}
                                                    >
                                                        Delete Client
                                                    </MenuItem> */}
                                                </MenuList>
                                            </Menu>
                                        </Td>
                                    </Tr>
                                ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </InfiniteScroll>
            {selectedClient && isClientDetailsModalOpen && (
                <ClientDetails
                    client={selectedClient}
                    isOpen={isClientDetailsModalOpen}
                    onClose={handleClientViewCloseModal}
                />
            )}
            {selectedClient && isClientFormModalOpen && (
                <ClientForm
                    operation={"Update Client"}
                    isOpen={isClientFormModalOpen}
                    onClose={handleClientFormCloseModal}
                    client={selectedClient}
                />
            )}
        </div>
    );
};

export default ClientBody;
