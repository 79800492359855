import React, { useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Input,
    Select,
    Flex,
    Heading,
    Avatar,
    Box,
} from "@chakra-ui/react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useUser } from "../../../context/UserContext";
import { useEnv } from "../../../context/EnvContext";

const validationSchema = Yup.object({
    name: Yup.string().required("* Name is required"),
    phoneNo: Yup.string()
        .matches(/^[0-9]+$/, "* Phone number must be numeric")
        .required("* Phone number is required"),
    whatsAppNo: Yup.string()
        .matches(/^[0-9]+$/, "* WhatsApp number must be numeric")
        .required("* WhatsApp number is required"),
});

const EditMyProfile = ({ isOpen, onClose, user }) => {
    const { backendUrl } = useEnv();
    const { updateProfile } = useUser();
    const [dpPreview, setDpPreview] = useState(`${backendUrl}${user?.dp}`);

    const handleOverallSubmit = (updatedUser) => {
        // console.log("updatedUser", updatedUser);
        updateProfile(updatedUser);
        onClose();
    };

    const handleDpChange = (event, setFieldValue) => {
        const file = event.target.files[0];
        setFieldValue("dp", file);

        if (!file) return;

        const reader = new FileReader();
        reader.onloadend = () => {
            setDpPreview(reader.result);
        };
        reader.readAsDataURL(file);
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
            <ModalOverlay />
            <ModalContent overflow={"clip"}>
                <ModalCloseButton top={3} />
                <ModalBody mt={0}>
                    <Flex justify={"center"} mb={2} mt={2}>
                        <Heading as="h3" size="md">
                            Update Profile
                        </Heading>
                    </Flex>

                    <Formik
                        initialValues={{
                            name: user.name || "",
                            dp: user.dp || "",
                            gender: user.gender || "",
                            phoneNo: user.phoneNo || "",
                            whatsAppNo: user.whatsAppNo || "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            handleOverallSubmit(values);
                        }}
                    >
                        {({ setFieldValue, resetForm }) => (
                            <Form>
                                <Flex gap={4} align={"center"} w={"100%"}>
                                    <Box flexGrow={1}>
                                        <FormControl id="dp">
                                            <FormLabel mb={1} mt={2}>
                                                Profile Picture
                                            </FormLabel>

                                            <Input
                                                type="file"
                                                accept="image/*"
                                                onChange={(event) =>
                                                    handleDpChange(event, setFieldValue)
                                                }
                                                bg={"gray.700"}
                                                paddingTop={"4px"}
                                            />
                                            <ErrorMessage
                                                name="dp"
                                                component="div"
                                                className="text-red-400 text-xs mt-1"
                                            />
                                        </FormControl>
                                        <FormControl id="name">
                                            <FormLabel mb={1} mt={2}>
                                                Name
                                            </FormLabel>
                                            <Field name="name" as={Input} bg={"gray.700"} />
                                            <ErrorMessage
                                                name="name"
                                                component="div"
                                                className="text-red-400 text-xs mt-1"
                                            />
                                        </FormControl>
                                    </Box>
                                    {dpPreview && <Avatar size="2xl" name="A B" src={dpPreview} />}
                                </Flex>
                                <FormControl id="phoneNo">
                                    <FormLabel mb={1} mt={2}>
                                        Phone Number
                                    </FormLabel>
                                    <Field name="phoneNo" as={Input} type="text" bg={"gray.700"} />
                                    <ErrorMessage
                                        name="phoneNo"
                                        component="div"
                                        className="text-red-400 text-xs mt-1"
                                    />
                                </FormControl>

                                <FormControl id="whatsAppNo">
                                    <FormLabel mb={1} mt={2}>
                                        WhatsApp Number
                                    </FormLabel>
                                    <Field
                                        name="whatsAppNo"
                                        as={Input}
                                        type="text"
                                        bg={"gray.700"}
                                    />
                                    <ErrorMessage
                                        name="whatsAppNo"
                                        component="div"
                                        className="text-red-400 text-xs mt-1"
                                    />
                                </FormControl>

                                <FormControl id="gender">
                                    <FormLabel mb={1} mt={2}>
                                        Gender
                                    </FormLabel>
                                    <Field name="gender" as={Select} bg={"gray.700"}>
                                        <option value="" disabled>
                                            Select gender
                                        </option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                        <option value="Prefer Not to Say">Prefer Not to Say</option>
                                    </Field>
                                    <ErrorMessage
                                        name="gender"
                                        component="div"
                                        className="text-red-400 text-xs mt-1"
                                    />
                                </FormControl>

                                <Flex justify={"center"} mb={2} mt={6} w={"100%"} gap={2}>
                                    <Button
                                        w={"100%"}
                                        type="button"
                                        colorScheme="gray"
                                        size="sm"
                                        style={{
                                            borderRadius: "20px",
                                            transition: "background 0.3s ease",
                                        }}
                                        onClick={() => {
                                            resetForm();
                                            setFieldValue("dp", "");
                                            setDpPreview(`${backendUrl}${user?.dp}`);
                                        }}
                                    >
                                        Reset
                                    </Button>
                                    <Button
                                        w={"100%"}
                                        type="submit"
                                        colorScheme="blue"
                                        size="sm"
                                        style={{
                                            borderRadius: "20px",
                                            transition: "background 0.3s ease",
                                        }}
                                    >
                                        Save
                                    </Button>
                                </Flex>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default EditMyProfile;
