import React, { useEffect } from "react";
import Carousel from "../../../Utils/GlobalComponents/Carousel";
import { bannerImages } from "../../../Utils/DataSets/bannerImages";
import { useAuth } from "../../../context/AuthContext";
import { useAdmin } from "../../../context/AdminContext";
import { useEnv } from "../../../context/EnvContext";

const HomePageBody = () => {
    const { backendUrl } = useEnv();
    const { fetchBasicTools, basicTools, loading } = useAdmin();

    useEffect(() => {
        fetchBasicTools();
    }, []);
    console.log("basicTools", basicTools);
    console.log("basicTools", basicTools);

    return (
        <div>
            {loading
                ? "loading"
                : basicTools && (
                      <Carousel
                          showStatus={false}
                          showIndicators={false}
                          showThumbs={false}
                          stopOnHover={false}
                          interval={8000}
                          images={basicTools?.carouselStack || []}
                          backendUrl={backendUrl}
                      />
                  )}
        </div>
    );
};

export default HomePageBody;
