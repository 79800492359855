import React, { useState, useEffect } from "react";

import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Badge,
    Text,
    Box,
    Tooltip,
    Flex,
    Skeleton,
    MenuDivider,
    Avatar,
} from "@chakra-ui/react";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverArrow,
    PopoverCloseButton,
} from "@chakra-ui/react";

import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import { GrMore } from "react-icons/gr";
import JdDetails from "./JdDetails";
import { FaSort } from "react-icons/fa";
import { MdMenu } from "react-icons/md";

import { formatToShortDate } from "../../../Utils/GlobalFunctions/FormatDate";
import { printSkills } from "./../../../Utils/GlobalFunctions/PrintSkills";
import StartApplication from "./StartApplication";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAuth } from "../../../context/AuthContext";
import JDForm from "./JDForm";
import { useJD } from "../../../context/JDContext";
import { useEnv } from "../../../context/EnvContext";

const JobDescription = ({ data, fetchAllJD, hasMore, pageNo, status, loading }) => {
    const { backendUrl } = useEnv();
    const { deleteJD } = useJD();
    const { userData } = useAuth();
    const [isJdDetailsModalOpen, setIsJdDetailsModalOpen] = useState(false);
    const [isStartApplicationModalOpen, setIsStartApplicationModalOpen] = useState(false);
    const [isJDFormModalOpen, setIsJDFormModalOpen] = useState(false);
    const [selectedJd, setSelectedJd] = useState(null);
    const [sortedData, setSortedData] = useState(data);
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: null,
    });

    // Sort the data
    useEffect(() => {
        if (!Array.isArray(data)) {
            console.error("Data is not an array");
            return;
        }
        let sortedArray = [...data];
        if (sortConfig.key) {
            sortedArray?.sort((a, b) => {
                let aValue = a[sortConfig.key];
                let bValue = b[sortConfig.key];

                // Handle nested keys for companyName
                if (sortConfig.key === "clientDetails.client.name") {
                    aValue = a.clientDetails.client.name;
                    bValue = b.clientDetails.client.name;
                }

                if (aValue < bValue) {
                    return sortConfig.direction === "ascending" ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === "ascending" ? 1 : -1;
                }
                return 0;
            });
        }
        setSortedData(sortedArray);
    }, [sortConfig, data]);

    const fetchMoreData = () => {
        console.log("fetching more data.......");
        fetchAllJD(status, pageNo, 30, true);
    };

    const requestSort = (key) => {
        let direction = "ascending";
        if (sortConfig.key === key && sortConfig.direction === "ascending") {
            direction = "descending";
        } else if (sortConfig.key === key && sortConfig.direction === "descending") {
            setSortConfig({ key: null, direction: null });
            return;
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            if (sortConfig.direction === "ascending") {
                return <TiArrowSortedUp cursor={"pointer"} />;
            } else if (sortConfig.direction === "descending") {
                return <TiArrowSortedDown cursor={"pointer"} />;
            }
        }
        return <FaSort cursor={"pointer"} />;
    };

    // Handle Jd Details modal Preview
    const handleJdPreview = (jd) => {
        setSelectedJd(jd);
        setIsJdDetailsModalOpen(true);
    };

    const handleJdViewCloseModal = () => {
        setIsJdDetailsModalOpen(false);
        setSelectedJd(null);
    };

    // Handle Start Application modal Preview
    const handleStartApplicationModalPreview = (jd) => {
        setSelectedJd(jd);
        setIsStartApplicationModalOpen(true);
    };

    const handleStartApplicationModalClose = () => {
        setIsStartApplicationModalOpen(false);
        setSelectedJd(null);
    };

    // Handle JD Form modal Preview
    const handleJDForm = (jd) => {
        setSelectedJd(jd);
        setIsJDFormModalOpen(true);
    };

    const handleJDFormCloseModal = () => {
        setIsJDFormModalOpen(false);
    };
    return (
        <div
            style={{
                height: "75vh",
                overflow: "auto",
                position: "relative",
            }}
        >
            <InfiniteScroll
                dataLength={sortedData.length}
                next={fetchMoreData}
                hasMore={hasMore}
                height={"100%"}
                loader={
                    <>
                        <Skeleton height="30px" m={2} />
                        <Skeleton height="30px" m={2} />
                        <Skeleton height="30px" m={2} />
                    </>
                }
                endMessage={
                    <p style={{ textAlign: "center", marginTop: "5px" }}>
                        <b>End of the List</b>
                    </p>
                }
            >
                <TableContainer
                    style={{ maxHeight: "100%" }}
                    overflowY={sortedData.length < 5 ? "auto" : "clip"}
                    overflowX={sortedData.length < 5 ? "clip" : "clip"}
                >
                    <Table size="sm">
                        <Thead
                            style={{
                                position: "sticky",
                                top: 0,
                                backgroundColor: "#05080D",
                                zIndex: 1,
                            }}
                        >
                            <Tr>
                                <Th style={{ color: "white" }} px={0}>
                                    Sl
                                </Th>
                                <Th
                                    style={{ color: "white" }}
                                    onClick={() => requestSort("jobId")}
                                    pr={0}
                                >
                                    <Tooltip
                                        hasArrow
                                        label={
                                            <Flex align={"center"} direction={"column"}>
                                                <Text>Job Description Id</Text>
                                                <Text fontSize={"xs"} color={"#2b2a2a"}>
                                                    Click to view JD Details
                                                </Text>
                                            </Flex>
                                        }
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                    >
                                        <Flex
                                            alignItems={"center"}
                                            width={"100%"}
                                            justify={"space-between"}
                                        >
                                            JD Id {getSortIcon("jobId")}
                                        </Flex>
                                    </Tooltip>
                                </Th>
                                <Th
                                    style={{ color: "white" }}
                                    onClick={() => requestSort("clientDetails.client.name")}
                                    pr={0}
                                >
                                    {" "}
                                    <Tooltip
                                        hasArrow
                                        label={
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                }}
                                            >
                                                <Text fontSize="xs">
                                                    Hover to get Company Details
                                                </Text>
                                            </div>
                                        }
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                    >
                                        <Flex
                                            alignItems={"center"}
                                            width={"100%"}
                                            justify={"space-between"}
                                        >
                                            Company {getSortIcon("clientDetails.client.name")}
                                        </Flex>
                                    </Tooltip>
                                </Th>
                                <Th
                                    style={{ color: "white" }}
                                    onClick={() => requestSort("jobTitle")}
                                    pr={0}
                                >
                                    <Tooltip
                                        hasArrow
                                        label={
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                }}
                                            >
                                                <Text fontSize="xs">
                                                    Click to get Related Job Titles
                                                </Text>
                                            </div>
                                        }
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                    >
                                        <Flex
                                            alignItems={"center"}
                                            width={"100%"}
                                            justify={"space-between"}
                                        >
                                            Job Title {getSortIcon("jobTitle")}
                                        </Flex>
                                    </Tooltip>
                                </Th>
                                <Th
                                    style={{ color: "white" }}
                                    onClick={() => requestSort("experience")}
                                    pr={0}
                                >
                                    <Tooltip
                                        hasArrow
                                        label={
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                }}
                                            >
                                                Overall Experience <br />{" "}
                                            </div>
                                        }
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                    >
                                        <Flex
                                            alignItems={"center"}
                                            width={"100%"}
                                            justify={"space-between"}
                                        >
                                            Exp. {getSortIcon("experience")}
                                        </Flex>
                                    </Tooltip>
                                </Th>
                                <Th style={{ color: "white" }}>
                                    <Tooltip
                                        hasArrow
                                        label={
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                }}
                                            >
                                                <Text fontSize="xs">
                                                    Click to get the Skills List with Relevant
                                                    Experience and Overall Experience
                                                </Text>
                                            </div>
                                        }
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                    >
                                        <Flex
                                            alignItems={"center"}
                                            width={"100%"}
                                            justify={"space-between"}
                                        >
                                            Required Skills
                                        </Flex>
                                    </Tooltip>
                                </Th>
                                <Th
                                    style={{ color: "white" }}
                                    onClick={() => requestSort("numberOfOpenings")}
                                    px={1}
                                >
                                    <Tooltip
                                        hasArrow
                                        label="No. of Open Positions"
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                    >
                                        <Flex
                                            alignItems={"center"}
                                            width={"100%"}
                                            justify={"space-between"}
                                        >
                                            Op {getSortIcon("numberOfOpenings")}
                                        </Flex>
                                    </Tooltip>
                                </Th>
                                <Th
                                    style={{ color: "white" }}
                                    onClick={() => requestSort("noOfClosings")}
                                    px={1}
                                >
                                    <Tooltip
                                        hasArrow
                                        label="No. of Closed Positions"
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                    >
                                        <Flex
                                            alignItems={"center"}
                                            width={"100%"}
                                            justify={"space-between"}
                                        >
                                            Cl {getSortIcon("noOfClosings")}
                                        </Flex>
                                    </Tooltip>
                                </Th>
                                <Th
                                    style={{ color: "white" }}
                                    onClick={() => requestSort("remainingPositions")}
                                    px={1}
                                >
                                    <Tooltip
                                        hasArrow
                                        label="No. of Remaining Positions"
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                    >
                                        <Flex
                                            alignItems={"center"}
                                            width={"100%"}
                                            justify={"space-between"}
                                        >
                                            Re {getSortIcon("remainingPositions")}
                                        </Flex>
                                    </Tooltip>
                                </Th>
                                <Th
                                    style={{ color: "white" }}
                                    onClick={() => requestSort("createdAt")}
                                    pr={0}
                                >
                                    <Tooltip
                                        hasArrow
                                        label="JD Added on"
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                    >
                                        <Flex
                                            alignItems={"center"}
                                            width={"100%"}
                                            justify={"space-between"}
                                        >
                                            Added {getSortIcon("createdAt")}
                                        </Flex>
                                    </Tooltip>
                                </Th>
                                <Th
                                    style={{ color: "white" }}
                                    onClick={() => requestSort("closingDate")}
                                    pr={0}
                                >
                                    <Tooltip
                                        hasArrow
                                        label="Closing Date"
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                    >
                                        <Flex
                                            alignItems={"center"}
                                            width={"100%"}
                                            justify={"space-between"}
                                        >
                                            Closing {getSortIcon("closingDate")}
                                        </Flex>
                                    </Tooltip>
                                </Th>
                                <Th
                                    style={{ color: "white" }}
                                    onClick={() => requestSort("salaryOffering")}
                                    pr={0}
                                >
                                    <Flex
                                        alignItems={"center"}
                                        width={"100%"}
                                        justify={"space-between"}
                                    >
                                        Salary {getSortIcon("salaryOffering")}
                                    </Flex>
                                </Th>
                                <Th
                                    style={{ color: "white" }}
                                    onClick={() => requestSort("status")}
                                    pr={0}
                                >
                                    <Flex
                                        alignItems={"center"}
                                        width={"100%"}
                                        justify={"space-between"}
                                    >
                                        Status {getSortIcon("status")}
                                    </Flex>
                                </Th>

                                <Th
                                    style={{
                                        color: "white",
                                    }}
                                    pr={0}
                                >
                                    <Tooltip
                                        hasArrow
                                        label='Click on respective "Menu Button" for more options.'
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                        maxW={"200px"}
                                    >
                                        <Box>
                                            <MdMenu />
                                        </Box>
                                    </Tooltip>
                                </Th>
                            </Tr>
                        </Thead>

                        <Tbody>
                            {sortedData &&
                                sortedData.map((jd, index) => (
                                    <Tr key={index}>
                                        <Td px={0}>{index + 1}</Td>
                                        <Td
                                            style={{ cursor: "pointer" }}
                                            onClick={() => handleJdPreview(jd)}
                                            pr={0}
                                        >
                                            {jd.jobId}
                                        </Td>
                                        <Td pr={0}>
                                            <Tooltip
                                                hasArrow
                                                label={
                                                    <Box>
                                                        <Flex gap={2} align={"center"}>
                                                            <Avatar
                                                                size="sm"
                                                                name={
                                                                    jd?.clientDetails?.client?.name
                                                                }
                                                                src={`${backendUrl}${jd?.clientDetails?.client?.logo}`}
                                                            />
                                                            <Flex direction={"column"}>
                                                                <Text fontSize={"sm"}>
                                                                    {
                                                                        jd?.clientDetails?.client
                                                                            ?.name
                                                                    }
                                                                </Text>
                                                                <Box fontSize={"2xs"}>
                                                                    {jd?.clientDetails?.client
                                                                        ?.city && (
                                                                        <Text as={"span"}>
                                                                            {
                                                                                jd?.clientDetails
                                                                                    ?.client?.city
                                                                            }
                                                                            ,
                                                                        </Text>
                                                                    )}
                                                                    {jd?.clientDetails?.client
                                                                        ?.state && (
                                                                        <Text as={"span"}>
                                                                            {
                                                                                jd?.clientDetails
                                                                                    ?.client?.state
                                                                            }
                                                                            ,
                                                                        </Text>
                                                                    )}
                                                                    {jd?.clientDetails?.client
                                                                        ?.country && (
                                                                        <Text as={"span"}>
                                                                            {
                                                                                jd?.clientDetails
                                                                                    ?.client
                                                                                    ?.country
                                                                            }
                                                                        </Text>
                                                                    )}
                                                                </Box>
                                                            </Flex>
                                                        </Flex>
                                                        {jd?.clientDetails?.client?.estd && (
                                                            <Text fontSize={"2xs"}>
                                                                <b>ESTD. - </b>
                                                                {jd?.clientDetails?.client?.estd}
                                                            </Text>
                                                        )}
                                                        {jd?.clientDetails?.client?.noOfEmp && (
                                                            <Text fontSize={"2xs"}>
                                                                <b>No. of Employees. - </b>
                                                                {jd?.clientDetails?.client?.noOfEmp}
                                                            </Text>
                                                        )}
                                                        {jd?.clientDetails?.handlerId &&
                                                            jd?.clientDetails?.client?.contacts && (
                                                                <Text fontSize={"2xs"}>
                                                                    <b>Contact -</b>{" "}
                                                                    <Text as={"span"}>
                                                                        {(() => {
                                                                            const contact =
                                                                                jd.clientDetails.client.contacts.find(
                                                                                    (contact) =>
                                                                                        contact.handlerId ===
                                                                                        jd
                                                                                            .clientDetails
                                                                                            .handlerId
                                                                                );
                                                                            return contact
                                                                                ? `${contact.name} (${contact.role}) - ${contact.contactNo} | ${contact.email}`
                                                                                : null;
                                                                        })()}
                                                                    </Text>
                                                                </Text>
                                                            )}
                                                        {jd?.clientDetails?.client?.details && (
                                                            <Text fontSize={"2xs"}>
                                                                <b>Details -</b>{" "}
                                                                <Text as={"span"}>
                                                                    {
                                                                        jd?.clientDetails?.client
                                                                            ?.details
                                                                    }
                                                                </Text>
                                                            </Text>
                                                        )}
                                                    </Box>
                                                }
                                                bg="gray.300"
                                                color="black"
                                                placement="top"
                                            >
                                                <Flex gap={1}>
                                                    <Avatar
                                                        size="2xs"
                                                        name={jd?.clientDetails?.client?.name}
                                                        src={`${backendUrl}${jd?.clientDetails?.client?.logo}`}
                                                    />
                                                    <Text
                                                        style={{
                                                            textOverflow: "ellipsis",
                                                            overflow: "hidden",
                                                            maxWidth: "150px",
                                                        }}
                                                    >
                                                        {jd?.clientDetails?.client?.name}
                                                    </Text>
                                                </Flex>
                                            </Tooltip>
                                        </Td>
                                        <Td pr={0}>
                                            {!jd.similarTags ? (
                                                <Text
                                                    style={{
                                                        textOverflow: "ellipsis",
                                                        overflow: "hidden",
                                                        maxWidth: "150px",
                                                    }}
                                                >
                                                    {" "}
                                                    {jd.jobTitle}
                                                </Text>
                                            ) : (
                                                <Popover>
                                                    <PopoverTrigger>
                                                        <Text
                                                            cursor={"pointer"}
                                                            style={{
                                                                textOverflow: "ellipsis",
                                                                overflow: "hidden",
                                                                maxWidth: "150px",
                                                            }}
                                                        >
                                                            {jd.jobTitle}
                                                        </Text>
                                                    </PopoverTrigger>
                                                    <PopoverContent>
                                                        <PopoverArrow />
                                                        <PopoverCloseButton />
                                                        <PopoverHeader>
                                                            Similar Job Titles
                                                        </PopoverHeader>
                                                        <PopoverBody
                                                            style={{
                                                                display: "flex",
                                                                flexWrap: "wrap",
                                                                gap: "5px",
                                                            }}
                                                        >
                                                            {jd.jobTitle},
                                                            {jd.similarTags.map((tag, index) => (
                                                                <span key={index}>
                                                                    {tag}
                                                                    {index <
                                                                        jd.similarTags.length - 1 &&
                                                                        ", "}
                                                                </span>
                                                            ))}
                                                        </PopoverBody>
                                                    </PopoverContent>
                                                </Popover>
                                            )}
                                        </Td>
                                        <Td pr={0}>{jd.experience} Years</Td>
                                        <Td pr={0}>
                                            <Popover>
                                                <PopoverTrigger>
                                                    <Text cursor={"pointer"}>
                                                        {printSkills(jd.skills, 20)}
                                                    </Text>
                                                </PopoverTrigger>
                                                <PopoverContent w={"200px"}>
                                                    <PopoverArrow />
                                                    <PopoverCloseButton />
                                                    <PopoverHeader>Skills</PopoverHeader>
                                                    <PopoverBody
                                                        style={{
                                                            textWrap: "wrap",
                                                            lineHeight: "normal",
                                                        }}
                                                    >
                                                        {jd.skills?.map((skill, index) => (
                                                            <Text
                                                                key={index}
                                                            >{`${skill.skillName} - ${skill.relevantExp} Years`}</Text>
                                                        ))}
                                                        {jd?.optionalSkills?.length > 0 && (
                                                            <>
                                                                <hr
                                                                    style={{
                                                                        marginTop: "8px",
                                                                        marginBottom: "4px",
                                                                    }}
                                                                />
                                                                <Flex gap={1} wrap={"wrap"}>
                                                                    <Text fontWeight={"bold"}>
                                                                        Optional Skills -
                                                                    </Text>
                                                                    {jd.optionalSkills?.map(
                                                                        (skill, index) => (
                                                                            <Text
                                                                                key={index}
                                                                            >{`${skill}, `}</Text>
                                                                        )
                                                                    )}
                                                                </Flex>
                                                            </>
                                                        )}
                                                    </PopoverBody>
                                                </PopoverContent>
                                            </Popover>
                                        </Td>
                                        <Td pr={0}>{jd.numberOfOpenings}</Td>
                                        <Td pr={0}>{jd.noOfClosings}</Td>
                                        <Td pr={0}>{jd.numberOfOpenings - jd.noOfClosings}</Td>
                                        <Td pr={0}>{formatToShortDate(jd.createdAt)}</Td>
                                        <Td pr={0}>{formatToShortDate(jd.closingDate)}</Td>
                                        <Td pr={0}>₹ {jd.salaryOffering}</Td>
                                        <Td pr={0}>
                                            {jd.status === "Closed" && (
                                                <Badge variant="subtle" colorScheme="green">
                                                    Closed
                                                </Badge>
                                            )}
                                            {jd.status === "Active" && (
                                                <Badge variant="subtle" colorScheme="blue">
                                                    Active
                                                </Badge>
                                            )}
                                            {jd.status === "On Hold" && (
                                                <Badge variant="subtle" colorScheme="orange">
                                                    On Hold
                                                </Badge>
                                            )}
                                            {jd.status === "Cancelled" && (
                                                <Badge variant="subtle" colorScheme="red">
                                                    Cancelled
                                                </Badge>
                                            )}
                                        </Td>
                                        <Td pr={0}>
                                            <Menu>
                                                <MenuButton>
                                                    <GrMore />
                                                </MenuButton>
                                                <MenuList minW={"50px"}>
                                                    <MenuItem onClick={() => handleJdPreview(jd)}>
                                                        View Details
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => {
                                                            handleStartApplicationModalPreview(jd);
                                                        }}
                                                    >
                                                        Allocate Candidate
                                                    </MenuItem>
                                                    {userData.userType == "Business Developer" && (
                                                        <>
                                                            <MenuDivider />
                                                            <MenuItem
                                                                onClick={() => handleJDForm(jd)}
                                                                color={"#d8a953"}
                                                            >
                                                                Update JD
                                                            </MenuItem>

                                                            <MenuItem
                                                                onClick={() => {
                                                                    deleteJD(jd._id, jd.status);
                                                                }}
                                                                color={"#f25c5c"}
                                                                cursor={
                                                                    loading ? "progress" : "pointer"
                                                                }
                                                            >
                                                                Delete JD
                                                            </MenuItem>
                                                        </>
                                                    )}
                                                </MenuList>
                                            </Menu>
                                        </Td>
                                    </Tr>
                                ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </InfiniteScroll>
            {selectedJd && isJdDetailsModalOpen && (
                <JdDetails
                    jd={selectedJd}
                    isOpen={isJdDetailsModalOpen}
                    onClose={handleJdViewCloseModal}
                />
            )}
            {selectedJd && isStartApplicationModalOpen && (
                <StartApplication
                    jd={selectedJd}
                    isOpen={isStartApplicationModalOpen}
                    onClose={handleStartApplicationModalClose}
                />
            )}
            {selectedJd && isJDFormModalOpen && (
                <JDForm
                    operation={"Update JD"}
                    isOpen={isJDFormModalOpen}
                    onClose={handleJDFormCloseModal}
                    jd={selectedJd}
                />
            )}
        </div>
    );
};

export default JobDescription;
