import React, { useEffect, useState } from "react";

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    Tag,
    TagLabel,
    Box,
    Text,
    Avatar,
    Heading,
    Flex,
    Tooltip,
    Badge,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    Checkbox,
    Select,
    UnorderedList,
    ListItem,
    Grid,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Skeleton,
} from "@chakra-ui/react";
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer } from "@chakra-ui/react";
import { FaBirthdayCake, FaPhoneAlt } from "react-icons/fa";
import { MdGroupWork, MdMenu, MdOutlineAlternateEmail, MdPlaylistAdd } from "react-icons/md";
import { BsSlashCircle, BsWhatsapp } from "react-icons/bs";

import { formatDate, formatDateTime } from "../../../Utils/GlobalFunctions/FormatDate";
import getGenderIcon from "../../../Utils/GlobalFunctions/GenderIcon";
import { formatToShortDate } from "../../../Utils/GlobalFunctions/FormatDate";
import { printSkills } from "../../../Utils/GlobalFunctions/PrintSkills";
import JdDetails from "../JobDescription/JdDetails";

import InfiniteScroll from "react-infinite-scroll-component";

import { useEnv } from "../../../context/EnvContext";
import { useApplication } from "../../../context/ApplicationContext";
import { useJD } from "../../../context/JDContext";

const ProcessCandidate = ({ isOpen, onClose, candidate }) => {
    const { backendUrl } = useEnv();
    const { fetchMatchingJDs, allMatchingJDs, hasMoreMatchingJDs, matchingJDsPageNo } = useJD();
    const { startApplication } = useApplication();
    const [isJdDetailsModalOpen, setIsJdDetailsModalOpen] = useState(false);
    const [selectedJD, setSelectedJD] = useState(null);

    const [sortedData, setSortedData] = useState([]);

    useEffect(() => {
        fetchMatchingJDs(1, 30, false, candidate._id);
    }, []);

    useEffect(() => {
        setSortedData(allMatchingJDs);
    }, [allMatchingJDs]);

    const fetchMoreData = () => {
        console.log("fetching more data.......");
        fetchMatchingJDs(matchingJDsPageNo, 30, true, candidate._id);
    };

    const handleJDPreview = (jd) => {
        setSelectedJD(jd);
        setIsJdDetailsModalOpen(true);
    };

    const handleJDViewCloseModal = () => {
        setIsJdDetailsModalOpen(false);
        setSelectedJD(null);
    };

    const handleStartApplication = (jdId, mpId) => {
        // console.log("abcd");
        startApplication(candidate._id, jdId, "", mpId, "procesCandidate");
    };

    return (
        <div>
            <Modal islazy isOpen={isOpen} onClose={onClose} size={"full"} height={"100vh"}>
                <ModalOverlay />
                <ModalContent className="jd-details">
                    <ModalHeader background={"black"} p={2}>
                        <Heading as="h4" size="md" textAlign={"center"}>
                            Process JD {candidate.stuId}
                        </Heading>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody
                        style={{
                            paddingBottom: "20px",
                            background: "linear-gradient(180deg, black, rgb(29, 48, 75))",
                        }}
                    >
                        <Flex gap={2}>
                            <Box
                                style={{ height: `calc(100vh - 85px)` }}
                                overflowY={"auto"}
                                width={"45%"}
                                pr={2}
                            >
                                <Box mb={3}>
                                    <Flex gap="20px">
                                        <Flex direction={"column"}>
                                            <Avatar
                                                size="xl"
                                                name={candidate.name}
                                                src={`${backendUrl}${candidate.dp}`}
                                            />
                                            <Text textAlign={"center"} fontSize="md">
                                                {candidate.stuId}
                                            </Text>
                                        </Flex>
                                        <Flex direction={"column"} width={"100%"}>
                                            <Flex gap={1} align={"center"}>
                                                {getGenderIcon(candidate.gender)}
                                                <Text>{candidate.name}</Text>
                                            </Flex>
                                            <Flex
                                                gap={"5px"}
                                                align={"center"}
                                                fontSize="md"
                                                color="#a3a3a3"
                                                width={"100%"}
                                                flexWrap={"wrap"}
                                            >
                                                <MdOutlineAlternateEmail />{" "}
                                                <Text
                                                    style={{
                                                        maxWidth: "80%",
                                                    }}
                                                >
                                                    {candidate.email}
                                                </Text>
                                            </Flex>
                                            <Grid templateColumns="repeat(2, 1fr)" gap={5} mt={2}>
                                                <Flex direction="column" maxWidth={"100%"}>
                                                    <Flex
                                                        gap={"5px"}
                                                        align={"center"}
                                                        fontSize="sm"
                                                        color="#a3a3a3"
                                                    >
                                                        <FaPhoneAlt /> 8249892934
                                                    </Flex>
                                                    <Flex
                                                        gap={"5px"}
                                                        align={"center"}
                                                        fontSize="sm"
                                                        color="#a3a3a3"
                                                    >
                                                        <BsWhatsapp /> 8249892934
                                                    </Flex>
                                                </Flex>

                                                <Flex direction="column">
                                                    <Flex
                                                        gap={"5px"}
                                                        align={"center"}
                                                        fontSize="sm"
                                                        color="#a3a3a3"
                                                    >
                                                        <div
                                                            style={{
                                                                alignSelf: "flex-start",
                                                                marginTop: "4px",
                                                            }}
                                                        >
                                                            <FaBirthdayCake />
                                                        </div>
                                                        <Box>
                                                            <Text>12/11/1998</Text>{" "}
                                                            <Text fontSize={"xs"}>(23 Years)</Text>
                                                        </Box>
                                                    </Flex>

                                                    <Flex
                                                        gap={"5px"}
                                                        align={"center"}
                                                        fontSize="sm"
                                                        color="#a3a3a3"
                                                    >
                                                        <MdGroupWork />{" "}
                                                        {candidate.currentStatus == "Selected" && (
                                                            <Badge
                                                                variant="subtle"
                                                                colorScheme="green"
                                                            >
                                                                Selected
                                                            </Badge>
                                                        )}
                                                        {candidate.currentStatus ==
                                                            "Processing" && (
                                                            <Badge
                                                                variant="subtle"
                                                                colorScheme="blue"
                                                            >
                                                                Processing
                                                            </Badge>
                                                        )}
                                                        {candidate.currentStatus == "On Bench" && (
                                                            <Badge
                                                                variant="subtle"
                                                                colorScheme="orange"
                                                            >
                                                                On Bench
                                                            </Badge>
                                                        )}
                                                    </Flex>
                                                </Flex>
                                            </Grid>
                                        </Flex>
                                    </Flex>
                                </Box>
                                <UnorderedList my={2}>
                                    <ListItem>
                                        <b>Address: </b>{" "}
                                        {candidate.address && (
                                            <>
                                                {candidate.address.at &&
                                                    `${candidate.address.at}, `}
                                                {candidate.address.po &&
                                                    `${candidate.address.po}, `}
                                                {candidate.address.city &&
                                                    `${candidate.address.city}, `}
                                                {candidate.address.dist &&
                                                    `${candidate.address.dist}, `}
                                                {candidate.address.state &&
                                                    `${candidate.address.state}, `}
                                                {candidate.address.country &&
                                                    `${candidate.address.country}, `}
                                                {candidate.address.pin &&
                                                    `${candidate.address.pin}`}
                                            </>
                                        )}
                                    </ListItem>
                                    <ListItem>
                                        <b>Added By: </b>{" "}
                                        <Tag
                                            style={{
                                                margin: "2px 0px",
                                            }}
                                            size="lg"
                                            borderRadius="full"
                                        >
                                            <Avatar
                                                src={`${backendUrl}${candidate.addedBy.dp}`}
                                                size="xs"
                                                name={candidate.addedBy.name}
                                                ml={-1}
                                                mr={2}
                                            />
                                            <TagLabel fontSize={"sm"}>
                                                <Text fontSize={"sm"}>
                                                    ({candidate.addedBy.empId}){" "}
                                                    {candidate.addedBy.name} on{" "}
                                                    {formatDateTime(candidate.createdAt)}
                                                </Text>
                                            </TagLabel>
                                        </Tag>
                                    </ListItem>
                                    <ListItem>
                                        <b>Skills:</b>{" "}
                                        {candidate.skills?.map((skill, index) => (
                                            <Tag
                                                key={index}
                                                size="md"
                                                borderRadius="full"
                                                variant="solid"
                                                m={1}
                                            >
                                                <TagLabel>{`${skill.skillName} - ${skill.relevantExp} Years`}</TagLabel>
                                            </Tag>
                                        ))}
                                    </ListItem>
                                    <ListItem>
                                        <b>Experience: </b>{" "}
                                        {candidate.isFresher ? "Fresher" : "Experienced"}
                                        {", "}
                                        {candidate.experience}
                                        {", "}
                                        {candidate.experienceType}
                                        {" Experience"}
                                    </ListItem>
                                    <ListItem>
                                        <b>Previous Work Details: </b> <br />
                                        <Accordion allowMultiple>
                                            {candidate.workDetails.map((work, index) => (
                                                <AccordionItem key={index}>
                                                    <h2>
                                                        <AccordionButton>
                                                            <Box
                                                                as="span"
                                                                flex="1"
                                                                textAlign="left"
                                                            >
                                                                <Flex
                                                                    justify={"space-between"}
                                                                    align={"center"}
                                                                >
                                                                    <Text fontSize={"md"}>
                                                                        {work.role}
                                                                    </Text>
                                                                    <Text fontSize={"xs"}>
                                                                        {formatToShortDate(
                                                                            work.startingDate
                                                                        )}{" "}
                                                                        -{" "}
                                                                        {formatToShortDate(
                                                                            work.endingDate
                                                                        )}
                                                                    </Text>
                                                                </Flex>
                                                            </Box>
                                                            <AccordionIcon />
                                                        </AccordionButton>
                                                    </h2>
                                                    <AccordionPanel pb={4}>
                                                        <Tag
                                                            key={index}
                                                            size="md"
                                                            borderRadius="sm"
                                                            variant="solid"
                                                            colorScheme="teal"
                                                            m={1}
                                                            style={{
                                                                alignItems: "flex-start",
                                                            }}
                                                        >
                                                            <TagLabel>
                                                                <Flex direction={"column"} py={2}>
                                                                    <Text
                                                                        fontSize={"md"}
                                                                        fontWeight="bold"
                                                                    >
                                                                        {work.companyName}
                                                                    </Text>
                                                                    <Flex gap={1}>
                                                                        <Text fontSize={"sm"}>
                                                                            {
                                                                                work.companyAddress
                                                                                    .city
                                                                            }
                                                                            {", "}
                                                                        </Text>
                                                                        <Text fontSize={"sm"}>
                                                                            {
                                                                                work.companyAddress
                                                                                    .state
                                                                            }
                                                                            {", "}
                                                                        </Text>
                                                                        <Text fontSize={"sm"}>
                                                                            {
                                                                                work.companyAddress
                                                                                    .country
                                                                            }
                                                                        </Text>
                                                                    </Flex>

                                                                    <Text mt={1}>
                                                                        <span>
                                                                            <b>Work Details: </b>
                                                                        </span>
                                                                        {work.workDetails}
                                                                    </Text>
                                                                </Flex>
                                                            </TagLabel>
                                                        </Tag>
                                                    </AccordionPanel>
                                                </AccordionItem>
                                            ))}
                                        </Accordion>
                                    </ListItem>
                                    <ListItem>
                                        <b>{candidate.isFresher ? "Stipend: " : "Current CTC: "}</b>{" "}
                                        {candidate.isFresher
                                            ? `₹ ${candidate.stipend}`
                                            : `₹ ${candidate.currentCTC}`}
                                    </ListItem>
                                    <ListItem>
                                        <b>Expected CTC: </b> {"₹ "}
                                        {candidate.expectedCTC}
                                    </ListItem>
                                    {candidate.noticePeriod && (
                                        <ListItem>
                                            <b>Notice Period: </b> {candidate.noticePeriod}
                                        </ListItem>
                                    )}
                                    {candidate.otherDetails && (
                                        <ListItem>
                                            <b>Other Details: </b>
                                            <br /> {candidate.otherDetails}
                                        </ListItem>
                                    )}
                                </UnorderedList>
                                {candidate.cv && (
                                    <Box>
                                        <Heading as="h5" size="sm" mb={2} pl={1}>
                                            Resume:
                                        </Heading>{" "}
                                        <iframe
                                            src={`${backendUrl}${candidate.cv}`}
                                            width="100%"
                                            height="700px"
                                            title="CV Preview"
                                        />
                                    </Box>
                                )}
                            </Box>

                            <Box flexGrow={1}>
                                <Flex justify={"space-between"} align="center" mb={2}>
                                    <Heading as="h4" size="md" textAlign={"center"}>
                                        All Relavant JDs
                                    </Heading>
                                </Flex>
                                <div
                                    style={{
                                        height: "80vh",
                                        overflow: "auto",
                                        position: "relative",
                                    }}
                                >
                                    <InfiniteScroll
                                        dataLength={sortedData?.length}
                                        next={fetchMoreData}
                                        hasMore={hasMoreMatchingJDs}
                                        height={"100%"}
                                        loader={
                                            <>
                                                <Skeleton height="30px" m={2} />
                                                <Skeleton height="30px" m={2} />
                                                <Skeleton height="30px" m={2} />
                                            </>
                                        }
                                        endMessage={
                                            <p
                                                style={{
                                                    textAlign: "center",
                                                    marginTop: "5px",
                                                }}
                                            >
                                                <b>End of the List</b>
                                            </p>
                                        }
                                    >
                                        <TableContainer style={{ position: "relative" }}>
                                            <Table size="sm">
                                                <Thead
                                                    style={{
                                                        position: "sticky",
                                                        top: 0,
                                                        zIndex: 1,
                                                    }}
                                                >
                                                    <Tr>
                                                        <Th
                                                            style={{
                                                                color: "white",
                                                            }}
                                                            p={1}
                                                        >
                                                            <Tooltip
                                                                hasArrow
                                                                label={
                                                                    <Text fontSize={"xs"}>
                                                                        Sl. No. with tick mark if
                                                                        candidate is processed for
                                                                        respective JD with
                                                                        application Id and
                                                                        Application currentstate.
                                                                        View Application Id and
                                                                        currentstate by hovering
                                                                        mouse over the tick mark "
                                                                    </Text>
                                                                }
                                                                bg="gray.300"
                                                                color="black"
                                                                placement="top"
                                                            >
                                                                Sl
                                                            </Tooltip>
                                                        </Th>
                                                        {/* <Th
                                                            style={{
                                                                color: "white",
                                                            }}
                                                            p={0}
                                                        >
                                                            <Checkbox
                                                                size="sm"
                                                                mt={"2px"}
                                                            ></Checkbox>
                                                        </Th> */}
                                                        <Th
                                                            style={{
                                                                color: "white",
                                                            }}
                                                            pr={0}
                                                        >
                                                            <Tooltip
                                                                hasArrow
                                                                label={
                                                                    <Flex
                                                                        align={"center"}
                                                                        direction={"column"}
                                                                    >
                                                                        <Text>JD Id</Text>
                                                                        <Text
                                                                            fontSize={"xs"}
                                                                            color={"#2b2a2a"}
                                                                        >
                                                                            Click to view JD Details
                                                                        </Text>
                                                                    </Flex>
                                                                }
                                                                bg="gray.300"
                                                                color="black"
                                                                placement="top"
                                                            >
                                                                JD Id
                                                            </Tooltip>
                                                        </Th>
                                                        <Th style={{ color: "white" }}>
                                                            <Tooltip
                                                                hasArrow
                                                                label={
                                                                    <div
                                                                        style={{
                                                                            textAlign: "center",
                                                                        }}
                                                                    >
                                                                        <Text fontSize="xs">
                                                                            Click to get Related Job
                                                                            Titles
                                                                        </Text>
                                                                    </div>
                                                                }
                                                                bg="gray.300"
                                                                color="black"
                                                                placement="top"
                                                            >
                                                                Job Title (Company)
                                                            </Tooltip>
                                                        </Th>
                                                        <Th style={{ color: "white" }}>
                                                            <Tooltip
                                                                hasArrow
                                                                label={
                                                                    <div
                                                                        style={{
                                                                            textAlign: "center",
                                                                        }}
                                                                    >
                                                                        <Text
                                                                            fontSize="xs"
                                                                            color={"gray"}
                                                                        >
                                                                            Overall Required
                                                                            Experience
                                                                        </Text>
                                                                        <Text fontSize="xs">
                                                                            Skills (Relavant
                                                                            Experience req. in that
                                                                            skill)
                                                                        </Text>
                                                                        <Text
                                                                            fontSize="2xs"
                                                                            color={"gray"}
                                                                        >
                                                                            (Click to get the Skills
                                                                            List with Relevant
                                                                            Experience)
                                                                        </Text>
                                                                    </div>
                                                                }
                                                                bg="gray.300"
                                                                color="black"
                                                                placement="top"
                                                            >
                                                                Experience & Skills
                                                            </Tooltip>
                                                        </Th>
                                                        <Th style={{ color: "white" }}>
                                                            <Tooltip
                                                                hasArrow
                                                                label="No. of Remaining Positions"
                                                                bg="gray.300"
                                                                color="black"
                                                                placement="top"
                                                            >
                                                                Re
                                                            </Tooltip>
                                                        </Th>
                                                        <Th
                                                            style={{
                                                                color: "white",
                                                            }}
                                                        >
                                                            <Tooltip
                                                                hasArrow
                                                                label="Closing Date and Offered Salary"
                                                                bg="gray.300"
                                                                color="black"
                                                                placement="top"
                                                            >
                                                                cl Dt. & Sal.
                                                            </Tooltip>
                                                        </Th>
                                                        <Th
                                                            style={{
                                                                color: "white",
                                                            }}
                                                            p={0}
                                                        >
                                                            <Tooltip
                                                                hasArrow
                                                                label="Percentage Matching"
                                                                bg="gray.300"
                                                                color="black"
                                                                placement="top"
                                                            >
                                                                % mat.
                                                            </Tooltip>
                                                        </Th>
                                                        <Th
                                                            style={{
                                                                color: "white",
                                                            }}
                                                        >
                                                            <Tooltip
                                                                hasArrow
                                                                label="Menu button for processing candidate for JDs"
                                                                bg="gray.300"
                                                                color="black"
                                                                placement="top"
                                                            >
                                                                <Box>
                                                                    <MdMenu />
                                                                </Box>
                                                            </Tooltip>
                                                        </Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {sortedData &&
                                                        sortedData?.map((data, index) => (
                                                            <Tr key={index}>
                                                                <Td p={1} position={"relative"}>
                                                                    {index + 1}
                                                                    {data?.addingStatus && (
                                                                        <Box
                                                                            position={"absolute"}
                                                                            bottom={"5px"}
                                                                            left={"0px"}
                                                                            cursor={"pointer"}
                                                                        >
                                                                            <Tooltip
                                                                                hasArrow
                                                                                label={
                                                                                    <>
                                                                                        <Text
                                                                                            fontSize={
                                                                                                "xs"
                                                                                            }
                                                                                        >
                                                                                            Candidate{" "}
                                                                                            <b>
                                                                                                {
                                                                                                    candidate.stuId
                                                                                                }
                                                                                            </b>{" "}
                                                                                            added to{" "}
                                                                                            <b>
                                                                                                {
                                                                                                    data
                                                                                                        ?.jobDescriptionId
                                                                                                        ?.jobId
                                                                                                }
                                                                                            </b>
                                                                                        </Text>
                                                                                        <hr />
                                                                                        <Text>
                                                                                            <b>
                                                                                                Application
                                                                                                Id-
                                                                                            </b>{" "}
                                                                                            {
                                                                                                data?.applicationID
                                                                                            }
                                                                                        </Text>
                                                                                        <Text>
                                                                                            <b>
                                                                                                Application
                                                                                                Status-
                                                                                            </b>{" "}
                                                                                            {
                                                                                                data?.currentState
                                                                                            }
                                                                                        </Text>
                                                                                    </>
                                                                                }
                                                                                bg="gray.300"
                                                                                color="black"
                                                                                placement="bottom"
                                                                            >
                                                                                ✔️
                                                                            </Tooltip>
                                                                        </Box>
                                                                    )}
                                                                </Td>
                                                                {/* <Td p={0}>
                                                                    <Checkbox
                                                                        size="sm"
                                                                        mt={"2px"}
                                                                    ></Checkbox>
                                                                </Td> */}
                                                                <Td
                                                                    style={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() =>
                                                                        handleJDPreview(
                                                                            data?.jobDescriptionId
                                                                        )
                                                                    }
                                                                >
                                                                    {data?.jobDescriptionId?.jobId}
                                                                </Td>

                                                                <Td>
                                                                    {!data?.jobDescriptionId
                                                                        ?.similarTags ? (
                                                                        <Box>
                                                                            <Text
                                                                                style={{
                                                                                    textOverflow:
                                                                                        "ellipsis",
                                                                                    overflow:
                                                                                        "hidden",
                                                                                    maxWidth:
                                                                                        "150px",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    data
                                                                                        ?.jobDescriptionId
                                                                                        ?.jobTitle
                                                                                }
                                                                            </Text>
                                                                            <Text
                                                                                fontSize={"xs"}
                                                                                color={"gray"}
                                                                                style={{
                                                                                    textOverflow:
                                                                                        "ellipsis",
                                                                                    overflow:
                                                                                        "hidden",
                                                                                    maxWidth:
                                                                                        "150px",
                                                                                }}
                                                                            >
                                                                                (
                                                                                {
                                                                                    data
                                                                                        ?.jobDescriptionId
                                                                                        ?.companyDetails
                                                                                        .companyName
                                                                                }
                                                                                )
                                                                            </Text>
                                                                        </Box>
                                                                    ) : (
                                                                        <Popover>
                                                                            <PopoverTrigger>
                                                                                <Box
                                                                                    cursor={
                                                                                        "pointer"
                                                                                    }
                                                                                >
                                                                                    <Text
                                                                                        style={{
                                                                                            textOverflow:
                                                                                                "ellipsis",
                                                                                            overflow:
                                                                                                "hidden",
                                                                                            maxWidth:
                                                                                                "150px",
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            data
                                                                                                ?.jobDescriptionId
                                                                                                ?.jobTitle
                                                                                        }
                                                                                    </Text>
                                                                                    <Text
                                                                                        fontSize={
                                                                                            "xs"
                                                                                        }
                                                                                        color={
                                                                                            "gray"
                                                                                        }
                                                                                        style={{
                                                                                            textOverflow:
                                                                                                "ellipsis",
                                                                                            overflow:
                                                                                                "hidden",
                                                                                            maxWidth:
                                                                                                "150px",
                                                                                        }}
                                                                                    >
                                                                                        (
                                                                                        {
                                                                                            data
                                                                                                ?.jobDescriptionId
                                                                                                ?.clientDetails
                                                                                                ?.client
                                                                                                ?.name
                                                                                        }
                                                                                        )
                                                                                    </Text>
                                                                                </Box>
                                                                            </PopoverTrigger>
                                                                            <PopoverContent>
                                                                                <PopoverArrow />
                                                                                <PopoverCloseButton />
                                                                                <PopoverHeader>
                                                                                    Similar Job
                                                                                    Titles
                                                                                </PopoverHeader>
                                                                                <PopoverBody
                                                                                    style={{
                                                                                        display:
                                                                                            "flex",
                                                                                        flexWrap:
                                                                                            "wrap",
                                                                                        gap: "5px",
                                                                                    }}
                                                                                >
                                                                                    {data?.jobDescriptionId?.similarTags.map(
                                                                                        (
                                                                                            tag,
                                                                                            index
                                                                                        ) => (
                                                                                            <span
                                                                                                key={
                                                                                                    index
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    tag
                                                                                                }
                                                                                                {index <
                                                                                                    data
                                                                                                        ?.jobDescriptionId
                                                                                                        ?.similarTags
                                                                                                        .length -
                                                                                                        1 &&
                                                                                                    ", "}
                                                                                            </span>
                                                                                        )
                                                                                    )}
                                                                                </PopoverBody>
                                                                            </PopoverContent>
                                                                        </Popover>
                                                                    )}
                                                                </Td>
                                                                <Td>
                                                                    <Popover>
                                                                        <PopoverTrigger>
                                                                            <Box cursor={"pointer"}>
                                                                                <Text
                                                                                    fontSize={"xs"}
                                                                                    color={"gray"}
                                                                                >
                                                                                    Overall Exp.-{" "}
                                                                                    {
                                                                                        data
                                                                                            ?.jobDescriptionId
                                                                                            ?.experience
                                                                                    }{" "}
                                                                                    Years
                                                                                </Text>
                                                                                <Text>
                                                                                    {printSkills(
                                                                                        data
                                                                                            ?.jobDescriptionId
                                                                                            ?.skills,
                                                                                        20
                                                                                    )}
                                                                                </Text>
                                                                            </Box>
                                                                        </PopoverTrigger>
                                                                        <PopoverContent w={"200px"}>
                                                                            <PopoverArrow />
                                                                            <PopoverCloseButton />
                                                                            <PopoverHeader>
                                                                                Skills
                                                                            </PopoverHeader>
                                                                            <PopoverBody
                                                                                style={{
                                                                                    textWrap:
                                                                                        "wrap",
                                                                                    lineHeight:
                                                                                        "normal",
                                                                                }}
                                                                            >
                                                                                {data?.jobDescriptionId?.skills?.map(
                                                                                    (
                                                                                        skill,
                                                                                        index
                                                                                    ) => (
                                                                                        <Text
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                        >{`${skill.skillName} - ${skill.relevantExp} Years`}</Text>
                                                                                    )
                                                                                )}
                                                                            </PopoverBody>
                                                                        </PopoverContent>
                                                                    </Popover>
                                                                </Td>
                                                                <Td>
                                                                    {data?.jobDescriptionId
                                                                        ?.numberOfOpenings -
                                                                        data?.jobDescriptionId
                                                                            ?.noOfClosings}
                                                                </Td>
                                                                <Td>
                                                                    <Text>
                                                                        {formatDate(
                                                                            data?.jobDescriptionId
                                                                                ?.closingDate
                                                                        )}
                                                                    </Text>
                                                                    <Text>
                                                                        ₹{" "}
                                                                        {
                                                                            data?.jobDescriptionId
                                                                                ?.salaryOffering
                                                                        }
                                                                    </Text>
                                                                </Td>

                                                                <Td p={0}>
                                                                    {parseFloat(
                                                                        data.percentageMatching.toFixed(
                                                                            2
                                                                        )
                                                                    )}{" "}
                                                                    %
                                                                </Td>

                                                                <Td>
                                                                    {data?.addingStatus ? (
                                                                        <>
                                                                            <Tooltip
                                                                                hasArrow
                                                                                label="Already added"
                                                                                bg="gray.300"
                                                                                color="black"
                                                                                placement="top"
                                                                            >
                                                                                <Box>
                                                                                    <BsSlashCircle
                                                                                        style={{
                                                                                            height: "10px",
                                                                                            color: "#fd9494",
                                                                                        }}
                                                                                    />
                                                                                </Box>
                                                                            </Tooltip>
                                                                        </>
                                                                    ) : (
                                                                        <Tooltip
                                                                            hasArrow
                                                                            label={
                                                                                <>
                                                                                    Click to Process
                                                                                    for-{" "}
                                                                                    <b>
                                                                                        {
                                                                                            data
                                                                                                ?.jobDescriptionId
                                                                                                ?.jobId
                                                                                        }
                                                                                    </b>
                                                                                </>
                                                                            }
                                                                            bg="gray.300"
                                                                            color="black"
                                                                            placement="top"
                                                                        >
                                                                            <Box>
                                                                                <MdPlaylistAdd
                                                                                    cursor={
                                                                                        "pointer"
                                                                                    }
                                                                                    onClick={() =>
                                                                                        handleStartApplication(
                                                                                            data
                                                                                                .jobDescriptionId
                                                                                                ._id,
                                                                                            data._id
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Box>
                                                                        </Tooltip>
                                                                    )}
                                                                </Td>
                                                            </Tr>
                                                        ))}
                                                </Tbody>
                                            </Table>
                                        </TableContainer>
                                    </InfiniteScroll>
                                    {selectedJD && isJdDetailsModalOpen && (
                                        <JdDetails
                                            jd={selectedJD}
                                            isOpen={isJdDetailsModalOpen}
                                            onClose={handleJDViewCloseModal}
                                        />
                                    )}
                                </div>
                            </Box>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default ProcessCandidate;
