import React, { useState, useRef, useEffect, useCallback } from "react";
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    Tag,
    TagLabel,
    TagCloseButton,
    List,
    ListItem,
    Avatar,
    Flex,
    Text,
} from "@chakra-ui/react";
import { useCombobox } from "downshift";
import debounce from "lodash.debounce";
import { useUser } from "../../context/UserContext";
import { useEnv } from "../../context/EnvContext";

const RecruitersInput = ({ workingRecruiters, setWorkingRecruiters }) => {
    const { backendUrl } = useEnv();
    const { fetchSearchedUsers, searchedUsers } = useUser();

    const [inputValue, setInputValue] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const recruiterInputRef = useRef(null);

    const debouncedFetchRecruiters = useCallback(debounce(fetchSearchedUsers, 300), []);

    const {
        isOpen,
        getMenuProps,
        getInputProps,
        getItemProps,
        highlightedIndex,
        setHighlightedIndex,
        openMenu,
    } = useCombobox({
        items: searchedUsers,
        onInputValueChange: ({ inputValue }) => {
            // debouncedFetchRecruiters(searchQuery, "Recruiter");
        },
        onSelectedItemChange: ({ selectedItem }) => {
            if (selectedItem) {
                addRecruiter(selectedItem);
            }
        },
    });

    console.log("workingRecruiters", workingRecruiters);

    const addRecruiter = (recruiter) => {
        if (recruiter && !workingRecruiters.some((r) => r._id === recruiter._id)) {
            setWorkingRecruiters([...workingRecruiters, recruiter]);
            setTimeout(() => {
                recruiterInputRef.current?.focus();
                setHighlightedIndex(0);
            }, 0);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && inputValue && highlightedIndex === -1) {
            addRecruiter({ name: inputValue }); // Add custom recruiter if none selected
        }
    };

    const removeRecruiter = (recruiterToRemove) => {
        setWorkingRecruiters(
            workingRecruiters.filter((recruiter) => recruiter !== recruiterToRemove)
        );
    };

    useEffect(() => {
        if (recruiterInputRef.current) {
            recruiterInputRef.current.addEventListener("keydown", handleKeyDown);
        }
        return () => {
            if (recruiterInputRef.current) {
                recruiterInputRef.current.removeEventListener("keydown", handleKeyDown);
            }
        };
    }, [recruiterInputRef.current, handleKeyDown]);

    return (
        <FormControl>
            <FormLabel mb={0}>Assign Recruiters</FormLabel>
            <Box>
                <List {...getMenuProps()} borderRadius="md" maxH="150px" overflowY="auto">
                    {searchQuery &&
                        searchedUsers.map((item, index) => (
                            <ListItem
                                key={index}
                                {...getItemProps({ item, index })}
                                bg={highlightedIndex === index ? "#ffffff29" : "#ffffff14"}
                                p={2}
                                cursor="pointer"
                            >
                                <Flex pl={2} align={"center"} gap={1}>
                                    <Avatar
                                        src={`${backendUrl}${item.dp}`}
                                        size="xs"
                                        name={item.name}
                                        ml={-1}
                                        mr={1}
                                    />
                                    <Text>
                                        <b>{item.empId}</b>
                                    </Text>
                                    <Text>- {item.name}</Text>
                                </Flex>
                            </ListItem>
                        ))}
                </List>
                <Input
                    {...getInputProps({
                        placeholder: "Type recruiter name or id, Ex. John Doe",
                        ref: recruiterInputRef,
                        value: searchQuery,
                        onChange: (e) => {
                            setSearchQuery(e.target.value);
                            debouncedFetchRecruiters(e.target.value, "Recruiter");
                        },
                    })}
                />
            </Box>
            {workingRecruiters.length > 0 && (
                <Box mt={2}>
                    {workingRecruiters.map((recruiter, index) => (
                        <Tag
                            key={index}
                            size="lg"
                            borderRadius="full"
                            variant="solid"
                            fontSize={"sm"}
                            colorScheme="teal"
                            mt={1}
                            mx={1}
                        >
                            <Avatar
                                src={`${backendUrl}${recruiter.dp}`}
                                size="xs"
                                name={recruiter.name}
                                ml={-2}
                                mr={2}
                            />
                            <TagLabel fontSize={"sm"}>
                                ({recruiter.empId}) {recruiter.name}
                            </TagLabel>
                            <TagCloseButton onClick={() => removeRecruiter(recruiter)} />
                        </Tag>
                    ))}
                </Box>
            )}
        </FormControl>
    );
};

export default RecruitersInput;
