import React, { useState, useEffect } from "react";

import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Badge,
    Text,
    Box,
    Tooltip,
    Flex,
    Skeleton,
    MenuDivider,
    Avatar,
} from "@chakra-ui/react";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverArrow,
    PopoverCloseButton,
} from "@chakra-ui/react";

import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import { GrMore } from "react-icons/gr";
import EmployeeDetails from "./EmployeeDetails";
import { FaLock, FaSort, FaWhatsapp } from "react-icons/fa";
import { MdMenu } from "react-icons/md";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { SiTicktick } from "react-icons/si";

import { formatDateTime, formatToShortDate } from "../../../Utils/GlobalFunctions/FormatDate";
import { printSkills } from "../../../Utils/GlobalFunctions/PrintSkills";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAuth } from "../../../context/AuthContext";
import EmployeeForm from "./EmployeeForm";
import { useUser } from "../../../context/UserContext";
import { IoCall } from "react-icons/io5";
import { useEnv } from "../../../context/EnvContext";

const EmployeeBody = ({ data, hasMore, pageNo, status }) => {
    console.log("data", data);
    console.log("hasMore", hasMore);
    console.log("pageNo", pageNo);
    console.log("status", status);
    const { userData } = useAuth();
    const { backendUrl } = useEnv();
    const { loading, fetchAllUsers, blockUser, deleteUser, unblockUser, retrieveUser } = useUser();

    const [isEmployeeDetailsModalOpen, setIsEmployeeDetailsModalOpen] = useState(false);
    const [isEmployeeFormModalOpen, setIsEmployeeFormModalOpen] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [sortedData, setSortedData] = useState(data);
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: null,
    });

    // Sort the data
    useEffect(() => {
        if (!Array.isArray(data)) {
            console.error("Data is not an array");
            return;
        }
        let sortedArray = [...data];
        if (sortConfig.key) {
            sortedArray?.sort((a, b) => {
                let aValue = a[sortConfig.key];
                let bValue = b[sortConfig.key];

                // Handle nested keys for companyName
                if (sortConfig.key === "companyDetails.companyName") {
                    aValue = a.companyDetails.companyName;
                    bValue = b.companyDetails.companyName;
                }

                if (aValue < bValue) {
                    return sortConfig.direction === "ascending" ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === "ascending" ? 1 : -1;
                }
                return 0;
            });
        }
        setSortedData(sortedArray);
    }, [sortConfig, data]);

    const fetchMoreData = () => {
        console.log("fetching more data.......");
        fetchAllUsers(status, pageNo, 30, true);
    };

    const requestSort = (key) => {
        let direction = "ascending";
        if (sortConfig.key === key && sortConfig.direction === "ascending") {
            direction = "descending";
        } else if (sortConfig.key === key && sortConfig.direction === "descending") {
            setSortConfig({ key: null, direction: null });
            return;
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            if (sortConfig.direction === "ascending") {
                return <TiArrowSortedUp cursor={"pointer"} />;
            } else if (sortConfig.direction === "descending") {
                return <TiArrowSortedDown cursor={"pointer"} />;
            }
        }
        return <FaSort cursor={"pointer"} />;
    };

    // Handle Employee Details modal Preview
    const handleEmployeePreview = (jd) => {
        setSelectedEmployee(jd);
        setIsEmployeeDetailsModalOpen(true);
    };

    const handleEmployeeViewCloseModal = () => {
        setIsEmployeeDetailsModalOpen(false);
        setSelectedEmployee(null);
    };

    // Handle Employee Form modal Preview
    const handleEmployeeForm = (emp) => {
        setSelectedEmployee(emp);
        setIsEmployeeFormModalOpen(true);
    };

    const handleEmployeeFormCloseModal = () => {
        setIsEmployeeFormModalOpen(false);
    };
    return (
        <div
            style={{
                height: "75vh",
                overflow: "auto",
                position: "relative",
            }}
        >
            <InfiniteScroll
                dataLength={sortedData.length}
                next={fetchMoreData}
                hasMore={hasMore}
                height={"100%"}
                loader={
                    <>
                        <Skeleton height="30px" m={2} />
                        <Skeleton height="30px" m={2} />
                        <Skeleton height="30px" m={2} />
                    </>
                }
                endMessage={
                    <p style={{ textAlign: "center", marginTop: "5px" }}>
                        <b>End of the List</b>
                    </p>
                }
            >
                <TableContainer
                    style={{ maxHeight: "100%" }}
                    overflowY={sortedData.length < 5 ? "auto" : "clip"}
                    overflowX={sortedData.length < 5 ? "clip" : "clip"}
                >
                    <Table size="sm">
                        <Thead
                            style={{
                                position: "sticky",
                                top: 0,
                                backgroundColor: "#05080D",
                                zIndex: 1,
                            }}
                        >
                            <Tr>
                                <Th style={{ color: "white" }}>Sl</Th>

                                <Th style={{ color: "white" }}>
                                    <Tooltip
                                        hasArrow
                                        label={
                                            <Flex align={"center"} direction={"column"}>
                                                <Text>Account Status</Text>
                                                <Flex
                                                    align={"center"}
                                                    gap={1}
                                                    fontSize={"xs"}
                                                    color={"#2b2a2a"}
                                                >
                                                    <FaLock />
                                                    Blocked
                                                </Flex>
                                                <Flex
                                                    align={"center"}
                                                    gap={1}
                                                    fontSize={"xs"}
                                                    color={"#2b2a2a"}
                                                >
                                                    <RiDeleteBin2Fill />
                                                    Deleted
                                                </Flex>
                                                <Flex
                                                    align={"center"}
                                                    gap={1}
                                                    fontSize={"xs"}
                                                    color={"#2b2a2a"}
                                                >
                                                    <SiTicktick />
                                                    Active
                                                </Flex>
                                            </Flex>
                                        }
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                    >
                                        ACs
                                    </Tooltip>
                                </Th>
                                <Th style={{ color: "white" }} onClick={() => requestSort("empId")}>
                                    <Flex
                                        alignItems={"center"}
                                        width={"100%"}
                                        justify={"space-between"}
                                    >
                                        Id {getSortIcon("empId")}
                                    </Flex>
                                </Th>
                                <Th style={{ color: "white" }}>
                                    <Flex
                                        alignItems={"center"}
                                        width={"100%"}
                                        justify={"space-between"}
                                    >
                                        DP
                                    </Flex>
                                </Th>
                                <Th style={{ color: "white" }} onClick={() => requestSort("name")}>
                                    <Flex
                                        alignItems={"center"}
                                        width={"100%"}
                                        justify={"space-between"}
                                    >
                                        Name {getSortIcon("name")}
                                    </Flex>
                                </Th>
                                <Th style={{ color: "white" }} onClick={() => requestSort("email")}>
                                    <Flex
                                        alignItems={"center"}
                                        width={"100%"}
                                        justify={"space-between"}
                                    >
                                        Email {getSortIcon("email")}
                                    </Flex>
                                </Th>
                                <Th
                                    style={{ color: "white" }}
                                    onClick={() => requestSort("gender")}
                                >
                                    <Flex
                                        alignItems={"center"}
                                        width={"100%"}
                                        justify={"space-between"}
                                    >
                                        Gender {getSortIcon("gender")}
                                    </Flex>
                                </Th>
                                <Th
                                    style={{ color: "white" }}
                                    onClick={() => requestSort("phoneNo")}
                                >
                                    <Flex
                                        alignItems={"center"}
                                        width={"100%"}
                                        justify={"space-between"}
                                    >
                                        Phone No {getSortIcon("phoneNo")}
                                    </Flex>
                                </Th>
                                <Th
                                    style={{ color: "white" }}
                                    onClick={() => requestSort("whatsAppNo")}
                                >
                                    <Flex
                                        alignItems={"center"}
                                        width={"100%"}
                                        justify={"space-between"}
                                    >
                                        Whatsapp {getSortIcon("whatsAppNo")}
                                    </Flex>
                                </Th>
                                <Th
                                    style={{ color: "white" }}
                                    onClick={() => requestSort("userType")}
                                >
                                    <Flex
                                        alignItems={"center"}
                                        width={"100%"}
                                        justify={"space-between"}
                                    >
                                        Designation {getSortIcon("userType")}
                                    </Flex>
                                </Th>
                                <Th
                                    style={{ color: "white" }}
                                    onClick={() => requestSort("createdAt")}
                                >
                                    <Flex
                                        alignItems={"center"}
                                        width={"100%"}
                                        justify={"space-between"}
                                    >
                                        Added On {getSortIcon("createdAt")}
                                    </Flex>
                                </Th>
                                <Th
                                    style={{
                                        color: "white",
                                    }}
                                >
                                    <Tooltip
                                        hasArrow
                                        label='Click on respective "Menu Button" for more options.'
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                        maxW={"200px"}
                                    >
                                        <Box>
                                            <MdMenu />
                                        </Box>
                                    </Tooltip>
                                </Th>
                            </Tr>
                        </Thead>

                        <Tbody>
                            {sortedData &&
                                sortedData?.map((employee, index) => (
                                    <>
                                        {employee._id !== userData.userId && (
                                            <Tr key={index}>
                                                <Td>{index + 1}</Td>
                                                <Td px={0}>
                                                    <Flex gap={1} justify={"center"}>
                                                        {employee.isBlocked && (
                                                            <FaLock color="#f25c5c" />
                                                        )}
                                                        {employee.isDeleted && (
                                                            <RiDeleteBin2Fill color="red" />
                                                        )}
                                                        {!employee.isDeleted &&
                                                            !employee.isBlocked && (
                                                                <SiTicktick color="green" />
                                                            )}
                                                    </Flex>
                                                </Td>
                                                <Td
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => handleEmployeePreview(employee)}
                                                >
                                                    {employee?.empId}
                                                </Td>
                                                <Td>
                                                    <Avatar
                                                        src={`${backendUrl}${employee.dp}`}
                                                        size="xs"
                                                        name={employee.name}
                                                    />
                                                </Td>
                                                <Td>{employee?.name}</Td>
                                                <Td>{employee?.email}</Td>
                                                <Td>
                                                    {employee?.whatsAppNo ? (
                                                        <Text textAlign={"center"}>
                                                            {employee?.gender}
                                                        </Text>
                                                    ) : (
                                                        <Text textAlign={"center"}>-</Text>
                                                    )}
                                                </Td>
                                                <Td>
                                                    {employee?.phoneNo ? (
                                                        <Flex gap={1} alignItems={"center"}>
                                                            <a
                                                                target="_blank"
                                                                href={`tel:${employee?.phoneNo}`}
                                                            >
                                                                <IoCall color="cyan" />
                                                            </a>
                                                            <Text fontSize={"xs"}>
                                                                <a
                                                                    target="_blank"
                                                                    href={`tel:${employee?.phoneNo}`}
                                                                >
                                                                    {employee?.phoneNo}
                                                                </a>
                                                            </Text>
                                                        </Flex>
                                                    ) : (
                                                        <Text textAlign={"center"}>-</Text>
                                                    )}
                                                </Td>
                                                <Td>
                                                    {" "}
                                                    {employee?.whatsAppNo ? (
                                                        <Flex gap={1} alignItems={"center"}>
                                                            <a
                                                                target="_blank"
                                                                href={`https://wa.me/${employee?.whatsAppNo}`}
                                                            >
                                                                <FaWhatsapp color="lime" />
                                                            </a>
                                                            <Text fontSize={"xs"}>
                                                                <a
                                                                    target="_blank"
                                                                    href={`https://wa.me/${employee?.whatsAppNo}`}
                                                                >
                                                                    {employee?.whatsAppNo}
                                                                </a>
                                                            </Text>
                                                        </Flex>
                                                    ) : (
                                                        <Text textAlign={"center"}>-</Text>
                                                    )}
                                                </Td>
                                                <Td>{employee?.userType}</Td>
                                                <Td style={{ cursor: "pointer" }}>
                                                    <Text>
                                                        {formatDateTime(employee.createdAt)}
                                                    </Text>
                                                </Td>
                                                <Td>
                                                    <Menu>
                                                        <MenuButton>
                                                            <GrMore />
                                                        </MenuButton>
                                                        <MenuList minW={"50px"}>
                                                            <MenuItem>Employee Details</MenuItem>
                                                            {!employee.isBlocked &&
                                                                !employee.isDeleted && (
                                                                    <MenuItem
                                                                        color={"#d8a953"}
                                                                        onClick={() => {
                                                                            handleEmployeeForm(
                                                                                employee
                                                                            );
                                                                        }}
                                                                    >
                                                                        Update Employee
                                                                    </MenuItem>
                                                                )}
                                                            {employee.isBlocked ? (
                                                                <MenuItem
                                                                    color={"#f25c5c"}
                                                                    onClick={() => {
                                                                        unblockUser(
                                                                            employee._id,
                                                                            employee.empId,
                                                                            status
                                                                        );
                                                                    }}
                                                                    cursor={
                                                                        loading
                                                                            ? "progress"
                                                                            : "pointer"
                                                                    }
                                                                >
                                                                    Unblock Employee
                                                                </MenuItem>
                                                            ) : (
                                                                <MenuItem
                                                                    color={"#f25c5c"}
                                                                    onClick={() => {
                                                                        blockUser(
                                                                            employee._id,
                                                                            employee.empId,
                                                                            status
                                                                        );
                                                                    }}
                                                                    cursor={
                                                                        loading
                                                                            ? "progress"
                                                                            : "pointer"
                                                                    }
                                                                >
                                                                    Block Employee
                                                                </MenuItem>
                                                            )}

                                                            {employee.isDeleted ? (
                                                                <MenuItem
                                                                    color={"red"}
                                                                    onClick={() => {
                                                                        retrieveUser(
                                                                            employee._id,
                                                                            employee.empId,
                                                                            status
                                                                        );
                                                                    }}
                                                                    cursor={
                                                                        loading
                                                                            ? "progress"
                                                                            : "pointer"
                                                                    }
                                                                >
                                                                    Retrive Employee
                                                                </MenuItem>
                                                            ) : (
                                                                <MenuItem
                                                                    color={"red"}
                                                                    onClick={() => {
                                                                        deleteUser(
                                                                            employee._id,
                                                                            employee.empId,
                                                                            status
                                                                        );
                                                                    }}
                                                                    cursor={
                                                                        loading
                                                                            ? "progress"
                                                                            : "pointer"
                                                                    }
                                                                >
                                                                    Delete Employee
                                                                </MenuItem>
                                                            )}
                                                        </MenuList>
                                                    </Menu>
                                                </Td>
                                            </Tr>
                                        )}
                                    </>
                                ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </InfiniteScroll>
            {selectedEmployee && isEmployeeDetailsModalOpen && (
                <EmployeeDetails
                    user={selectedEmployee}
                    isOpen={isEmployeeDetailsModalOpen}
                    onClose={handleEmployeeViewCloseModal}
                    operation="User Profile"
                />
            )}
            {selectedEmployee && isEmployeeFormModalOpen && (
                <EmployeeForm
                    operation={"Update Employee"}
                    isOpen={isEmployeeFormModalOpen}
                    onClose={handleEmployeeFormCloseModal}
                    employee={selectedEmployee}
                    status={status}
                />
            )}
        </div>
    );
};

export default EmployeeBody;
