import axios from "axios";
import React, { createContext, useState, useContext, useMemo } from "react";
import { useEnv } from "./EnvContext";
import { useAuth } from "./AuthContext";
import useGlobalToast from "../Utils/GlobalFunctions/toast";
import { getStatusCtoS, getStatusStoC } from "../Utils/GlobalFunctions/GetStatus";

const CandidateContext = createContext();

export const CandidateContextProvider = ({ children }) => {
    const toast = useGlobalToast();
    const { backendUrl } = useEnv();
    const { userData } = useAuth();

    const [candidateData, setCandidateData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingCandidate, setLoadingCandidate] = useState(false);
    const [error, setError] = useState(null);

    // handle matching candidates
    const [allMatchingCandidates, setAllMatchingCandidates] = useState({
        all: [],
        selected: [],
        processing: [],
        bench: [],
    });
    const [hasMoreMatchingCandidates, setHasMoreMatchingCandidates] = useState({
        all: true,
        selected: true,
        processing: true,
        bench: true,
    });
    const [matchingCandidatesPageNo, setMatchingCandidatesPageNo] = useState({
        all: 1,
        selected: 1,
        processing: 1,
        bench: 1,
    });

    // handle candidates
    const [allCandidates, setAllCandidates] = useState({
        all: [],
        selected: [],
        processing: [],
        onBench: [],
    });
    const [hasMoreCandidates, setHasMoreCandidates] = useState({
        all: true,
        selected: true,
        processing: true,
        onBench: true,
    });
    const [candidatesPageNo, setCandidatesPageNo] = useState({
        all: 1,
        selected: 1,
        processing: 1,
        onBench: 1,
    });

    const memoizedCandidates = useMemo(() => allCandidates, [allCandidates]);

    // Fetch All Matching Candidates
    const fetchMatchingCandidates = async (
        status,
        page = 1,
        limit = 30,
        updateData = false,
        jobDescriptionId
    ) => {
        console.log("fetching matching candidates of status ", status, updateData);
        console.log("page ", page);
        setLoading(true);
        setError(null);
        try {
            let cs;
            switch (status) {
                case "all":
                    status = "all";
                    cs = "";
                    break;
                case "selected":
                    status = "selected";
                    cs = "Selected";
                    break;
                case "processing":
                    status = "processing";
                    cs = "Processing";
                    break;
                case "bench":
                    status = "bench";
                    cs = "On Bench";
                    break;
                default:
                    status = "all";
                    cs = "";
                    break;
            }

            const token = userData?.token;
            const response = await axios.get(
                `${backendUrl}/candidate/all-percentage?jobDescriptionId=${jobDescriptionId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        status: cs,
                        page,
                        limit,
                    },
                }
            );

            const fetchedMatchingCandidates = response.data;
            console.log("fetchedMatchingCandidates", fetchedMatchingCandidates);

            if (updateData) {
                setAllMatchingCandidates((prevState) => ({
                    ...prevState,
                    [status]: [...prevState[status], ...fetchedMatchingCandidates],
                }));

                setMatchingCandidatesPageNo((prevState) => ({
                    ...prevState,
                    [status]: prevState[status] + 1,
                }));

                if (fetchedMatchingCandidates.length < limit) {
                    setHasMoreMatchingCandidates((prevState) => ({
                        ...prevState,
                        [status]: false,
                    }));
                }
            } else {
                setAllMatchingCandidates((prevState) => ({
                    ...prevState,
                    [status]: fetchedMatchingCandidates,
                }));

                setMatchingCandidatesPageNo((prevState) => ({
                    ...prevState,
                    [status]: 2,
                }));

                setHasMoreMatchingCandidates((prevState) => ({
                    ...prevState,
                    [status]: fetchedMatchingCandidates.length === limit,
                }));
            }

            toast.success(`${status} Candidates fetched successfully!`);
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch All Candidates
    const fetchAllCandidates = async (status, page = 1, limit = 30, updateData = false) => {
        setLoading(true);
        setError(null);

        try {
            if (!updateData && memoizedCandidates[status].length > 0) {
                setLoading(false);
                return;
            }
            let requestStatus = getStatusStoC(status);
            const token = userData?.token;
            const response = await axios.get(`${backendUrl}/candidate/all`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    status: requestStatus,
                    page,
                    limit,
                },
            });

            const fetchedCandidates = response.data;
            console.log("fetchedCandidates", fetchedCandidates);

            if (updateData) {
                setAllCandidates((prevState) => ({
                    ...prevState,
                    [status]: [...prevState[status], ...fetchedCandidates],
                }));

                setCandidatesPageNo((prevState) => ({
                    ...prevState,
                    [status]: prevState[status] + 1,
                }));

                if (fetchedCandidates.length < limit) {
                    setHasMoreCandidates((prevState) => ({
                        ...prevState,
                        [status]: false,
                    }));
                }
            } else {
                setAllCandidates((prevState) => ({
                    ...prevState,
                    [status]: fetchedCandidates,
                }));

                setCandidatesPageNo((prevState) => ({
                    ...prevState,
                    [status]: 2,
                }));

                setHasMoreCandidates((prevState) => ({
                    ...prevState,
                    [status]: fetchedCandidates.length === limit,
                }));
            }

            toast.success(`Candidates fetched successfully!`);
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch Specific Candidates
    const fetchSpecificCandidate = async (candidateId) => {
        console.log("fetching candidate ", candidateId);
        setLoadingCandidate(true);
        setError(null);
        try {
            const token = userData?.token;
            const response = await axios.get(`${backendUrl}/candidate`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    candidateId,
                },
            });

            const fetchedCandidate = response.data;
            console.log("fetchedCandidate", fetchedCandidate);
            setCandidateData(fetchedCandidate);

            // toast.success(`Candidate fetched successfully!`);
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoadingCandidate(false);
        }
    };

    // Add Candidate
    const addCandidate = async (candidate) => {
        setLoading(true);
        setError(null);

        const newCandidate = new FormData();
        Object.keys(candidate).forEach((key) => {
            if (key === "workDetails" || key === "address" || key === "skills") {
                newCandidate.append(key, JSON.stringify(candidate[key]));
            } else {
                newCandidate.append(key, candidate[key]);
            }
        });
        try {
            const token = userData?.token;
            const response = await axios.post(`${backendUrl}/candidate/add`, newCandidate, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log("add candidate response", response);

            const newCand = response.data.candidate;
            const status = getStatusCtoS(candidate.currentStatus);

            setAllCandidates((prevState) => ({
                ...prevState,
                [status]: [...prevState[status], newCand],
                all: [...prevState.all, newCand],
            }));

            toast.success("New Candidate Added Successfully!");
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    // Update Candidate
    const updateCandidate = async (candidate, candidateId, candidatePrevStatus) => {
        setLoading(true);
        setError(null);
        const UpdatedCandidate = new FormData();

        Object.keys(candidate).forEach((key) => {
            if (key === "workDetails" || key === "address" || key === "skills") {
                UpdatedCandidate.append(key, JSON.stringify(candidate[key]));
            } else {
                UpdatedCandidate.append(key, candidate[key]);
            }
        });
        try {
            const token = userData?.token;
            const response = await axios.put(
                `${backendUrl}/candidate/update?candidateId=${candidateId}`,
                UpdatedCandidate,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log("Update candidate response", response);

            const updatedCandidateData = response.data.candidate;
            const currStatus = getStatusCtoS(candidate.currentStatus);
            const prevStatus = getStatusCtoS(candidatePrevStatus);

            setAllCandidates((prevCandidates) => {
                const updatedCandidates = { ...prevCandidates };

                // Update the 'all' array
                updatedCandidates.all = prevCandidates.all.map((candidate) =>
                    candidate._id === candidateId ? updatedCandidateData : candidate
                );

                if (currStatus == prevStatus) {
                    // Update the 'current status' array
                    updatedCandidates[currStatus] = prevCandidates[currStatus].map((candidate) =>
                        candidate._id === candidateId ? updatedCandidateData : candidate
                    );
                } else {
                    // Remove from Previous Staus array
                    updatedCandidates[prevStatus] = prevCandidates[prevStatus].filter(
                        (candidate) => candidate._id !== candidateId
                    );

                    // Add to current Status array
                    if (updatedCandidates[currStatus]?.length > 0)
                        updatedCandidates[currStatus] = [
                            updatedCandidateData,
                            ...prevCandidates[currStatus],
                        ];
                }

                return updatedCandidates;
            });

            toast.success("Candidate Updated Successfully!");
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    // Delete Candidate
    const deleteCandidate = async (candidateId, candidatePreviousStatus) => {
        // Prompt the user for confirmation
        const confirmation = window.prompt('Type "delete candidate" to confirm the deletion:');

        if (confirmation !== "delete candidate") {
            toast.error("Deletion cancelled or incorrect phrase entered.");
            return;
        }

        setLoading(true);
        setError(null);

        console.log("Candidtate Id", candidateId);

        try {
            const token = userData?.token;
            const response = await axios.delete(
                `${backendUrl}/candidate?candidateId=${candidateId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log("Delete candidate response", response);
            const status = getStatusCtoS(candidatePreviousStatus);

            setAllCandidates((prevCandidates) => {
                const updatedCandidates = { ...prevCandidates };

                // Update the 'all' array
                updatedCandidates.all = prevCandidates.all.filter((c) => c._id !== candidateId);

                // Update the 'current status' array
                updatedCandidates[status] = prevCandidates[status].filter(
                    (c) => c._id !== candidateId
                );
                return updatedCandidates;
            });

            toast.success("Candidate Deleted Successfully!");
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <CandidateContext.Provider
            value={{
                loading,
                loadingCandidate,
                candidateData,
                setCandidateData,
                fetchSpecificCandidate,
                fetchAllCandidates,
                allCandidates,
                addCandidate,
                updateCandidate,
                deleteCandidate,
                fetchMatchingCandidates,
                allMatchingCandidates,
                hasMoreMatchingCandidates,
                matchingCandidatesPageNo,
                setAllMatchingCandidates,
                hasMoreCandidates,
                candidatesPageNo,
            }}
        >
            {children}
        </CandidateContext.Provider>
    );
};

export default CandidateContext;

export const useCandidate = () => useContext(CandidateContext);
