import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Tabs, TabList, TabPanels, Tab, TabPanel, Text } from "@chakra-ui/react";

import CandidateHeader from "./CandidateHeader";
import CandidateBody from "./CandidateBody";

import { tagColor } from "../../../Utils/GlobalFunctions/TagColor";
import { useCandidate } from "../../../context/CandidateContext";
import { useAuth } from "../../../context/AuthContext";

const CandidateContainer = () => {
    const {
        loading,
        fetchAllCandidates,
        candidateData,
        allCandidates,
        hasMoreCandidates,
        candidatesPageNo,
    } = useCandidate();
    const { userData } = useAuth();

    const [tabIndex, setTabIndex] = useState(0);
    const { status } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        console.log("userData**", userData);
        // if (!userData?.token) return;
        switch (status) {
            case "All":
                fetchAllCandidates("all", 1, 30);
                setTabIndex(0);
                break;
            case "Selected":
                fetchAllCandidates("selected", 1, 30);
                setTabIndex(1);
                break;
            case "Processing":
                fetchAllCandidates("processing", 1, 30);
                setTabIndex(2);
                break;
            case "OnBench":
                fetchAllCandidates("onBench", 1, 30);
                setTabIndex(3);
                break;
            default:
                fetchAllCandidates("all", 1, 30);
                setTabIndex(0);
                break;
        }
    }, [status]);

    const handleTabsChange = (index) => {
        setTabIndex(index);
        const tabMapping = ["All", "Selected", "Processing", "OnBench"];
        navigate(`/recruiter_dashboard/Candidates/${tabMapping[index]}`);
    };

    const [filteredData, setFilteredData] = useState([]);

    const filterData = (query) => {
        console.log("query", query);
        if (!query) {
            setFilteredData(candidateData);
            return;
        }

        const filtered = candidateData.filter((item) => {
            return deepSearch(item, query);
        });

        console.log("filtered", filtered);
        setFilteredData(filtered);
    };

    const deepSearch = (obj, query) => {
        for (const key in obj) {
            const value = obj[key];
            if (typeof value === "object") {
                if (deepSearch(value, query)) {
                    return true;
                }
            } else if (
                typeof value === "string" ||
                typeof value === "number" ||
                typeof value === "boolean"
            ) {
                if (String(value).toLowerCase().includes(query.toLowerCase())) {
                    return true;
                }
            } else if (Array.isArray(value)) {
                for (const item of value) {
                    if (typeof item === "object") {
                        if (deepSearch(item, query)) {
                            return true;
                        }
                    } else if (
                        typeof item === "string" ||
                        typeof item === "number" ||
                        typeof item === "boolean"
                    ) {
                        if (String(item).toLowerCase().includes(query.toLowerCase())) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    };

    const filterByStatus = (status) => {
        return filteredData.filter((item) => item.currentStatus === status);
    };

    useEffect(() => {
        if (candidateData?.length > 0) setFilteredData(candidateData);
        else return;
    }, [candidateData]);
    return (
        <>
            <Tabs isLazy variant="enclosed" index={tabIndex} onChange={handleTabsChange}>
                <CandidateHeader filterData={filterData} />
                <TabList>
                    <Tab>
                        <Text
                            bg={tagColor("").bg}
                            color={tagColor("").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            All
                        </Text>
                    </Tab>
                    <Tab>
                        <Text
                            bg={tagColor("green").bg}
                            color={tagColor("green").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            Selected
                        </Text>
                    </Tab>
                    <Tab>
                        <Text
                            bg={tagColor("blue").bg}
                            color={tagColor("blue").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            Processing
                        </Text>
                    </Tab>
                    <Tab>
                        <Text
                            bg={tagColor("orange").bg}
                            color={tagColor("orange").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            On Bench
                        </Text>
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel p={0} pt={4}>
                        <CandidateBody
                            data={allCandidates.all}
                            fetchAllCandidates={fetchAllCandidates}
                            hasMore={hasMoreCandidates.all}
                            pageNo={candidatesPageNo.all}
                            status={"all"}
                            loading={loading}
                        />
                    </TabPanel>
                    <TabPanel p={0} pt={4}>
                        <CandidateBody
                            data={allCandidates.selected}
                            fetchAllCandidates={fetchAllCandidates}
                            hasMore={hasMoreCandidates.selected}
                            pageNo={candidatesPageNo.selected}
                            status={"selected"}
                            loading={loading}
                        />
                    </TabPanel>
                    <TabPanel p={0} pt={4}>
                        <CandidateBody
                            data={allCandidates.processing}
                            fetchAllCandidates={fetchAllCandidates}
                            hasMore={hasMoreCandidates.processing}
                            pageNo={candidatesPageNo.processing}
                            status={"processing"}
                            loading={loading}
                        />
                    </TabPanel>
                    <TabPanel p={0} pt={4}>
                        <CandidateBody
                            data={allCandidates.onBench}
                            fetchAllCandidates={fetchAllCandidates}
                            hasMore={hasMoreCandidates.onBench}
                            pageNo={candidatesPageNo.onBench}
                            status={"onBench"}
                            loading={loading}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    );
};

export default CandidateContainer;
