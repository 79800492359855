import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Tabs, TabList, TabPanels, Tab, TabPanel, Text, Icon } from "@chakra-ui/react";

import ClientHeader from "./ClientHeader";
import { tagColor } from "../../../Utils/GlobalFunctions/TagColor";
import { useAuth } from "../../../context/AuthContext";
import { TfiDashboard } from "react-icons/tfi";
import { ImStatsBars } from "react-icons/im";
import { useClient } from "../../../context/ClientContext";
import ClientBody from "./ClientBody";

const ClientContainer = () => {
    const { fetchAllClient, loading, allClients, hasMoreClients, clientsPageNo } = useClient();
    const { userData } = useAuth();

    const [tabIndex, setTabIndex] = useState(0);
    const { status } = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const initialPath = pathname.split("/")[1];

    useEffect(() => {
        console.log("userData**", userData);
        // if (!userData?.token) return;
        switch (status) {
            case "All":
                fetchAllClient("all", 1, 30);
                setTabIndex(0);
                break;
            case "Active":
                fetchAllClient("active", 1, 30);
                setTabIndex(1);
                break;
            case "Inactive":
                fetchAllClient("inActive", 1, 30);
                setTabIndex(2);
                break;
            default:
                fetchAllClient("active", 1, 30);
                setTabIndex(1);
                break;
        }
    }, [status]);

    const handleTabsChange = (index) => {
        setTabIndex(index);
        const tabMapping = ["All", "Active", "Inactive"];
        navigate(`/${initialPath}/Clients/${tabMapping[index]}`);
    };

    const jobDescriptions = {};
    const [filteredData, setFilteredData] = useState([]);

    const filterData = (query) => {
        if (!jobDescriptions?.length > 0) return;
        console.log("query", query);
        if (!query) {
            setFilteredData(jobDescriptions);
            return;
        }

        const filtered = jobDescriptions?.filter((item) => {
            return deepSearch(item, query);
        });

        console.log("filtered", filtered);
        setFilteredData(filtered);
    };

    const deepSearch = (obj, query) => {
        for (const key in obj) {
            const value = obj[key];
            if (typeof value === "object") {
                if (deepSearch(value, query)) {
                    return true;
                }
            } else if (
                typeof value === "string" ||
                typeof value === "number" ||
                typeof value === "boolean"
            ) {
                if (String(value).toLowerCase().includes(query.toLowerCase())) {
                    return true;
                }
            } else if (Array.isArray(value)) {
                for (const item of value) {
                    if (typeof item === "object") {
                        if (deepSearch(item, query)) {
                            return true;
                        }
                    } else if (
                        typeof item === "string" ||
                        typeof item === "number" ||
                        typeof item === "boolean"
                    ) {
                        if (String(item).toLowerCase().includes(query.toLowerCase())) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    };

    const filterByStatus = (status) => {
        return filteredData.filter((item) => item.status === status);
    };

    useEffect(() => {
        if (jobDescriptions?.length > 0) setFilteredData(jobDescriptions);
    }, [jobDescriptions]);

    return (
        <>
            <Tabs isLazy variant="enclosed" index={tabIndex} onChange={handleTabsChange}>
                <ClientHeader filterData={filterData} initialPath={initialPath} />

                <TabList>
                    <Tab>
                        <Text
                            bg={tagColor("").bg}
                            color={tagColor("").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            All
                        </Text>
                    </Tab>
                    <Tab>
                        <Text
                            bg={tagColor("blue").bg}
                            color={tagColor("blue").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            Active
                        </Text>
                    </Tab>
                    <Tab>
                        <Text
                            bg={tagColor("orange").bg}
                            color={tagColor("orange").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            Inactive
                        </Text>
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <ClientBody
                            data={allClients.all}
                            fetchAllClient={fetchAllClient}
                            hasMore={hasMoreClients.all}
                            pageNo={clientsPageNo.all}
                            status={"all"}
                            loading={loading}
                        />
                    </TabPanel>
                    <TabPanel>
                        <ClientBody
                            data={allClients.active}
                            fetchAllClient={fetchAllClient}
                            hasMore={hasMoreClients.active}
                            pageNo={clientsPageNo.active}
                            status={"active"}
                            loading={loading}
                        />
                    </TabPanel>
                    <TabPanel>
                        <ClientBody
                            data={allClients.inActive}
                            fetchAllClient={fetchAllClient}
                            hasMore={hasMoreClients.inActive}
                            pageNo={clientsPageNo.inActive}
                            status={"inActive"}
                            loading={loading}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    );
};

export default ClientContainer;
