import { Disclosure, Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import { BellIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import TimeDisplay from "./Time";
import { useAuth } from "../../context/AuthContext";
import logo from "../../assets/logo.png";
import { Avatar, Flex, Text } from "@chakra-ui/react";
import { useUser } from "../../context/UserContext";
import { useState } from "react";
import EmployeeDetails from "../Body/Employees/EmployeeDetails";
import { useEnv } from "../../context/EnvContext";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function NavBar() {
    const navigate = useNavigate();
    const { backendUrl } = useEnv();
    const { logout, userData } = useAuth();
    const { loading, getMyProfile, myProfile } = useUser();
    const [isViewMyProfileModalOpen, setIsViewMyProfileModalOpen] = useState(false);
    console.log("userData", userData);

    // Handle Employee Form modal Preview
    const handleViewMyProfile = () => {
        getMyProfile();
        setIsViewMyProfileModalOpen(true);
    };

    const handleViewMyProfileCloseModal = () => {
        setIsViewMyProfileModalOpen(false);
    };

    return (
        <Disclosure as="nav" className="bg-gray-800">
            {({ open }) => (
                <>
                    <div className="mx-auto w-full px-1 sm:px-5">
                        <div className="relative flex h-12 items-center justify-between">
                            <div
                                className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start"
                                style={{ alignItems: "center" }}
                            >
                                <div className="flex flex-shrink-0 items-center">
                                    <img
                                        className="h-8 w-auto"
                                        src={logo}
                                        alt="ATS"
                                        onClick={() => navigate("/")}
                                        style={{ cursor: "pointer" }}
                                    />
                                </div>
                                <div className="hidden sm:ml-2 sm:block">
                                    <div className="flex space-x-4">
                                        <button
                                            className={classNames(
                                                "bg-gray-900 text-white",
                                                "rounded-md px-2 py-1 text-sm font-medium"
                                            )}
                                            style={{
                                                cursor: "default",
                                            }}
                                        >
                                            <TimeDisplay />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                {/* <button
                                    type="button"
                                    className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white"
                                >
                                    <span className="absolute -inset-1.5" />
                                    <span className="sr-only">View notifications</span>
                                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                                </button> */}

                                <div className="hidden  sm:block">
                                    <Flex direction="column" align={"flex-end"}>
                                        <Text className="text-sm font-medium">
                                            {userData.empId}
                                        </Text>
                                        <Text className="text-xs font-medium">
                                            {userData.userType}
                                            {" - "}
                                            {userData.name}
                                        </Text>
                                    </Flex>
                                </div>
                                {/* Profile dropdown */}
                                <Menu as="div" className="relative ml-3">
                                    <div>
                                        <MenuButton className="relative flex rounded-full bg-gray-800 text-sm  hover:outline-none hover:ring-2 hover:ring-white hover:ring-offset-2 hover:ring-offset-gray-800">
                                            <span className="absolute -inset-1.5" />
                                            <Avatar
                                                size="sm"
                                                name={userData.name}
                                                src={`${backendUrl}${userData.dp}`}
                                            />
                                        </MenuButton>
                                    </div>
                                    <Transition
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <MenuItems className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <Text mb={1} color={"#374151"} fontWeight={"bold"}>
                                                Hi, {userData.empId}
                                            </Text>
                                            <hr
                                                style={{
                                                    borderColor: "#374151",
                                                    borderStyle: "dashed",
                                                    margin: "auto",
                                                    width: "90%",
                                                }}
                                            />
                                            <MenuItem>
                                                {({ focus }) => (
                                                    <a
                                                        href="#"
                                                        className={classNames(
                                                            focus ? "bg-gray-100" : "",
                                                            "block px-4 py-2 text-sm text-gray-700"
                                                        )}
                                                        onClick={() => {
                                                            handleViewMyProfile();
                                                        }}
                                                    >
                                                        Your Profile
                                                    </a>
                                                )}
                                            </MenuItem>
                                            <MenuItem>
                                                {({ focus }) => (
                                                    <a
                                                        href="#"
                                                        className={classNames(
                                                            focus ? "bg-gray-100" : "",
                                                            "block px-4 py-2 text-sm text-gray-700"
                                                        )}
                                                    >
                                                        Settings
                                                    </a>
                                                )}
                                            </MenuItem>
                                            <MenuItem>
                                                {({ focus }) => (
                                                    <a
                                                        href="#"
                                                        onClick={() => logout()}
                                                        className={classNames(
                                                            focus ? "bg-gray-100" : "",
                                                            "block px-4 py-2 text-sm text-gray-700"
                                                        )}
                                                    >
                                                        Sign out
                                                    </a>
                                                )}
                                            </MenuItem>
                                        </MenuItems>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </div>
                    {!loading && myProfile && isViewMyProfileModalOpen && (
                        <EmployeeDetails
                            isOpen={isViewMyProfileModalOpen}
                            onClose={handleViewMyProfileCloseModal}
                            user={myProfile}
                            operation="My Profile"
                        />
                    )}
                </>
            )}
        </Disclosure>
    );
}
