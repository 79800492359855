import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useAuth } from "./AuthContext";
import { useEnv } from "./EnvContext";
import axios from "axios";
import { getStatusCtoS, getStatusStoC } from "../Utils/GlobalFunctions/GetStatus";
import useGlobalToast from "../Utils/GlobalFunctions/toast";

const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
    const toast = useGlobalToast();
    const { userData, updateUserData } = useAuth();
    const { backendUrl } = useEnv();
    const [error, setError] = useState(null);

    const [loading, setLoading] = useState(false);
    const [searchedUsers, setSearchedUsers] = useState([]);

    // handle Employees
    const [allEmployees, setAllEmployees] = useState({
        all: [],
        activeBDs: [],
        activeRecruiters: [],
        blocked: [],
        deleted: [],
    });
    const [hasMoreEmployees, setHasMoreEmployees] = useState({
        all: true,
        activeBDs: true,
        activeRecruiters: true,
        blocked: true,
        deleted: true,
    });
    const [employeesPageNo, setEmployeesPageNo] = useState({
        all: 1,
        activeBDs: 1,
        activeRecruiters: 1,
        blocked: 1,
        deleted: 1,
    });

    const [myProfile, setMyProfile] = useState(null);

    const memoizedEmployees = useMemo(() => allEmployees, [allEmployees]);
    const memoizedProfile = useMemo(() => myProfile, myProfile);

    const fetchSearchedUsers = async (query, userType) => {
        setLoading(true);
        setError(null);
        if (!query) {
            setSearchedUsers([]);
            return;
        }
        try {
            const token = userData?.token;
            const response = await axios.get(`${backendUrl}/user/search`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    search: query,
                    userType,
                },
            });

            const searchRes = response.data;
            console.log("searchRes", searchRes);
            setSearchedUsers(searchRes.users);

            toast.success(searchRes.message);
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchAllUsers = async (status, page = 1, limit = 30, updateData = false) => {
        setLoading(true);
        try {
            if (!updateData && memoizedEmployees[status].length > 0) {
                setLoading(false);
                return;
            }

            let requestStatus = getStatusStoC(status);

            const token = userData?.token;
            const response = await axios.get(`${backendUrl}/user/all`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    status: requestStatus,
                    page,
                    limit,
                },
            });

            const fetchedEmployees = response.data;
            console.log("fetchedEmployees", fetchedEmployees);

            if (updateData) {
                setAllEmployees((prevState) => ({
                    ...prevState,
                    [status]: [...prevState[status], ...fetchedEmployees],
                }));

                setEmployeesPageNo((prevState) => ({
                    ...prevState,
                    [status]: prevState[status] + 1,
                }));

                if (fetchedEmployees.length < limit) {
                    setHasMoreEmployees((prevState) => ({
                        ...prevState,
                        [status]: false,
                    }));
                }
            } else {
                setAllEmployees((prevState) => ({
                    ...prevState,
                    [status]: fetchedEmployees,
                }));

                setEmployeesPageNo((prevState) => ({
                    ...prevState,
                    [status]: 2,
                }));

                setHasMoreEmployees((prevState) => ({
                    ...prevState,
                    [status]: fetchedEmployees.length === limit,
                }));
            }

            toast.success(`${status} Employees fetched successfully!`);
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    // Add User
    const addUser = async (newUserData) => {
        setLoading(true);
        setError(null);

        try {
            const token = userData?.token;
            const response = await axios.post(`${backendUrl}/user/add_user`, newUserData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const addUserRes = response.data;
            console.log("addUserRes", addUserRes);
            const addedUser = addUserRes.user;

            let status,
                flag = -1;
            switch (addedUser.userType) {
                case "Business Developer":
                    status = "activeBDs";
                    flag = 1;
                    break;
                case "Recruiter":
                    status = "activeRecruiters";
                    flag = 1;
                    break;
                default:
                    break;
            }

            setAllEmployees((prevUsers) => {
                const updatedUsers = { ...prevUsers };

                // Update the 'all' array
                if (updatedUsers?.all?.length > 0) updatedUsers.all = [addedUser, ...prevUsers.all];

                // Update the [status] array
                if (flag == 1 && updatedUsers[status]?.length > 0)
                    updatedUsers[status] = [addedUser, ...prevUsers[status]];

                return updatedUsers;
            });

            toast.success("New Employee Added Successfully!");
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    // Update User
    const updateUser = async (updatedUser, userId, tab, prevStatus) => {
        setLoading(true);
        setError(null);
        try {
            const token = userData?.token;
            const response = await axios.put(
                `${backendUrl}/user/update_user?userId=${userId}`,
                updatedUser,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const updateUserRes = response.data;
            console.log("updateUserRes", updateUserRes);
            const updatedUserData = updateUserRes.user;

            let currStatus,
                updateCurrFlag = -1,
                updatePrevFlag = -1;

            switch (updatedUserData.userType) {
                case "Business Developer":
                    currStatus = "activeBDs";
                    updateCurrFlag = 1;
                    break;
                case "Recruiter":
                    currStatus = "activeRecruiters";
                    updateCurrFlag = 1;
                    break;
                default:
                    break;
            }

            switch (prevStatus) {
                case "Business Developer":
                    prevStatus = "activeBDs";
                    updatePrevFlag = 1;
                    break;
                case "Recruiter":
                    prevStatus = "activeRecruiters";
                    updatePrevFlag = 1;
                    break;
                default:
                    break;
            }

            setAllEmployees((prevUsers) => {
                const updatedUsers = { ...prevUsers };

                // Update the 'all' array
                updatedUsers.all = prevUsers.all.map((user) =>
                    user._id === userId ? updatedUserData : user
                );

                if (tab == "all") {
                    if (updateCurrFlag == 1) {
                        if (currStatus == prevStatus) {
                            // update previous status
                            updatedUsers[prevStatus] = prevUsers[prevStatus].map((user) =>
                                user._id === userId ? updatedUserData : user
                            );
                        } else {
                            if (updatePrevFlag == 1) {
                                // remove it from prev status array
                                updatedUsers[prevStatus] = prevUsers[prevStatus].filter(
                                    (user) => user._id !== userId
                                );
                            }
                            // add it to curr status array
                            if (updatedUsers[currStatus]?.length > 0)
                                updatedUsers[currStatus] = [
                                    updatedUserData,
                                    ...prevUsers[currStatus],
                                ];
                        }
                    } else {
                        if (updatePrevFlag == 1) {
                            // remove it from prev status array
                            updatedUsers[prevStatus] = prevUsers[prevStatus].filter(
                                (user) => user._id !== userId
                            );
                        }
                    }
                } else {
                    if (updateCurrFlag == 1) {
                        if (currStatus == prevStatus) {
                            // update previous status
                            updatedUsers[prevStatus] = prevUsers[prevStatus].map((user) =>
                                user._id === userId ? updatedUserData : user
                            );
                        } else {
                            // remove it from prev status array
                            updatedUsers[prevStatus] = prevUsers[prevStatus].filter(
                                (user) => user._id !== userId
                            );

                            // add it to curr status array
                            if (updatedUsers[currStatus]?.length > 0)
                                updatedUsers[currStatus] = [
                                    updatedUserData,
                                    ...prevUsers[currStatus],
                                ];
                        }
                    } else {
                        // remove it from prev status array
                        updatedUsers[prevStatus] = prevUsers[prevStatus].filter(
                            (user) => user._id !== userId
                        );
                    }
                }

                return updatedUsers;
            });

            toast.success("User Updated Successfully!");
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    // Block User
    const blockUser = async (userId, empId, tab) => {
        // Prompt the user for confirmation
        const confirmation = window.prompt(
            `Type "block employee ${empId}" to confirm the blocking:`
        );

        if (confirmation !== `block employee ${empId}`) {
            toast.error("blocking cancelled or incorrect phrase entered.");
            return;
        }

        setLoading(true);
        setError(null);
        try {
            const token = userData?.token;
            const response = await axios.put(
                `${backendUrl}/user/block?userId=${userId}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const blockUserRes = response.data;
            console.log("blockUserRes", blockUserRes);
            const blockedUserData = blockUserRes.user;

            let currStatus,
                updateCurrFlag = -1;

            switch (blockedUserData.userType) {
                case "Business Developer":
                    currStatus = "activeBDs";
                    updateCurrFlag = 1;
                    break;
                case "Recruiter":
                    currStatus = "activeRecruiters";
                    updateCurrFlag = 1;
                    break;
                default:
                    break;
            }

            setAllEmployees((prevUsers) => {
                const updatedUsers = { ...prevUsers };

                // Update the 'all' array
                updatedUsers.all = prevUsers.all.map((user) =>
                    user._id === userId ? blockedUserData : user
                );

                if (tab == "all") {
                    if (updateCurrFlag == 1) {
                        // remove from current status
                        updatedUsers[currStatus] = prevUsers[currStatus].filter(
                            (user) => user._id !== userId
                        );

                        // add it to blocked status
                        if (updatedUsers.blocked?.length > 0)
                            updatedUsers.blocked = [blockedUserData, ...prevUsers.blocked];
                    } else {
                        // update delete status array
                        updatedUsers.deleted = prevUsers.deleted.map((user) =>
                            user._id === userId ? blockedUserData : user
                        );
                    }
                } else if (tab == "activeBDs" || tab == "activeRecruiters") {
                    // remove them current status
                    updatedUsers[currStatus] = prevUsers[currStatus].filter(
                        (user) => user._id !== userId
                    );

                    // add them to blocked status
                    if (updatedUsers.blocked?.length > 0)
                        updatedUsers.blocked = [blockedUserData, ...prevUsers.blocked];
                } else {
                    // update delete status array
                    updatedUsers.deleted = prevUsers.deleted.map((user) =>
                        user._id === userId ? blockedUserData : user
                    );
                    // add them to blocked status
                    if (updatedUsers.blocked?.length > 0)
                        updatedUsers.blocked = [blockedUserData, ...prevUsers.blocked];
                }

                return updatedUsers;
            });

            toast.success("Employee Blocked Successfully");
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    // Unblock User
    const unblockUser = async (userId, empId, tab) => {
        // Prompt the user for confirmation
        const confirmation = window.prompt(
            `Type "unblock employee ${empId}" to confirm the unblocking:`
        );

        if (confirmation !== `unblock employee ${empId}`) {
            toast.error("unblocking cancelled or incorrect phrase entered.");
            return;
        }

        setLoading(true);
        setError(null);
        try {
            const token = userData?.token;
            const response = await axios.put(
                `${backendUrl}/user/unblock?userId=${userId}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const unblockUserRes = response.data;
            console.log("unblockUserRes", unblockUserRes);
            const unblockedUserData = unblockUserRes.user;

            let currStatus,
                updateCurrFlag = -1;

            switch (unblockedUserData.userType) {
                case "Business Developer":
                    currStatus = "activeBDs";
                    updateCurrFlag = 1;
                    break;
                case "Recruiter":
                    currStatus = "activeRecruiters";
                    updateCurrFlag = 1;
                    break;
                default:
                    break;
            }

            setAllEmployees((prevUsers) => {
                const updatedUsers = { ...prevUsers };

                // Update the 'all' array
                updatedUsers.all = prevUsers.all.map((user) =>
                    user._id === userId ? unblockedUserData : user
                );

                if (!!unblockedUserData.isDeleted) {
                    // update deleted array
                    updatedUsers.deleted = prevUsers.deleted.map((user) =>
                        user._id === userId ? unblockedUserData : user
                    );
                } else if (updateCurrFlag == 1) {
                    // add it to curr status
                    if (updatedUsers[currStatus]?.length > 0)
                        updatedUsers[currStatus] = [unblockedUserData, ...prevUsers[currStatus]];
                }

                // removeit from blocked array
                updatedUsers.blocked = prevUsers.blocked.filter((user) => user._id !== userId);

                return updatedUsers;
            });

            toast.success("Employee Unblocked Successfully");
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    // Delete User
    const deleteUser = async (userId, empId, tab) => {
        // Prompt the user for confirmation
        const confirmation = window.prompt(
            `Type "delete employee ${empId}" to confirm the deleting:`
        );

        if (confirmation !== `delete employee ${empId}`) {
            toast.error("deleting cancelled or incorrect phrase entered.");
            return;
        }

        setLoading(true);
        setError(null);
        try {
            const token = userData?.token;
            const response = await axios.delete(`${backendUrl}/user?userId=${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const deleteUserRes = response.data;
            console.log("deleteUserRes", deleteUserRes);
            const deletedUserData = deleteUserRes.user;

            let currStatus,
                updateCurrFlag = -1;

            switch (deletedUserData.userType) {
                case "Business Developer":
                    currStatus = "activeBDs";
                    updateCurrFlag = 1;
                    break;
                case "Recruiter":
                    currStatus = "activeRecruiters";
                    updateCurrFlag = 1;
                    break;
                default:
                    break;
            }

            setAllEmployees((prevUsers) => {
                const updatedUsers = { ...prevUsers };

                // Update the 'all' array
                updatedUsers.all = prevUsers.all.map((user) =>
                    user._id === userId ? deletedUserData : user
                );

                if (tab == "all") {
                    if (updateCurrFlag == 1) {
                        // remove from current status
                        updatedUsers[currStatus] = prevUsers[currStatus].filter(
                            (user) => user._id !== userId
                        );

                        // add it to deleted status
                        if (updatedUsers.deleted?.length > 0)
                            updatedUsers.deleted = [deletedUserData, ...prevUsers.deleted];
                    } else {
                        // update blocked status array
                        updatedUsers.blocked = prevUsers.blocked.map((user) =>
                            user._id === userId ? deletedUserData : user
                        );
                    }
                } else if (tab == "activeBDs" || tab == "activeRecruiters") {
                    // remove them current status
                    updatedUsers[currStatus] = prevUsers[currStatus].filter(
                        (user) => user._id !== userId
                    );

                    // add them to deleted status
                    if (updatedUsers.deleted?.length > 0)
                        updatedUsers.deleted = [deletedUserData, ...prevUsers.deleted];
                } else {
                    // update blocked status array
                    updatedUsers.blocked = prevUsers.blocked.map((user) =>
                        user._id === userId ? deletedUserData : user
                    );
                    // add them to deleted status
                    if (updatedUsers.deleted?.length > 0)
                        updatedUsers.deleted = [deletedUserData, ...prevUsers.deleted];
                }

                return updatedUsers;
            });

            toast.success("Employee Deleted Successfully");
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    // retrieve User
    const retrieveUser = async (userId, empId, tab) => {
        // Prompt the user for confirmation
        const confirmation = window.prompt(
            `Type "retrieve employee ${empId}" to confirm the retrieve:`
        );

        if (confirmation !== `retrieve employee ${empId}`) {
            toast.error("retrieve cancelled or incorrect phrase entered.");
            return;
        }

        setLoading(true);
        setError(null);
        try {
            const token = userData?.token;
            const response = await axios.put(
                `${backendUrl}/user/revive?userId=${userId}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const retrieveUserRes = response.data;
            console.log("retrieveUserRes", retrieveUserRes);
            const retrievedUserData = retrieveUserRes.user;

            let currStatus,
                updateCurrFlag = -1;

            switch (retrievedUserData.userType) {
                case "Business Developer":
                    currStatus = "activeBDs";
                    updateCurrFlag = 1;
                    break;
                case "Recruiter":
                    currStatus = "activeRecruiters";
                    updateCurrFlag = 1;
                    break;
                default:
                    break;
            }

            setAllEmployees((prevUsers) => {
                const updatedUsers = { ...prevUsers };

                // Update the 'all' array
                updatedUsers.all = prevUsers.all.map((user) =>
                    user._id === userId ? retrievedUserData : user
                );

                if (!!retrievedUserData.isBlocked) {
                    // update blocked array
                    updatedUsers.blocked = prevUsers.blocked.map((user) =>
                        user._id === userId ? retrievedUserData : user
                    );
                } else if (updateCurrFlag == 1) {
                    // add it to curr status
                    if (updatedUsers[currStatus]?.length > 0)
                        updatedUsers[currStatus] = [retrievedUserData, ...prevUsers[currStatus]];
                }

                // removeit from deleted array
                updatedUsers.deleted = prevUsers.deleted.filter((user) => user._id !== userId);

                return updatedUsers;
            });

            toast.success("Employee Retrieved Successfully");
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    const getMyProfile = async () => {
        setLoading(true);
        try {
            if (memoizedProfile) {
                setLoading(false);
                return;
            }

            const token = userData?.token;
            const response = await axios.get(`${backendUrl}/user/my_profile`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const myProfileRes = response.data;
            console.log("myProfileRes", myProfileRes);

            setMyProfile(myProfileRes.user);

            // toast.success(`My Profile Data fetched successfully!`);
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    // Update Profile
    const updateProfile = async (updatedData) => {
        setLoading(true);
        setError(null);
        const UpdatedProfile = new FormData();

        Object.keys(updatedData).forEach((key) => {
            UpdatedProfile.append(key, updatedData[key]);
        });

        try {
            const token = userData?.token;
            const response = await axios.put(`${backendUrl}/user/update_profile`, UpdatedProfile, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log("Update Profile response", response);

            const updatedProfileData = response.data.user;

            setMyProfile(updatedProfileData);
            updateUserData(true, updatedProfileData);

            toast.success("Profile Updated Successfully!");
        } catch (err) {
            console.log("Error", err);
            let error = err.response
                ? err.response?.data?.message
                : err.message
                ? err.message
                : "Something Went Wrong";
            setError(error);
            toast.error("Error", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <UserContext.Provider
            value={{
                fetchSearchedUsers,
                searchedUsers,
                loading,
                fetchAllUsers,
                allEmployees,
                hasMoreEmployees,
                employeesPageNo,
                addUser,
                updateUser,
                blockUser,
                deleteUser,
                unblockUser,
                retrieveUser,
                getMyProfile,
                myProfile,
                updateProfile,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;

export const useUser = () => useContext(UserContext);
