import React, { useState, useEffect } from "react";

import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Button,
    Badge,
    Text,
    Box,
    Tooltip,
    Flex,
    Avatar,
    Skeleton,
} from "@chakra-ui/react";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import { IoMdFemale, IoMdMale } from "react-icons/io";

import { GrMore } from "react-icons/gr";
import { FaSort } from "react-icons/fa";
import { IoMaleFemale } from "react-icons/io5";
import { MdMenu } from "react-icons/md";

import { useCandidate } from "../../../context/CandidateContext";
import { useJD } from "../../../context/JDContext";
import { useApplication } from "../../../context/ApplicationContext";
import { useEnv } from "../../../context/EnvContext";

import InfiniteScroll from "react-infinite-scroll-component";

import { formatDateTimeDay } from "../../../Utils/GlobalFunctions/FormatDate";
import getGenderIcon from "../../../Utils/GlobalFunctions/GenderIcon";
import CandidateDetails from "../Candidate/CandidateDetails";
import JdDetails from "../JobDescription/JdDetails";
import ScheduleInterview from "./ScheduleInterview";
import RecordIntvRes from "./RecordIntvRes";
import RecordOffer from "../OfferProcess/RecordOffer";
import ApplicationDetails from "../Application/ApplicationDetails";

const PrintLastIntvDetails = ({
    interview,
    application,
    status,
    handleSkdIntModalPreview,
    handleRecIntvResModalPreview,
    totalPassedRounds,
    totalRoundNo,
    handleOfferCandModalPreview,
    setIsUpdatingResult,
    setSelectedApplication,
    handleApplicationPreview,
    setSubTab,
}) => {
    return (
        <>
            <Td style={{ cursor: "pointer" }}>
                <Tooltip
                    hasArrow
                    label={
                        <Text fontSize={"xs"} color={"gray"}>
                            Click to view Application in Details
                        </Text>
                    }
                    bg="gray.300"
                    color="black"
                    placement="top"
                >
                    <Flex
                        direction={"column"}
                        onClick={() => {
                            setSelectedApplication(application);
                            setSubTab(interview?.roundNo - 1);
                            handleApplicationPreview(application);
                        }}
                    >
                        <Text>{interview?.interviewId}</Text>
                        <Text fontSize={"sm"} color={"#a3a3a3"}>
                            {application?.applicationID}
                        </Text>
                    </Flex>
                </Tooltip>
            </Td>
            <Td style={{ textAlign: "center" }}>
                <b>{interview?.roundNo}</b>
            </Td>
            <Td>
                {formatDateTimeDay(interview?.scheduledOn)}{" "}
                <Text
                    fontSize={"sm"}
                    color={"#a3a3a3"}
                    style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        maxWidth: "200px",
                    }}
                >
                    {formatDateTimeDay(interview?.schedulingDate)}
                </Text>
            </Td>
            <Td>
                <Tooltip
                    hasArrow
                    label={
                        interview?.remarks && (
                            <>
                                Remark:
                                <Box whiteSpace="pre-line">{interview?.remarks}</Box>
                            </>
                        )
                    }
                    bg="gray.300"
                    color="black"
                    placement="top"
                >
                    <Flex align={"center"} justify={"center"} w={"100%"} direction={"column"}>
                        {interview?.result == "Pending" && (
                            <Badge variant="subtle" colorScheme="blue">
                                Pending
                            </Badge>
                        )}
                        {interview?.result == "Passed" && (
                            <Badge variant="subtle" colorScheme="green">
                                Passed
                            </Badge>
                        )}
                        {interview?.result == "Failed" && (
                            <Badge variant="subtle" colorScheme="red">
                                Failed
                            </Badge>
                        )}
                        <Text
                            fontSize={"sm"}
                            color={"#a3a3a3"}
                            style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                maxWidth: "200px",
                            }}
                        >
                            {formatDateTimeDay(interview?.resultDate)}
                        </Text>
                    </Flex>
                </Tooltip>
            </Td>
            {interview?.result == "Pending" && (
                <Td>
                    <Flex justify={"space-between"} w={"100%"} gap={1}>
                        <Button
                            colorScheme="blue"
                            p={1}
                            size={"xs"}
                            onClick={() => handleRecIntvResModalPreview(application, interview)}
                        >
                            Record Result
                        </Button>
                        <Button
                            colorScheme="yellow"
                            p={1}
                            size={"xs"}
                            onClick={() => handleSkdIntModalPreview(application, true, interview)}
                        >
                            Reschedule
                        </Button>
                    </Flex>
                </Td>
            )}
            {interview?.result == "Passed" && (
                <Td>
                    <Flex justify={"space-between"} w={"100%"} gap={1} align={"center"}>
                        {totalPassedRounds == totalRoundNo ? (
                            application?.offerProcess == "Not Started" ? (
                                <Button
                                    colorScheme="purple"
                                    p={1}
                                    size={"xs"}
                                    onClick={() => handleOfferCandModalPreview(application)}
                                >
                                    Offer Candidate
                                </Button>
                            ) : (
                                <Text fontSize={"sm"} color={"#a3a3a3"}>
                                    Offered
                                </Text>
                            )
                        ) : (
                            <Button
                                colorScheme="green"
                                p={1}
                                size={"xs"}
                                onClick={() =>
                                    handleSkdIntModalPreview(
                                        application,
                                        false,
                                        application?.jobDescription?.interviewRounds?.find(
                                            (intv) => intv?.roundNo == interview?.roundNo + 1
                                        )
                                    )
                                }
                            >
                                Skd. Next Rn.
                            </Button>
                        )}
                        <Button
                            colorScheme="orange"
                            p={1}
                            size={"xs"}
                            onClick={() => handleRecIntvResModalPreview(application, interview)}
                        >
                            Upd. CR Res.
                        </Button>
                    </Flex>
                </Td>
            )}
            {interview?.result == "Failed" && (
                <Td>
                    <Flex justify={"space-between"} w={"100%"} gap={1}>
                        <Button
                            colorScheme="orange"
                            p={1}
                            size={"xs"}
                            onClick={() => {
                                setIsUpdatingResult(true);
                                handleRecIntvResModalPreview(application, interview);
                            }}
                        >
                            Upd. CR Res.
                        </Button>
                    </Flex>
                </Td>
            )}

            <Td>
                <Menu>
                    <MenuButton>
                        <GrMore />
                    </MenuButton>
                    <MenuList minW={"50px"}>
                        <MenuItem
                            onClick={() => {
                                setSelectedApplication(application);
                                setSubTab(interview?.roundNo - 1);
                                handleApplicationPreview(application);
                            }}
                        >
                            View Application
                        </MenuItem>
                        {interview?.result == "Pending" ? (
                            <>
                                <MenuItem
                                    onClick={() =>
                                        handleSkdIntModalPreview(application, true, interview)
                                    }
                                    color={"yellow"}
                                >
                                    Reschedule Current Round
                                </MenuItem>
                                <MenuItem
                                    onClick={() =>
                                        handleRecIntvResModalPreview(application, interview)
                                    }
                                    color={"#61bcff"}
                                >
                                    Record Current Round Result
                                </MenuItem>
                            </>
                        ) : (
                            <MenuItem
                                onClick={() => {
                                    setIsUpdatingResult(true);
                                    handleRecIntvResModalPreview(application, interview);
                                }}
                                color={"orange"}
                            >
                                Update Current Round Result
                            </MenuItem>
                        )}
                        {interview?.result === "Passed" &&
                            (totalPassedRounds === totalRoundNo ? (
                                application?.offerProcess == "Not Started" && (
                                    <MenuItem
                                        onClick={() => handleOfferCandModalPreview(application)}
                                        color={"#d6bcfa"}
                                    >
                                        Offer Candidate
                                    </MenuItem>
                                )
                            ) : (
                                <MenuItem
                                    onClick={() =>
                                        handleSkdIntModalPreview(
                                            application,
                                            false,
                                            application?.jobDescription?.interviewRounds?.find(
                                                (intv) => intv?.roundNo === interview?.roundNo + 1
                                            )
                                        )
                                    }
                                    color={"#00c800"}
                                >
                                    Schedule Next Round
                                </MenuItem>
                            ))}
                    </MenuList>
                </Menu>
            </Td>
        </>
    );
};

const InterviewProcessBody = ({ data, fetchAllApplications, hasMore, pageNo, status }) => {
    console.log("data", data);
    console.log("hasMore", hasMore);
    console.log("pageNo", pageNo);
    console.log("status", status);

    const { backendUrl } = useEnv();
    const { candidateData, setCandidateData, fetchSpecificCandidate, loadingCandidate } =
        useCandidate();
    const { jobDescriptionData, setJobDescriptionData, fetchJDById, loadingJD } = useJD();
    const {
        forwardCV,
        rejectApplication,
        revertRejection,
        recordCvRes,
        scheduleInterview,
        recordInterviewRes,
        recordOffer,
    } = useApplication();

    // handle view modals
    const [selectedApplication, setSelectedApplication] = useState(null);
    const [subTab, setSubTab] = useState(0);
    const [isCandidateDetailsModalOpen, setIsCandidateDetailsModalOpen] = useState(false);
    const [isJDDetailsModalOpen, setIsJDDetailsModalOpen] = useState(false);
    const [isApplicationDetailsModalOpen, setIsApplicationDetailsModalOpen] = useState(false);
    const [isRecordResultModalOpen, setIsRecordResultModalOpen] = useState(false);
    const [isSkdIntModalOpen, setIsSkdIntModalOpen] = useState(false);
    const [isRecIntvResModalOpen, setIsRecIntvResModalOpen] = useState(false);
    const [isRescheduling, setIsRescheduling] = useState(false);
    const [isOfferCandModalOpen, setIsOfferCandModalOpen] = useState(false);
    const [isUpdatingResult, setIsUpdatingResult] = useState(false);
    const [selectedRoundDetails, setSelectedRoundDetails] = useState(null);

    const [sortedData, setSortedData] = useState(data);
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: null,
    });

    // Fetch more data
    const fetchMoreData = () => {
        console.log("fetching more data.......");
        fetchAllApplications("interviewProcess", status, pageNo, 30, true);
    };

    // Sort the data
    useEffect(() => {
        let sortedArray = [...data];
        if (sortConfig.key) {
            sortedArray.sort((a, b) => {
                let aValue = a[sortConfig.key];
                let bValue = b[sortConfig.key];

                // Handle nested keys for jobDescription
                if (sortConfig.key === "jobDescription.jobTitle") {
                    aValue = a.jobDescription.jobTitle;
                    bValue = b.jobDescription.jobTitle;
                }

                // Handle nested keys for candidate
                if (sortConfig.key === "candidate.name") {
                    aValue = a.candidate.name;
                    bValue = b.candidate.name;
                }

                // Handle nested keys for cv forwardingDate
                if (sortConfig.key === "cvSelectionDetails.forwardingDate") {
                    aValue = a.cvSelectionDetails.forwardingDate;
                    bValue = b.cvSelectionDetails.forwardingDate;
                }

                // Handle nested keys for cv resultDate
                if (sortConfig.key === "cvSelectionDetails.resultDate") {
                    aValue = a.cvSelectionDetails.resultDate;
                    bValue = b.cvSelectionDetails.resultDate;
                }

                // Handle nested keys for result
                if (sortConfig.key === "cvSelectionDetails.result") {
                    aValue = a.cvSelectionDetails.result;
                    bValue = b.cvSelectionDetails.result;
                }
                if (aValue < bValue) {
                    return sortConfig.direction === "ascending" ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === "ascending" ? 1 : -1;
                }
                return 0;
            });
        }
        setSortedData(sortedArray);
    }, [sortConfig, data]);

    const requestSort = (key) => {
        let direction = "ascending";
        if (sortConfig.key === key && sortConfig.direction === "ascending") {
            direction = "descending";
        } else if (sortConfig.key === key && sortConfig.direction === "descending") {
            setSortConfig({ key: null, direction: null });
            return;
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            if (sortConfig.direction === "ascending") {
                return <TiArrowSortedUp cursor={"pointer"} />;
            } else if (sortConfig.direction === "descending") {
                return <TiArrowSortedDown cursor={"pointer"} />;
            }
        }
        return <FaSort cursor={"pointer"} />;
    };

    // Handle Candidate Details modal Preview
    const handleCandidatePreview = (candidate) => {
        // fetch the candidate
        fetchSpecificCandidate(candidate?._id);
        setIsCandidateDetailsModalOpen(true);
    };

    const handleCandidateViewCloseModal = () => {
        setIsCandidateDetailsModalOpen(false);
        setCandidateData(null);
    };

    // Handle JD Details modal Preview
    const handleJDPreview = (jd) => {
        // fetch the jd
        fetchJDById(jd?._id);
        setIsJDDetailsModalOpen(true);
    };

    const handleJDViewCloseModal = () => {
        setIsJDDetailsModalOpen(false);
        setJobDescriptionData(null);
    };

    // Handle Application Details modal Preview
    const handleApplicationPreview = () => {
        setIsApplicationDetailsModalOpen(true);
    };
    const handleApplicationViewCloseModal = () => {
        setIsApplicationDetailsModalOpen(false);
        setSelectedApplication(null);
    };

    // Handle Record CV Response Result modal Preview
    const handleCvResModalPreview = (application) => {
        setSelectedApplication(application);
        setIsRecordResultModalOpen(true);
    };
    const handleCvResCloseModal = () => {
        setIsRecordResultModalOpen(false);
        setSelectedApplication(null);
    };

    // Handle Offer Candidate modal Preview
    const handleOfferCandModalPreview = (application) => {
        setSelectedApplication(application);
        setIsOfferCandModalOpen(true);
    };
    const handleOfferCandCloseModal = () => {
        setIsOfferCandModalOpen(false);
        setSelectedApplication(null);
    };

    // Handle Schedule interview modal Preview
    const handleSkdIntModalPreview = (application, isRescheduling, roundDetails) => {
        setSelectedApplication(application);
        setIsSkdIntModalOpen(true);
        setIsRescheduling(isRescheduling);
        setSelectedRoundDetails(roundDetails);
    };
    const handleSkdIntCloseModal = () => {
        setIsSkdIntModalOpen(false);
        setIsRescheduling(false);
        setSelectedApplication(null);
        setSelectedRoundDetails(null);
    };

    // Handle Record Interview Result modal Preview
    const handleRecIntvResModalPreview = (application, roundDetails) => {
        setSelectedApplication(application);
        setIsRecIntvResModalOpen(true);
        setSelectedRoundDetails(roundDetails);
    };
    const handleRecIntvResCloseModal = () => {
        setIsRecIntvResModalOpen(false);
        setSelectedApplication(null);
        setSelectedRoundDetails(null);
        setIsUpdatingResult(false);
    };

    // Get Total Round No
    const getTotalRoundNo = (jd) => {
        return jd?.interviewRounds?.length;
    };

    // Get Total Passed Rounds
    const getTotalPassedRounds = (interviews) => {
        return interviews?.filter((intv) => {
            return intv?.result == "Passed";
        })?.length;
    };

    return (
        <div
            style={{
                height: "75vh",
                overflow: "auto",
                position: "relative",
            }}
        >
            <InfiniteScroll
                dataLength={sortedData?.length}
                next={fetchMoreData}
                hasMore={hasMore}
                height={"100%"}
                loader={
                    <>
                        <Skeleton height="30px" m={2} />
                        <Skeleton height="30px" m={2} />
                        <Skeleton height="30px" m={2} />
                    </>
                }
                endMessage={
                    <p style={{ textAlign: "center", marginTop: "5px" }}>
                        <b>End of the List</b>
                    </p>
                }
            >
                <TableContainer
                    style={{ maxHeight: "100%" }}
                    overflowY={sortedData?.length < 5 ? "auto" : "clip"}
                    overflowX={sortedData?.length < 5 ? "clip" : "clip"}
                >
                    <Table size="sm">
                        <Thead
                            style={{
                                position: "sticky",
                                top: 0,
                                backgroundColor: "#05080D",
                                zIndex: 1,
                            }}
                        >
                            <Tr>
                                <Th style={{ color: "white" }}>Sl</Th>
                                <Th
                                    style={{
                                        color: "white",
                                    }}
                                    onClick={() => requestSort("jobDescription?.jobTitle")}
                                >
                                    {" "}
                                    <Flex
                                        alignItems={"center"}
                                        width={"100%"}
                                        justify={"space-between"}
                                    >
                                        <Tooltip
                                            hasArrow
                                            label={
                                                <Box
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <Text fontSize={"sm"} textAlign={"center"}>
                                                        Job Id, Job Title
                                                    </Text>
                                                    <Text
                                                        fontSize={"xs"}
                                                        color={"gray"}
                                                        textAlign={"center"}
                                                    >
                                                        Click on job Id to view the JD details
                                                    </Text>
                                                </Box>
                                            }
                                            bg="gray.300"
                                            color="black"
                                            placement="top"
                                        >
                                            Job Description
                                        </Tooltip>
                                        {getSortIcon("jobDescription?.jobTitle")}
                                    </Flex>
                                </Th>
                                <Th
                                    style={{
                                        color: "white",
                                    }}
                                    onClick={() => requestSort("candidate?.name")}
                                >
                                    {" "}
                                    <Flex
                                        alignItems={"center"}
                                        width={"100%"}
                                        justify={"space-between"}
                                    >
                                        <Tooltip
                                            hasArrow
                                            label={
                                                <>
                                                    <Flex align={"center"}>
                                                        <Avatar
                                                            src=""
                                                            size="sm"
                                                            name="Display Picture"
                                                            mr={2}
                                                            background={"green"}
                                                            color={"white"}
                                                        />
                                                        <Box
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                            }}
                                                        >
                                                            <Text
                                                                fontSize={"sm"}
                                                                style={{
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                }}
                                                            >
                                                                <b>Candidate Id</b>
                                                                {" - "}
                                                                Name, gender
                                                            </Text>
                                                            <Text
                                                                fontSize={"sm"}
                                                                color={"#2b2a2a"}
                                                                style={{
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                    maxWidth: "180px",
                                                                }}
                                                            >
                                                                Candidate Email
                                                            </Text>
                                                        </Box>
                                                    </Flex>
                                                    <Flex
                                                        direction={"column"}
                                                        fontSize={"xs"}
                                                        color={"gray"}
                                                        mt={2}
                                                    >
                                                        <Text color={"black"}>
                                                            Gender Details:{" "}
                                                        </Text>
                                                        <Flex
                                                            align={"center"}
                                                            gap={1}
                                                            background={"black"}
                                                            p={1}
                                                            px={2}
                                                            color={"white"}
                                                        >
                                                            <IoMdMale color="#2D9AFE" />
                                                            <Text>Male, </Text>
                                                            <IoMdFemale color="#F86CFF" />
                                                            <Text>Female, </Text>
                                                            <IoMaleFemale color="#ffd600" />
                                                            <Text>Other</Text>
                                                        </Flex>
                                                    </Flex>
                                                    <Text
                                                        fontSize={"xs"}
                                                        color={"gray"}
                                                        textAlign={"center"}
                                                    >
                                                        Click on the DP to view the candidate
                                                        details <br />
                                                        Click on mail id to mail the candidate.
                                                    </Text>
                                                </>
                                            }
                                            bg="gray.300"
                                            color="black"
                                            placement="top"
                                        >
                                            Candidate
                                        </Tooltip>
                                        {getSortIcon("candidate?.name")}
                                    </Flex>
                                </Th>
                                <Th style={{ color: "white" }}>
                                    <Tooltip
                                        hasArrow
                                        label="No. of Round Passed"
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                    >
                                        Pas
                                    </Tooltip>
                                </Th>
                                <Th style={{ color: "white" }}>
                                    <Flex
                                        alignItems={"center"}
                                        width={"100%"}
                                        justify={"space-between"}
                                    >
                                        <Tooltip
                                            hasArrow
                                            label={
                                                <>
                                                    <Text textAlign={"center"} color={"black"}>
                                                        Interview Id, Application Id
                                                    </Text>
                                                    <Text
                                                        fontSize={"xs"}
                                                        color={"gray"}
                                                        textAlign={"center"}
                                                    >
                                                        Click to view the Full Application Details,
                                                        and previous interview details also.
                                                    </Text>
                                                </>
                                            }
                                            bg="gray.300"
                                            color="black"
                                            placement="top"
                                        >
                                            Ids
                                        </Tooltip>
                                    </Flex>
                                </Th>
                                <Th style={{ color: "white" }}>
                                    <Tooltip
                                        hasArrow
                                        label="Current Interview Round No."
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                    >
                                        cRn.
                                    </Tooltip>
                                </Th>
                                <Th style={{ color: "white" }}>
                                    <Tooltip
                                        hasArrow
                                        label={
                                            <>
                                                <Text textAlign={"center"} color={"black"}>
                                                    Current Round Scheduled For & On
                                                </Text>
                                                <Text fontSize={"xs"} color={"gray"}>
                                                    <b>Scheduled For</b>: The date and time the
                                                    interview will take place.
                                                    <br />
                                                    <b>Scheduled On</b>: The date and time the
                                                    interview was scheduled.
                                                </Text>
                                            </>
                                        }
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                    >
                                        Skd. For & Skd. On
                                    </Tooltip>
                                </Th>
                                <Th style={{ color: "white", textAlign: "center" }}>
                                    <Tooltip
                                        hasArrow
                                        label={
                                            <>
                                                <Text textAlign={"center"} color={"black"}>
                                                    Current Interview Round Result
                                                </Text>
                                                <Text
                                                    fontSize={"xs"}
                                                    color={"gray"}
                                                    textAlign={"center"}
                                                >
                                                    Hover on it to see Interview Result Remarks
                                                </Text>
                                            </>
                                        }
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                    >
                                        Result
                                    </Tooltip>
                                </Th>
                                <Th style={{ color: "white", textAlign: "center" }}>
                                    <Tooltip
                                        hasArrow
                                        label="Click to do neccessary actions"
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                    >
                                        Actions
                                    </Tooltip>
                                </Th>
                                <Th style={{ color: "white" }}>
                                    <Tooltip
                                        hasArrow
                                        label='Click on respective "Menu Button" for more options.'
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                        maxW={"200px"}
                                    >
                                        <Box>
                                            <MdMenu />
                                        </Box>
                                    </Tooltip>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {sortedData &&
                                sortedData.map((application, index) => (
                                    <Tr key={index}>
                                        <Td style={{ textAlign: "center" }}>{index + 1}</Td>
                                        <Td
                                            style={{
                                                maxWidth: "230px",
                                                overflow: "hidden",
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <Text fontSize={"sm"}>
                                                    <Tooltip
                                                        hasArrow
                                                        label={
                                                            <Text fontSize={"xs"} color={"gray"}>
                                                                Click to view{" "}
                                                                <b>
                                                                    {
                                                                        application?.jobDescription
                                                                            ?.jobId
                                                                    }
                                                                </b>{" "}
                                                                in Details
                                                            </Text>
                                                        }
                                                        bg="gray.300"
                                                        color="black"
                                                        placement="top"
                                                    >
                                                        <Flex
                                                            style={{ cursor: "pointer" }}
                                                            cursor={
                                                                loadingJD ? "progress" : "progress"
                                                            }
                                                            onClick={() => {
                                                                if (!loadingJD)
                                                                    handleJDPreview(
                                                                        application?.jobDescription
                                                                    );
                                                            }}
                                                        >
                                                            {application?.jobDescription?.jobId}
                                                        </Flex>
                                                    </Tooltip>
                                                </Text>

                                                <Text
                                                    fontSize={"sm"}
                                                    color={"#a3a3a3"}
                                                    style={{
                                                        textOverflow: "ellipsis",
                                                        overflow: "hidden",
                                                        maxWidth: "180px",
                                                    }}
                                                >
                                                    {application?.jobDescription?.jobTitle}
                                                </Text>
                                            </Box>
                                        </Td>
                                        <Td
                                            style={{
                                                maxWidth: "250px",
                                                overflow: "hidden",
                                            }}
                                        >
                                            <Flex>
                                                <Tooltip
                                                    hasArrow
                                                    label={
                                                        <Text fontSize={"xs"} color={"gray"}>
                                                            Click to view Candidate{" "}
                                                            <b>{application?.candidate?.stuId}</b> in
                                                            Details
                                                        </Text>
                                                    }
                                                    bg="gray.300"
                                                    color="black"
                                                    placement="top"
                                                >
                                                    <Avatar
                                                        src={`${backendUrl}${application?.candidate?.dp}`}
                                                        size="sm"
                                                        name={application?.candidate?.name}
                                                        mr={2}
                                                        background={"green"}
                                                        color={"white"}
                                                        cursor={
                                                            loadingCandidate
                                                                ? "progress"
                                                                : "pointer"
                                                        }
                                                        onClick={() => {
                                                            if (!loadingCandidate)
                                                                handleCandidatePreview(
                                                                    application?.candidate
                                                                );
                                                        }}
                                                    />
                                                </Tooltip>

                                                <Box
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <Flex gap={1}>
                                                        <Text
                                                            fontSize={"sm"}
                                                            style={{
                                                                textOverflow: "ellipsis",
                                                                overflow: "hidden",
                                                                maxWidth: "150px",
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    color: "#a3a3a3",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                {application?.candidate?.stuId}
                                                            </span>
                                                            {" - "}
                                                            {application?.candidate?.name}
                                                        </Text>
                                                        {getGenderIcon(
                                                            application?.candidate?.gender
                                                        )}
                                                    </Flex>
                                                    <Text
                                                        fontSize={"sm"}
                                                        color={"#a3a3a3"}
                                                        style={{
                                                            textOverflow: "ellipsis",
                                                            overflow: "hidden",
                                                            maxWidth: "160px",
                                                        }}
                                                    >
                                                        <Tooltip
                                                            hasArrow
                                                            label={
                                                                <Text
                                                                    fontSize={"xs"}
                                                                    color={"gray"}
                                                                >
                                                                    Click to mail the Candidate
                                                                </Text>
                                                            }
                                                            bg="gray.300"
                                                            color="black"
                                                            placement="bottom"
                                                        >
                                                            <a
                                                                target="_blank"
                                                                href={`mailto:${application?.candidate?.email}`}
                                                            >
                                                                {application?.candidate?.email}
                                                            </a>
                                                        </Tooltip>
                                                    </Text>
                                                </Box>
                                            </Flex>
                                        </Td>
                                        <Td>
                                            {getTotalPassedRounds(application?.interviewDetails)}/
                                            {getTotalRoundNo(application?.jobDescription)}
                                        </Td>
                                        <PrintLastIntvDetails
                                            application={application}
                                            interview={
                                                application?.interviewDetails[
                                                    application?.interviewDetails?.length - 1
                                                ]
                                            }
                                            status={status}
                                            handleSkdIntModalPreview={handleSkdIntModalPreview}
                                            handleRecIntvResModalPreview={
                                                handleRecIntvResModalPreview
                                            }
                                            handleOfferCandModalPreview={
                                                handleOfferCandModalPreview
                                            }
                                            totalPassedRounds={getTotalPassedRounds(
                                                application?.interviewDetails
                                            )}
                                            totalRoundNo={getTotalRoundNo(
                                                application?.jobDescription
                                            )}
                                            setIsUpdatingResult={setIsUpdatingResult}
                                            handleApplicationPreview={handleApplicationPreview}
                                            setSelectedApplication={setSelectedApplication}
                                            setSubTab={setSubTab}
                                        />
                                    </Tr>
                                ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </InfiniteScroll>

            {candidateData && isCandidateDetailsModalOpen && (
                <CandidateDetails
                    candidate={candidateData}
                    isOpen={isCandidateDetailsModalOpen}
                    onClose={handleCandidateViewCloseModal}
                />
            )}

            {jobDescriptionData && isJDDetailsModalOpen && (
                <JdDetails
                    jd={jobDescriptionData}
                    isOpen={isJDDetailsModalOpen}
                    onClose={handleJDViewCloseModal}
                />
            )}

            {selectedApplication && isSkdIntModalOpen && (
                <ScheduleInterview
                    application={selectedApplication}
                    isOpen={isSkdIntModalOpen}
                    onClose={handleSkdIntCloseModal}
                    roundNo={selectedRoundDetails?.roundNo}
                    scheduleInterview={scheduleInterview}
                    isRescheduling={isRescheduling}
                    prevScheduledOn={formatDateTimeDay(selectedRoundDetails?.scheduledOn)}
                />
            )}

            {selectedApplication && isRecIntvResModalOpen && (
                <RecordIntvRes
                    application={selectedApplication}
                    isOpen={isRecIntvResModalOpen}
                    onClose={handleRecIntvResCloseModal}
                    roundNo={selectedRoundDetails?.roundNo}
                    interviewDetails={selectedRoundDetails}
                    recordInterviewRes={recordInterviewRes}
                    status={status}
                    isUpdatingResult={isUpdatingResult}
                />
            )}

            {selectedApplication && isOfferCandModalOpen && (
                <RecordOffer
                    application={selectedApplication}
                    isOpen={isOfferCandModalOpen}
                    onClose={handleOfferCandCloseModal}
                    recordOffer={recordOffer}
                />
            )}

            {selectedApplication && isApplicationDetailsModalOpen && (
                <ApplicationDetails
                    application={selectedApplication}
                    isOpen={isApplicationDetailsModalOpen}
                    onClose={handleApplicationViewCloseModal}
                    tab={1}
                    subTab={subTab}
                />
            )}
        </div>
    );
};

export default InterviewProcessBody;
