import React, { useState, useEffect } from "react";

import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Button,
    Badge,
    Text,
    Box,
    Tooltip,
    Flex,
    Avatar,
    Skeleton,
} from "@chakra-ui/react";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";

import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";

import { formatDateTime } from "../../../Utils/GlobalFunctions/FormatDate";
import getGenderIcon from "./../../../Utils/GlobalFunctions/GenderIcon";

import { GrMore } from "react-icons/gr";
import { FaSort } from "react-icons/fa";
import { IoMdMale, IoMdFemale } from "react-icons/io";
import { IoMaleFemale } from "react-icons/io5";
import { MdMenu } from "react-icons/md";

import { useEnv } from "../../../context/EnvContext";
import { useCandidate } from "../../../context/CandidateContext";
import { useJD } from "../../../context/JDContext";
import { useApplication } from "../../../context/ApplicationContext";

import InfiniteScroll from "react-infinite-scroll-component";

import CandidateDetails from "../Candidate/CandidateDetails";
import JdDetails from "../JobDescription/JdDetails";
import RecordResult from "./RecordResult";
import ScheduleInterview from "../InterviewProcess/ScheduleInterview";
import ApplicationDetails from "../Application/ApplicationDetails";

const CVProcessBody = ({ data, fetchAllApplications, hasMore, pageNo, status }) => {
    console.log("data", data);
    console.log("hasMore", hasMore);
    console.log("pageNo", pageNo);
    console.log("status", status);

    const { backendUrl } = useEnv();
    const { candidateData, setCandidateData, fetchSpecificCandidate, loadingCandidate } =
        useCandidate();
    const { jobDescriptionData, setJobDescriptionData, fetchJDById, loadingJD } = useJD();
    const { forwardCV, rejectApplication, revertRejection, recordCvRes, scheduleInterview } =
        useApplication();

    // handle view modals
    const [selectedApplication, setSelectedApplication] = useState(null);
    const [isCandidateDetailsModalOpen, setIsCandidateDetailsModalOpen] = useState(false);
    const [isJDDetailsModalOpen, setIsJDDetailsModalOpen] = useState(false);
    const [isApplicationDetailsModalOpen, setIsApplicationDetailsModalOpen] = useState(false);
    const [isRecordResultModalOpen, setIsRecordResultModalOpen] = useState(false);
    const [isSkdIntModalOpen, setIsSkdIntModalOpen] = useState(false);

    const [sortedData, setSortedData] = useState(data);
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: null,
    });

    // Fetch more data
    const fetchMoreData = () => {
        console.log("fetching more data.......");
        fetchAllApplications("cvProcess", status, pageNo, 30, true);
    };

    // Sort the data
    useEffect(() => {
        let sortedArray = [...data];
        if (sortConfig.key) {
            sortedArray.sort((a, b) => {
                let aValue = a[sortConfig.key];
                let bValue = b[sortConfig.key];

                // Handle nested keys for jobDescription
                if (sortConfig.key === "jobDescription.jobTitle") {
                    aValue = a.jobDescription.jobTitle;
                    bValue = b.jobDescription.jobTitle;
                }

                // Handle nested keys for candidate
                if (sortConfig.key === "candidate.name") {
                    aValue = a.candidate.name;
                    bValue = b.candidate.name;
                }

                // Handle nested keys for cv forwardingDate
                if (sortConfig.key === "cvSelectionDetails.forwardingDate") {
                    aValue = a.cvSelectionDetails.forwardingDate;
                    bValue = b.cvSelectionDetails.forwardingDate;
                }

                // Handle nested keys for cv resultDate
                if (sortConfig.key === "cvSelectionDetails.resultDate") {
                    aValue = a.cvSelectionDetails.resultDate;
                    bValue = b.cvSelectionDetails.resultDate;
                }

                // Handle nested keys for result
                if (sortConfig.key === "cvSelectionDetails.result") {
                    aValue = a.cvSelectionDetails.result;
                    bValue = b.cvSelectionDetails.result;
                }
                if (aValue < bValue) {
                    return sortConfig.direction === "ascending" ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === "ascending" ? 1 : -1;
                }
                return 0;
            });
        }
        setSortedData(sortedArray);
    }, [sortConfig, data]);

    const requestSort = (key) => {
        let direction = "ascending";
        if (sortConfig.key === key && sortConfig.direction === "ascending") {
            direction = "descending";
        } else if (sortConfig.key === key && sortConfig.direction === "descending") {
            setSortConfig({ key: null, direction: null });
            return;
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            if (sortConfig.direction === "ascending") {
                return <TiArrowSortedUp cursor={"pointer"} />;
            } else if (sortConfig.direction === "descending") {
                return <TiArrowSortedDown cursor={"pointer"} />;
            }
        }
        return <FaSort cursor={"pointer"} />;
    };

    // Handle Candidate Details modal Preview
    const handleCandidatePreview = (candidate) => {
        // fetch the candidate
        fetchSpecificCandidate(candidate._id);
        setIsCandidateDetailsModalOpen(true);
    };

    const handleCandidateViewCloseModal = () => {
        setIsCandidateDetailsModalOpen(false);
        setCandidateData(null);
    };

    // Handle JD Details modal Preview
    const handleJDPreview = (jd) => {
        // fetch the jd
        fetchJDById(jd._id);
        setIsJDDetailsModalOpen(true);
    };

    const handleJDViewCloseModal = () => {
        setIsJDDetailsModalOpen(false);
        setJobDescriptionData(null);
    };

    // Handle Application Details modal Preview
    const handleApplicationPreview = () => {
        setIsApplicationDetailsModalOpen(true);
    };
    const handleApplicationViewCloseModal = () => {
        setIsApplicationDetailsModalOpen(false);
        setSelectedApplication(null);
    };

    // Handle Record CV Response Result modal Preview
    const handleCvResModalPreview = (application) => {
        setSelectedApplication(application);
        setIsRecordResultModalOpen(true);
    };
    const handleCvResCloseModal = () => {
        setIsRecordResultModalOpen(false);
        setSelectedApplication(null);
    };

    // Handle Schedule interview modal Preview
    const handleSkdIntModalPreview = (application) => {
        setSelectedApplication(application);
        setIsSkdIntModalOpen(true);
    };
    const handleSkdIntCloseModal = () => {
        setIsSkdIntModalOpen(false);
        setSelectedApplication(null);
    };
    return (
        <div
            style={{
                height: "75vh",
                overflow: "auto",
                position: "relative",
            }}
        >
            <InfiniteScroll
                dataLength={sortedData.length}
                next={fetchMoreData}
                hasMore={hasMore}
                height={"100%"}
                loader={
                    <>
                        <Skeleton height="30px" m={2} />
                        <Skeleton height="30px" m={2} />
                        <Skeleton height="30px" m={2} />
                    </>
                }
                endMessage={
                    <p style={{ textAlign: "center", marginTop: "5px" }}>
                        <b>End of the List</b>
                    </p>
                }
            >
                <TableContainer
                    style={{ maxHeight: "100%" }}
                    overflowY={sortedData.length < 5 ? "auto" : "clip"}
                    overflowX={sortedData.length < 5 ? "clip" : "clip"}
                >
                    <Table size="sm">
                        <Thead
                            style={{
                                position: "sticky",
                                top: 0,
                                backgroundColor: "#05080D",
                                zIndex: 1,
                            }}
                        >
                            <Tr>
                                <Th style={{ color: "white" }}>Sl</Th>
                                <Th
                                    style={{ color: "white" }}
                                    onClick={() => requestSort("cvSelectionDetails?.processId")}
                                >
                                    <Flex
                                        alignItems={"center"}
                                        width={"100%"}
                                        justify={"space-between"}
                                    >
                                        <Tooltip
                                            hasArrow
                                            label={
                                                <>
                                                    <Text textAlign={"center"} color={"gray"}>
                                                        CV Process Id, Application Id
                                                    </Text>
                                                    <Text
                                                        fontSize={"xs"}
                                                        color={"gray"}
                                                        textAlign={"center"}
                                                    >
                                                        Click to view the Application Details
                                                    </Text>
                                                </>
                                            }
                                            bg="gray.300"
                                            color="black"
                                            placement="top"
                                        >
                                            Ids
                                        </Tooltip>
                                        {getSortIcon("cvSelectionDetails?.processId")}
                                    </Flex>
                                </Th>
                                <Th
                                    style={{
                                        color: "white",
                                    }}
                                    onClick={() => requestSort("jobDescription?.jobTitle")}
                                >
                                    {" "}
                                    <Flex
                                        alignItems={"center"}
                                        width={"100%"}
                                        justify={"space-between"}
                                    >
                                        <Tooltip
                                            hasArrow
                                            label={
                                                <Box
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <Text fontSize={"sm"} textAlign={"center"}>
                                                        Job Id, Job Title
                                                    </Text>
                                                    <Text
                                                        fontSize={"xs"}
                                                        color={"gray"}
                                                        textAlign={"center"}
                                                    >
                                                        Click on job Id to view the JD details
                                                    </Text>
                                                </Box>
                                            }
                                            bg="gray.300"
                                            color="black"
                                            placement="top"
                                        >
                                            Job Description
                                        </Tooltip>
                                        {getSortIcon("jobDescription?.jobTitle")}
                                    </Flex>
                                </Th>
                                <Th
                                    style={{
                                        color: "white",
                                    }}
                                    onClick={() => requestSort("candidate?.name")}
                                >
                                    {" "}
                                    <Flex
                                        alignItems={"center"}
                                        width={"100%"}
                                        justify={"space-between"}
                                    >
                                        <Tooltip
                                            hasArrow
                                            label={
                                                <>
                                                    <Flex align={"center"}>
                                                        <Avatar
                                                            src=""
                                                            size="sm"
                                                            name="Display Picture"
                                                            mr={2}
                                                            background={"green"}
                                                            color={"white"}
                                                        />
                                                        <Box
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                            }}
                                                        >
                                                            <Text
                                                                fontSize={"sm"}
                                                                style={{
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                }}
                                                            >
                                                                <b>Candidate Id</b>
                                                                {" - "}
                                                                Name, gender
                                                            </Text>
                                                            <Text
                                                                fontSize={"sm"}
                                                                color={"#2b2a2a"}
                                                                style={{
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                    maxWidth: "180px",
                                                                }}
                                                            >
                                                                Candidate Email
                                                            </Text>
                                                        </Box>
                                                    </Flex>
                                                    <Flex
                                                        direction={"column"}
                                                        fontSize={"xs"}
                                                        color={"gray"}
                                                        mt={2}
                                                    >
                                                        <Text color={"black"}>
                                                            Gender Details:{" "}
                                                        </Text>
                                                        <Flex
                                                            align={"center"}
                                                            gap={1}
                                                            background={"black"}
                                                            p={1}
                                                            px={2}
                                                            color={"white"}
                                                        >
                                                            <IoMdMale color="#2D9AFE" />
                                                            <Text>Male, </Text>
                                                            <IoMdFemale color="#F86CFF" />
                                                            <Text>Female, </Text>
                                                            <IoMaleFemale color="#ffd600" />
                                                            <Text>Other</Text>
                                                        </Flex>
                                                    </Flex>
                                                    <Text
                                                        fontSize={"xs"}
                                                        color={"gray"}
                                                        textAlign={"center"}
                                                    >
                                                        Click on the DP to view the candidate
                                                        details <br />
                                                        Click on mail id to mail the candidate.
                                                    </Text>
                                                </>
                                            }
                                            bg="gray.300"
                                            color="black"
                                            placement="top"
                                        >
                                            Candidate
                                        </Tooltip>
                                        {getSortIcon("candidate?.name")}
                                    </Flex>
                                </Th>
                                <Th
                                    style={{ color: "white" }}
                                    onClick={() => requestSort("cvSelectionDetails?.forwardingDate")}
                                >
                                    <Flex
                                        alignItems={"center"}
                                        width={"100%"}
                                        justify={"space-between"}
                                    >
                                        <Tooltip
                                            hasArrow
                                            label="CV Forwarded On"
                                            bg="gray.300"
                                            color="black"
                                            placement="top"
                                        >
                                            Forwarded On
                                        </Tooltip>
                                        {getSortIcon("cvSelectionDetails?.forwardingDate")}
                                    </Flex>
                                </Th>
                                <Th
                                    style={{ color: "white" }}
                                    onClick={() => requestSort("cvSelectionDetails?.result")}
                                >
                                    <Flex
                                        alignItems={"center"}
                                        width={"100%"}
                                        justify={"space-between"}
                                    >
                                        <Tooltip
                                            hasArrow
                                            label="CV Process Result Status"
                                            bg="gray.300"
                                            color="black"
                                            placement="top"
                                        >
                                            Res. St.
                                        </Tooltip>

                                        {getSortIcon("cvSelectionDetails?.result")}
                                    </Flex>
                                </Th>
                                {status != "pending" && (
                                    <Th
                                        style={{ color: "white" }}
                                        onClick={() => requestSort("cvSelectionDetails?.resultDate")}
                                    >
                                        <Flex
                                            alignItems={"center"}
                                            width={"100%"}
                                            justify={"space-between"}
                                        >
                                            <Tooltip
                                                hasArrow
                                                label={
                                                    status == "all"
                                                        ? "CV Process Result"
                                                        : "CV Process Result Recorded on"
                                                }
                                                bg="gray.300"
                                                color="black"
                                                placement="top"
                                            >
                                                {status == "all" ? "Result" : "Result Date"}
                                            </Tooltip>
                                            {getSortIcon("cvSelectionDetails?.resultDate")}
                                        </Flex>
                                    </Th>
                                )}
                                {status == "pending" && (
                                    <Th style={{ color: "white" }}>
                                        <Flex
                                            alignItems={"center"}
                                            width={"100%"}
                                            justify={"center"}
                                        >
                                            <Tooltip
                                                hasArrow
                                                label="CV Process Result Recorded on"
                                                bg="gray.300"
                                                color="black"
                                                placement="top"
                                            >
                                                Record Result
                                            </Tooltip>
                                        </Flex>
                                    </Th>
                                )}{" "}
                                {status == "selected" && (
                                    <Th style={{ color: "white" }}>
                                        <Flex
                                            alignItems={"center"}
                                            width={"100%"}
                                            justify={"center"}
                                        >
                                            <Tooltip
                                                hasArrow
                                                label="Schedule Interview"
                                                bg="gray.300"
                                                color="black"
                                                placement="top"
                                            >
                                                Skd. Int.
                                            </Tooltip>
                                        </Flex>
                                    </Th>
                                )}
                                <Th
                                    style={{
                                        color: "white",
                                    }}
                                >
                                    <Tooltip
                                        hasArrow
                                        label='Click on respective "Menu Button" for more options.'
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                        maxW={"200px"}
                                    >
                                        <Box>
                                            <MdMenu />
                                        </Box>
                                    </Tooltip>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {sortedData &&
                                sortedData?.map((application, index) => (
                                    <Tr key={index}>
                                        <Td>{index + 1}</Td>
                                        <Td
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setSelectedApplication(application);
                                                handleApplicationPreview(application);
                                            }}
                                        >
                                            <Tooltip
                                                hasArrow
                                                label={
                                                    <Text fontSize={"xs"} color={"gray"}>
                                                        Click to view Application in Details
                                                    </Text>
                                                }
                                                bg="gray.300"
                                                color="black"
                                                placement="top"
                                            >
                                                <Flex direction={"column"}>
                                                    <Text>
                                                        {application?.cvSelectionDetails?.processId}
                                                    </Text>
                                                    <Text fontSize={"sm"} color={"#a3a3a3"}>
                                                        {application?.applicationID}
                                                    </Text>
                                                </Flex>
                                            </Tooltip>
                                        </Td>
                                        <Td
                                            style={{
                                                maxWidth: "230px",
                                                overflow: "hidden",
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <Text fontSize={"sm"}>
                                                    <Tooltip
                                                        hasArrow
                                                        label={
                                                            <Text fontSize={"xs"} color={"gray"}>
                                                                Click to view{" "}
                                                                <b>
                                                                    {
                                                                        application?.jobDescription
                                                                            ?.jobId
                                                                    }
                                                                </b>{" "}
                                                                in Details
                                                            </Text>
                                                        }
                                                        bg="gray.300"
                                                        color="black"
                                                        placement="top"
                                                    >
                                                        <Flex
                                                            style={{ cursor: "pointer" }}
                                                            cursor={
                                                                loadingJD ? "progress" : "progress"
                                                            }
                                                            onClick={() => {
                                                                if (!loadingJD)
                                                                    handleJDPreview(
                                                                        application?.jobDescription
                                                                    );
                                                            }}
                                                        >
                                                            {application?.jobDescription?.jobId}
                                                        </Flex>
                                                    </Tooltip>
                                                </Text>

                                                <Text
                                                    fontSize={"sm"}
                                                    color={"#a3a3a3"}
                                                    style={{
                                                        textOverflow: "ellipsis",
                                                        overflow: "hidden",
                                                        maxWidth: "200px",
                                                    }}
                                                >
                                                    {application?.jobDescription?.jobTitle}
                                                </Text>
                                            </Box>
                                        </Td>
                                        <Td
                                            style={{
                                                maxWidth: "300px",
                                                overflow: "hidden",
                                            }}
                                        >
                                            <Flex>
                                                <Tooltip
                                                    hasArrow
                                                    label={
                                                        <Text fontSize={"xs"} color={"gray"}>
                                                            Click to view Candidate{" "}
                                                            <b>{application?.candidate?.stuId}</b> in
                                                            Details
                                                        </Text>
                                                    }
                                                    bg="gray.300"
                                                    color="black"
                                                    placement="top"
                                                >
                                                    <Avatar
                                                        src={`${backendUrl}${application?.candidate?.dp}`}
                                                        size="sm"
                                                        name={application?.candidate?.name}
                                                        mr={2}
                                                        background={"green"}
                                                        color={"white"}
                                                        cursor={
                                                            loadingCandidate
                                                                ? "progress"
                                                                : "pointer"
                                                        }
                                                        onClick={() => {
                                                            if (!loadingCandidate)
                                                                handleCandidatePreview(
                                                                    application?.candidate
                                                                );
                                                        }}
                                                    />
                                                </Tooltip>

                                                <Box
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <Flex gap={1}>
                                                        <Text
                                                            fontSize={"sm"}
                                                            style={{
                                                                textOverflow: "ellipsis",
                                                                overflow: "hidden",
                                                                maxWidth: "270px",
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    color: "#a3a3a3",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                {application?.candidate?.stuId}
                                                            </span>
                                                            {" - "}
                                                            {application?.candidate?.name}
                                                        </Text>
                                                        {getGenderIcon(
                                                            application?.candidate?.gender
                                                        )}
                                                    </Flex>
                                                    <Text
                                                        fontSize={"sm"}
                                                        color={"#a3a3a3"}
                                                        style={{
                                                            textOverflow: "ellipsis",
                                                            overflow: "hidden",
                                                            maxWidth: "270px",
                                                        }}
                                                    >
                                                        <Tooltip
                                                            hasArrow
                                                            label={
                                                                <Text
                                                                    fontSize={"xs"}
                                                                    color={"gray"}
                                                                >
                                                                    Click to mail the Candidate
                                                                </Text>
                                                            }
                                                            bg="gray.300"
                                                            color="black"
                                                            placement="bottom"
                                                        >
                                                            <a
                                                                target="_blank"
                                                                href={`mailto:${application?.candidate?.email}`}
                                                            >
                                                                {application?.candidate?.email}
                                                            </a>
                                                        </Tooltip>
                                                    </Text>
                                                </Box>
                                            </Flex>
                                        </Td>
                                        <Td>
                                            {formatDateTime(
                                                application?.cvSelectionDetails?.forwardingDate
                                            )}
                                        </Td>

                                        <Td>
                                            {application?.cvSelectionDetails?.result == "Pending" && (
                                                <Badge variant="subtle" colorScheme="blue">
                                                    Pending
                                                </Badge>
                                            )}
                                            {application?.cvSelectionDetails?.result ==
                                                "Selected" && (
                                                <Badge variant="subtle" colorScheme="green">
                                                    Selected
                                                </Badge>
                                            )}
                                            {application?.cvSelectionDetails?.result ==
                                                "Rejected" && (
                                                <Badge variant="subtle" colorScheme="red">
                                                    Rejected
                                                </Badge>
                                            )}
                                        </Td>
                                        {application?.cvSelectionDetails?.result != "Pending" && (
                                            <Td maxW={"190px"}>
                                                <Flex
                                                    justify={"space-between"}
                                                    pr={2}
                                                    align={"center"}
                                                    gap={1}
                                                >
                                                    <Tooltip
                                                        hasArrow
                                                        label={
                                                            application?.cvSelectionDetails
                                                                ?.remark && (
                                                                <>
                                                                    Remark:
                                                                    <Box whiteSpace="pre-line">
                                                                        {
                                                                            application
                                                                                ?.cvSelectionDetails
                                                                                ?.remark
                                                                        }
                                                                    </Box>
                                                                </>
                                                            )
                                                        }
                                                        bg="gray.300"
                                                        color="black"
                                                        placement="top"
                                                    >
                                                        {formatDateTime(
                                                            application?.cvSelectionDetails
                                                                ?.resultDate
                                                        )}
                                                    </Tooltip>
                                                    {application?.cvSelectionDetails?.result ==
                                                        "Selected" &&
                                                        status == "all" &&
                                                        application?.overallInterviewProcess ==
                                                            "Not Started" && (
                                                            <Button
                                                                colorScheme="teal"
                                                                size="xs"
                                                                ml={2}
                                                                onClick={() =>
                                                                    handleSkdIntModalPreview(
                                                                        application
                                                                    )
                                                                }
                                                            >
                                                                Skd. Intv.
                                                            </Button>
                                                        )}
                                                    {application?.cvSelectionDetails?.result ==
                                                        "Selected" &&
                                                        status == "all" &&
                                                        application?.overallInterviewProcess !==
                                                            "Not Started" && (
                                                            <Text fontSize={"sm"} color={"#9AE696"}>
                                                                Int. Schded.
                                                            </Text>
                                                        )}
                                                    {application?.cvSelectionDetails?.result ==
                                                        "Rejected" &&
                                                        status == "all" &&
                                                        application?.overallInterviewProcess ==
                                                            "Not Started" && (
                                                            <Button
                                                                colorScheme="purple"
                                                                size="xs"
                                                                ml={2}
                                                                onClick={() =>
                                                                    handleCvResModalPreview(
                                                                        application
                                                                    )
                                                                }
                                                            >
                                                                Upd. Res.
                                                            </Button>
                                                        )}
                                                </Flex>
                                            </Td>
                                        )}
                                        {application?.cvSelectionDetails?.result == "Selected" &&
                                            status == "selected" &&
                                            application?.overallInterviewProcess !==
                                                "Not Started" && (
                                                <Td>
                                                    <Text
                                                        fontSize={"sm"}
                                                        color={"#9AE696"}
                                                        textAlign={"center"}
                                                    >
                                                        Int. Schded.
                                                    </Text>
                                                </Td>
                                            )}

                                        {application?.cvSelectionDetails?.result == "Selected" &&
                                            status == "selected" &&
                                            application?.overallInterviewProcess ==
                                                "Not Started" && (
                                                <Td>
                                                    <Flex justify={"center"} w={"100%"}>
                                                        <Button
                                                            colorScheme="blue"
                                                            size="xs"
                                                            onClick={() =>
                                                                handleSkdIntModalPreview(
                                                                    application
                                                                )
                                                            }
                                                        >
                                                            Skd. Intv.
                                                        </Button>
                                                    </Flex>
                                                </Td>
                                            )}
                                        {application?.cvSelectionDetails?.result == "Pending" && (
                                            <Td>
                                                <Flex
                                                    justify={status == "all" ? "" : "center"}
                                                    w={"100%"}
                                                >
                                                    <Button
                                                        colorScheme="blue"
                                                        size="xs"
                                                        onClick={() =>
                                                            handleCvResModalPreview(application)
                                                        }
                                                    >
                                                        Record Result
                                                    </Button>
                                                </Flex>
                                            </Td>
                                        )}

                                        <Td>
                                            <Menu>
                                                <MenuButton>
                                                    <GrMore />
                                                </MenuButton>
                                                <MenuList minW={"50px"}>
                                                    <MenuItem
                                                        onClick={() => {
                                                            setSelectedApplication(application);
                                                            handleApplicationPreview(application);
                                                        }}
                                                    >
                                                        View Application
                                                    </MenuItem>
                                                    {application?.cvSelectionDetails?.result !=
                                                        "Pending" &&
                                                        application?.overallInterviewProcess ==
                                                            "Not Started" && (
                                                            <MenuItem
                                                                onClick={() =>
                                                                    handleCvResModalPreview(
                                                                        application
                                                                    )
                                                                }
                                                            >
                                                                Update CV Process Result
                                                            </MenuItem>
                                                        )}

                                                    {application?.cvSelectionDetails?.result ==
                                                        "Pending" && (
                                                        <MenuItem
                                                            onClick={() =>
                                                                handleCvResModalPreview(application)
                                                            }
                                                        >
                                                            Record CV Process Result
                                                        </MenuItem>
                                                    )}
                                                    {application?.cvSelectionDetails?.result ==
                                                        "Selected" &&
                                                        application?.overallInterviewProcess ==
                                                            "Not Started" && (
                                                            <MenuItem
                                                                onClick={() =>
                                                                    handleSkdIntModalPreview(
                                                                        application
                                                                    )
                                                                }
                                                            >
                                                                Schedule Interview
                                                            </MenuItem>
                                                        )}
                                                </MenuList>
                                            </Menu>
                                        </Td>
                                    </Tr>
                                ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </InfiniteScroll>

            {candidateData && isCandidateDetailsModalOpen && (
                <CandidateDetails
                    candidate={candidateData}
                    isOpen={isCandidateDetailsModalOpen}
                    onClose={handleCandidateViewCloseModal}
                />
            )}

            {jobDescriptionData && isJDDetailsModalOpen && (
                <JdDetails
                    jd={jobDescriptionData}
                    isOpen={isJDDetailsModalOpen}
                    onClose={handleJDViewCloseModal}
                />
            )}

            {selectedApplication && isRecordResultModalOpen && (
                <RecordResult
                    application={selectedApplication}
                    isOpen={isRecordResultModalOpen}
                    onClose={handleCvResCloseModal}
                    recordCvRes={recordCvRes}
                />
            )}

            {selectedApplication && isSkdIntModalOpen && (
                <ScheduleInterview
                    application={selectedApplication}
                    isOpen={isSkdIntModalOpen}
                    onClose={handleSkdIntCloseModal}
                    roundNo={1}
                    scheduleInterview={scheduleInterview}
                />
            )}

            {selectedApplication && isApplicationDetailsModalOpen && (
                <ApplicationDetails
                    application={selectedApplication}
                    isOpen={isApplicationDetailsModalOpen}
                    onClose={handleApplicationViewCloseModal}
                    tab={0}
                />
            )}
        </div>
    );
};

export default CVProcessBody;
