import { Tabs, TabList, TabPanels, Tab, TabPanel, Icon } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import JdContainer from "./JobDescription/JdContainer";
import ApplicationContainer from "./Application/ApplicationContainer";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import EmployeeContainer from "./Employees/EmployeeContainer";
import { TfiDashboard } from "react-icons/tfi";
import { MdDashboard } from "react-icons/md";
import { LuLayoutDashboard } from "react-icons/lu";
import AdminDashboardContainer from "./AdminDashboard/AdminDashboardContainer";
import ClientContainer from "./ClientTab/ClientContainer";

const AdminBody = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const { tab } = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const initialPath = pathname.split("/")[1];

    useEffect(() => {
        switch (tab) {
            case "Dashboard":
                setTabIndex(0);
                break;
            case "Clients":
                setTabIndex(1);
                break;
            case "Employees":
                setTabIndex(2);
                break;
            case "JobDescriptions":
                setTabIndex(3);
                break;
            case "Applications":
                setTabIndex(4);
                break;
            default:
                setTabIndex(0);
                break;
        }
    }, [tab]);

    const handleTabsChange = (index) => {
        setTabIndex(index);
        const tabMapping = ["Dashboard", "Clients", "Employees", "JobDescriptions", "Applications"];
        navigate(`/${initialPath}/${tabMapping[index]}/Default`);
    };

    return (
        <div
            className="recruiter-body"
            style={{
                background: "linear-gradient(180deg, black, rgb(29, 48, 75))",
                height: "calc(100vh - 3rem)",
                overflow: "clip",
            }}
        >
            <Tabs
                isLazy
                variant="enclosed"
                colorScheme="green"
                color={"white"}
                index={tabIndex}
                onChange={handleTabsChange}
            >
                <TabList mb="1" style={{ background: "black" }}>
                    <Tab>
                        <Icon as={TfiDashboard} fontSize={"24px"} />
                    </Tab>
                    <Tab>Clients</Tab>
                    <Tab>Employees</Tab>
                    <Tab>Job Descriptions</Tab>
                    <Tab>Applications</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel
                        pb={0}
                        style={{
                            height: "calc(100vh - 5rem)",
                            overflow: "clip",
                            paddingTop: 0,
                        }}
                        pl={0}
                        pr={0}
                    >
                        <AdminDashboardContainer />
                    </TabPanel>{" "}
                    <TabPanel
                        pb={0}
                        style={{
                            height: "calc(100vh - 5rem)",
                            overflow: "clip",
                            paddingTop: 0,
                        }}
                    >
                        <ClientContainer />
                    </TabPanel>
                    <TabPanel
                        pb={0}
                        style={{
                            height: "calc(100vh - 5rem)",
                            overflow: "clip",
                            paddingTop: 0,
                        }}
                    >
                        <EmployeeContainer />
                    </TabPanel>
                    <TabPanel
                        pb={0}
                        style={{
                            height: "calc(100vh - 5rem)",
                            overflow: "clip",
                            paddingTop: 0,
                        }}
                    >
                        <JdContainer />
                    </TabPanel>
                    <TabPanel
                        pb={0}
                        style={{
                            height: "calc(100vh - 5rem)",
                            overflow: "clip",
                            paddingTop: 0,
                        }}
                    >
                        <ApplicationContainer />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </div>
    );
};

export default AdminBody;
