import React, { useState, useEffect } from "react";

import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Button,
    Badge,
    Text,
    Box,
    Tooltip,
    Flex,
    Avatar,
    MenuDivider,
    Skeleton,
} from "@chakra-ui/react";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverArrow,
    PopoverCloseButton,
} from "@chakra-ui/react";

import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import { GrMore } from "react-icons/gr";
import { FaSort, FaWhatsapp } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { IoMdMale, IoMdFemale } from "react-icons/io";
import { IoMaleFemale } from "react-icons/io5";
import { MdMenu } from "react-icons/md";

import { formatDateTime } from "../../../Utils/GlobalFunctions/FormatDate";
import convertToLPA from "../../../Utils/GlobalFunctions/FormatSalary";
import { printSkills } from "../../../Utils/GlobalFunctions/PrintSkills";
import getGenderIcon from "../../../Utils/GlobalFunctions/GenderIcon";

import CandidateDetails from "./CandidateDetails";
import CandidateForm from "./CandidateForm";
import ProcessCandidate from "./ProcessCandidate";

import InfiniteScroll from "react-infinite-scroll-component";

import { useCandidate } from "../../../context/CandidateContext";
import { useEnv } from "../../../context/EnvContext";

const CandidateBody = ({ data, fetchAllCandidates, hasMore, pageNo, status }) => {
    const { loading, deleteCandidate } = useCandidate();
    const { backendUrl } = useEnv();
    const [selectedCandidate, setSelectedCandidate] = useState(null);
    const [isCandidateDetailsModalOpen, setIsCandidateDetailsModalOpen] = useState(false);
    const [isCandidateFormModalOpen, setIsCandidateFormModalOpen] = useState(false);
    const [isProcessCandidateModalOpen, setIsProcessCandidateModalOpen] = useState(false);

    const [sortedData, setSortedData] = useState(data);
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: null,
    });

    // Sort the data
    useEffect(() => {
        let sortedArray = [...data];
        if (sortConfig.key) {
            sortedArray.sort((a, b) => {
                let aValue = a[sortConfig.key];
                let bValue = b[sortConfig.key];

                // Handle nested keys
                // if (sortConfig.key === "companyDetails.companyName") {
                //     aValue = a.companyDetails.companyName;
                //     bValue = b.companyDetails.companyName;
                // }

                if (aValue < bValue) {
                    return sortConfig.direction === "ascending" ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === "ascending" ? 1 : -1;
                }
                return 0;
            });
        }
        setSortedData(sortedArray);
    }, [sortConfig, data]);

    const fetchMoreData = () => {
        console.log("fetching more data.......");
        fetchAllCandidates(status, pageNo, 30, true);
    };

    const requestSort = (key) => {
        let direction = "ascending";
        if (sortConfig.key === key && sortConfig.direction === "ascending") {
            direction = "descending";
        } else if (sortConfig.key === key && sortConfig.direction === "descending") {
            setSortConfig({ key: null, direction: null });
            return;
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            if (sortConfig.direction === "ascending") {
                return <TiArrowSortedUp cursor={"pointer"} />;
            } else if (sortConfig.direction === "descending") {
                return <TiArrowSortedDown cursor={"pointer"} />;
            }
        }
        return <FaSort cursor={"pointer"} />;
    };

    // Handle Candidate Details modal Preview
    const handleCandidatePreview = (candidate) => {
        setSelectedCandidate(candidate);
        setIsCandidateDetailsModalOpen(true);
    };

    const handleCandidateViewCloseModal = () => {
        setIsCandidateDetailsModalOpen(false);
        setSelectedCandidate(null);
    };

    // Handle Candidate Form modal Preview
    const handleCandidateForm = (candidate) => {
        setSelectedCandidate(candidate);
        setIsCandidateFormModalOpen(true);
    };

    const handleCandidateFormCloseModal = () => {
        setIsCandidateFormModalOpen(false);
        setSelectedCandidate(null);
    };

    // Handle Start Application modal Preview
    const handleProcessCandidateModalPreview = (candidate) => {
        setSelectedCandidate(candidate);
        setIsProcessCandidateModalOpen(true);
    };

    const handleProcessCandidateModalClose = () => {
        setIsProcessCandidateModalOpen(false);
        setSelectedCandidate(null);
    };
    return (
        <div
            style={{
                height: "75vh",
                overflow: "auto",
                position: "relative",
            }}
        >
            <InfiniteScroll
                dataLength={sortedData.length}
                next={fetchMoreData}
                hasMore={hasMore}
                height={"100%"}
                loader={
                    <>
                        <Skeleton height="30px" m={2} />
                        <Skeleton height="30px" m={2} />
                        <Skeleton height="30px" m={2} />
                    </>
                }
                endMessage={
                    <p style={{ textAlign: "center", marginTop: "5px" }}>
                        <b>End of the List</b>
                    </p>
                }
            >
                <TableContainer
                    style={{ position: "relative" }}
                    overflowY={sortedData.length < 5 ? "auto" : "clip"}
                    overflowX={sortedData.length < 5 ? "clip" : "clip"}
                >
                    <Table size="sm">
                        <Thead
                            style={{
                                position: "sticky",
                                top: 0,
                                backgroundColor: "#05080D",
                                zIndex: 1,
                            }}
                        >
                            <Tr>
                                <Th style={{ color: "white" }}>Sl</Th>
                                <Th style={{ color: "white" }} onClick={() => requestSort("stuId")}>
                                    <Tooltip
                                        hasArrow
                                        label={
                                            <Flex align={"center"} direction={"column"}>
                                                <Text>Student Id</Text>
                                                <Text fontSize={"xs"} color={"#2b2a2a"}>
                                                    Click to view Candidate Details
                                                </Text>
                                            </Flex>
                                        }
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                    >
                                        <Flex
                                            alignItems={"center"}
                                            width={"100%"}
                                            justify={"space-between"}
                                        >
                                            St. Id {getSortIcon("stuId")}
                                        </Flex>
                                    </Tooltip>
                                </Th>
                                <Th
                                    style={{
                                        color: "white",
                                    }}
                                    onClick={() => requestSort("name")}
                                >
                                    <Flex
                                        alignItems={"center"}
                                        width={"100%"}
                                        justify={"space-between"}
                                    >
                                        <Tooltip
                                            hasArrow
                                            label={
                                                <>
                                                    <Flex align={"center"}>
                                                        <Avatar
                                                            src=""
                                                            size="sm"
                                                            name="Display Picture"
                                                            mr={2}
                                                            background={"green"}
                                                            color={"white"}
                                                        />
                                                        <Box
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                            }}
                                                        >
                                                            <Text
                                                                fontSize={"sm"}
                                                                style={{
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                    maxWidth: "180px",
                                                                }}
                                                            >
                                                                Gender - Candidate Name
                                                            </Text>
                                                            <Text
                                                                fontSize={"sm"}
                                                                color={"#2b2a2a"}
                                                                style={{
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                    maxWidth: "180px",
                                                                }}
                                                            >
                                                                Candidate Email
                                                            </Text>
                                                        </Box>
                                                    </Flex>
                                                    <Text fontSize={"xs"} color={"gray"}>
                                                        click on mail to mail the candidate directly
                                                    </Text>
                                                    <Flex
                                                        direction={"column"}
                                                        fontSize={"xs"}
                                                        color={"gray"}
                                                        mt={2}
                                                    >
                                                        <Text color={"black"}>
                                                            Gender Details:{" "}
                                                        </Text>
                                                        <Flex
                                                            align={"center"}
                                                            gap={1}
                                                            background={"black"}
                                                            p={1}
                                                            px={2}
                                                            color={"white"}
                                                        >
                                                            <IoMdMale color="#2D9AFE" />
                                                            <Text>Male, </Text>
                                                            <IoMdFemale color="#F86CFF" />
                                                            <Text>Female, </Text>
                                                            <IoMaleFemale color="#ffd600" />
                                                            <Text>Other</Text>
                                                        </Flex>
                                                    </Flex>
                                                </>
                                            }
                                            bg="gray.300"
                                            color="black"
                                            placement="top"
                                        >
                                            Candidate
                                        </Tooltip>
                                        {getSortIcon("name")}
                                    </Flex>
                                </Th>
                                <Th style={{ color: "white" }}>
                                    <Tooltip
                                        hasArrow
                                        label={
                                            <Flex direction={"column"} fontSize={"xs"}>
                                                <Flex gap={1} alignItems={"center"}>
                                                    <IoCall color="black" />
                                                    <Text fontSize={"xs"}>
                                                        Phone Number- click to call directly
                                                    </Text>
                                                </Flex>
                                                <Flex gap={1} alignItems={"center"}>
                                                    <FaWhatsapp color="black" />
                                                    <Text fontSize={"xs"}>
                                                        Whatsapp Number- click to msg. directly
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                        }
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                    >
                                        Contact
                                    </Tooltip>
                                </Th>
                                <Th
                                    style={{
                                        color: "white",
                                        maxWidth: "200px",
                                    }}
                                >
                                    <Tooltip
                                        hasArrow
                                        label={
                                            <>
                                                Candidate Skills with his experience in it.
                                                <Text
                                                    fontSize={"xs"}
                                                    color={"gray"}
                                                    textAlign={"center"}
                                                >
                                                    click on skill to view skills with relavant
                                                    experience in details.
                                                </Text>
                                            </>
                                        }
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                    >
                                        Skills
                                    </Tooltip>
                                </Th>
                                <Th style={{ color: "white" }}>
                                    <Tooltip
                                        hasArrow
                                        label={
                                            <>
                                                <Flex
                                                    direction={"column"}
                                                    fontSize={"xs"}
                                                    borderLeft={"3px solid"}
                                                    borderColor="green"
                                                    paddingLeft={1}
                                                >
                                                    <Text fontSize={"xs"} fontWeight={"bold"}>
                                                        Experience Period
                                                    </Text>
                                                    <Text fontSize={"xs"}>(Experience Type)</Text>
                                                </Flex>
                                                <Box
                                                    mt={1}
                                                    paddingLeft={1}
                                                    borderLeft={"3px solid"}
                                                    borderColor="red"
                                                >
                                                    Fresher
                                                </Box>
                                                <Box
                                                    mt={1}
                                                    paddingLeft={1}
                                                    borderLeft={"3px solid"}
                                                    borderColor="green"
                                                >
                                                    Experienced
                                                </Box>
                                            </>
                                        }
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                    >
                                        Experience
                                    </Tooltip>
                                </Th>
                                <Th style={{ color: "white" }}>
                                    <Tooltip
                                        hasArrow
                                        label={
                                            <Flex direction={"column"} fontSize={"xs"}>
                                                <Flex gap={1} alignItems={"center"}>
                                                    <Text fontSize={"xs"}>Current-</Text>
                                                    <Text fontSize={"xs"} fontWeight={"bold"}>
                                                        Stipend / CTC
                                                    </Text>
                                                </Flex>
                                                <Text fontSize={"xs"} fontWeight={"bold"}>
                                                    Expected CTC
                                                </Text>
                                            </Flex>
                                        }
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                    >
                                        CTC
                                    </Tooltip>
                                </Th>
                                <Th style={{ color: "white" }}>
                                    <Tooltip
                                        hasArrow
                                        label="Address- City, State"
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                    >
                                        Address
                                    </Tooltip>
                                </Th>
                                <Th
                                    style={{ color: "white" }}
                                    onClick={() => requestSort("currentStatus")}
                                >
                                    <Flex
                                        alignItems={"center"}
                                        width={"100%"}
                                        justify={"space-between"}
                                    >
                                        <Tooltip
                                            hasArrow
                                            label="Candidate Status"
                                            bg="gray.300"
                                            color="black"
                                            placement="top"
                                        >
                                            Status
                                        </Tooltip>{" "}
                                        {getSortIcon("currentStatus")}
                                    </Flex>
                                </Th>
                                <Th style={{ color: "white" }}>
                                    <Flex
                                        alignItems={"center"}
                                        width={"100%"}
                                        justify={"space-between"}
                                        gap={2}
                                    >
                                        <Flex
                                            alignItems={"center"}
                                            flexGrow={1}
                                            justify={"space-between"}
                                            cursor={"pointer"}
                                            onClick={() => requestSort("createdAt")}
                                        >
                                            <Tooltip
                                                hasArrow
                                                label="Candidate Added On"
                                                bg="gray.300"
                                                color="black"
                                                placement="top"
                                            >
                                                Add
                                            </Tooltip>{" "}
                                            {getSortIcon("createdAt")}
                                        </Flex>
                                        <Flex
                                            alignItems={"center"}
                                            flexGrow={1}
                                            justify={"space-between"}
                                            cursor={"pointer"}
                                            onClick={() => requestSort("updatedAt")}
                                        >
                                            <Tooltip
                                                hasArrow
                                                label="Candidate Updated On"
                                                bg="gray.300"
                                                color="black"
                                                placement="top"
                                            >
                                                Upd
                                            </Tooltip>{" "}
                                            {getSortIcon("updatedAt")}
                                        </Flex>
                                    </Flex>
                                </Th>
                                <Th
                                    style={{
                                        color: "white",
                                    }}
                                >
                                    <Tooltip
                                        hasArrow
                                        label='Click on respective "Menu Button" for more options.'
                                        bg="gray.300"
                                        color="black"
                                        placement="top"
                                        maxW={"200px"}
                                    >
                                        <Box>
                                            <MdMenu />
                                        </Box>
                                    </Tooltip>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {sortedData &&
                                sortedData?.map((candidate, index) => (
                                    <Tr key={index}>
                                        <Td>{index + 1}</Td>
                                        <Td
                                            style={{ cursor: "pointer" }}
                                            onClick={() => handleCandidatePreview(candidate)}
                                        >
                                            {candidate?.stuId}
                                        </Td>
                                        <Td
                                            style={{
                                                maxWidth: "250px",
                                                overflow: "hidden",
                                            }}
                                        >
                                            <Flex>
                                                <Avatar
                                                    isLazy
                                                    src={`${backendUrl}${candidate.dp}`}
                                                    size="sm"
                                                    name={candidate?.name}
                                                    mr={2}
                                                    background={"green"}
                                                    color={"white"}
                                                />
                                                <Box
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <Flex gap={1}>
                                                        {getGenderIcon(candidate.gender)}
                                                        <Text
                                                            fontSize={"sm"}
                                                            style={{
                                                                textOverflow: "ellipsis",
                                                                overflow: "hidden",
                                                                maxWidth: "180px",
                                                            }}
                                                        >
                                                            {candidate?.name}
                                                        </Text>
                                                    </Flex>
                                                    <Text
                                                        fontSize={"sm"}
                                                        color={"#a3a3a3"}
                                                        style={{
                                                            textOverflow: "ellipsis",
                                                            overflow: "hidden",
                                                            maxWidth: "180px",
                                                        }}
                                                    >
                                                        <a
                                                            target="_blank"
                                                            href={`mailto:${candidate?.email}`}
                                                        >
                                                            {candidate?.email}
                                                        </a>
                                                    </Text>
                                                </Box>
                                            </Flex>
                                        </Td>
                                        <Td>
                                            <Flex direction={"column"} fontSize={"xs"}>
                                                <Flex gap={1} alignItems={"center"}>
                                                    <a
                                                        target="_blank"
                                                        href={`tel:${candidate?.phone}`}
                                                    >
                                                        <IoCall color="cyan" />
                                                    </a>
                                                    <Text fontSize={"xs"}>
                                                        <a
                                                            target="_blank"
                                                            href={`tel:${candidate?.phone}`}
                                                        >
                                                            {candidate?.phone}
                                                        </a>
                                                    </Text>
                                                </Flex>
                                                <Flex gap={1} alignItems={"center"}>
                                                    <a
                                                        target="_blank"
                                                        href={`https://wa.me/${candidate?.whatsappNo}`}
                                                    >
                                                        <FaWhatsapp color="lime" />
                                                    </a>
                                                    <Text fontSize={"xs"}>
                                                        <a
                                                            target="_blank"
                                                            href={`https://wa.me/${candidate?.whatsappNo}`}
                                                        >
                                                            {candidate?.whatsappNo}
                                                        </a>
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                        </Td>
                                        <Td
                                            style={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                maxWidth: "200px",
                                            }}
                                        >
                                            <Popover>
                                                <PopoverTrigger>
                                                    <Text cursor={"pointer"}>
                                                        {printSkills(candidate?.skills, 25)}
                                                    </Text>
                                                </PopoverTrigger>
                                                <PopoverContent w={"200px"}>
                                                    <PopoverArrow />
                                                    <PopoverCloseButton />
                                                    <PopoverHeader>Skills</PopoverHeader>
                                                    <PopoverBody
                                                        style={{
                                                            textWrap: "wrap",
                                                            lineHeight: "normal",
                                                        }}
                                                    >
                                                        {candidate.skills?.map((skill, index) => (
                                                            <Text
                                                                key={index}
                                                            >{`${skill.skillName} - ${skill.relevantExp} Years`}</Text>
                                                        ))}
                                                    </PopoverBody>
                                                </PopoverContent>
                                            </Popover>
                                        </Td>
                                        <Td>
                                            <Flex
                                                direction={"column"}
                                                fontSize={"xs"}
                                                borderLeft={"3px solid"}
                                                borderColor={candidate?.isFresher ? "red" : "green"}
                                                paddingLeft={1}
                                            >
                                                <Text fontSize={"xs"} fontWeight={"bold"}>
                                                    {candidate?.experience}
                                                </Text>
                                                <Text fontSize={"xs"}>
                                                    ({candidate?.experienceType})
                                                </Text>
                                            </Flex>
                                        </Td>
                                        <Td>
                                            <Flex direction={"column"} fontSize={"xs"}>
                                                <Flex gap={1} alignItems={"center"}>
                                                    {candidate?.currentCTC ? (
                                                        <>
                                                            <Text fontSize={"xs"}>Curr.-</Text>
                                                            <Text
                                                                fontSize={"xs"}
                                                                fontWeight={"bold"}
                                                            >
                                                                {convertToLPA(
                                                                    candidate?.currentCTC
                                                                )}
                                                            </Text>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Text fontSize={"xs"}>Stipend-</Text>
                                                            <Text
                                                                fontSize={"xs"}
                                                                fontWeight={"bold"}
                                                            >
                                                                ₹ {candidate?.stipend}
                                                            </Text>
                                                        </>
                                                    )}
                                                </Flex>
                                                <Flex gap={1} alignItems={"center"}>
                                                    <Text fontSize={"xs"}>Exp.-</Text>
                                                    <Text fontSize={"xs"} fontWeight={"bold"}>
                                                        {convertToLPA(candidate?.expectedCTC)}
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                        </Td>
                                        <Td>
                                            <Flex direction={"column"}>
                                                <Text fontSize={"xs"}>
                                                    {candidate?.address?.city}
                                                    {","}
                                                </Text>
                                                <Text fontSize={"xs"}>
                                                    {candidate?.address?.state}
                                                </Text>
                                            </Flex>
                                        </Td>
                                        <Td>
                                            {candidate?.currentStatus == "Selected" && (
                                                <Badge variant="subtle" colorScheme="green">
                                                    Selected
                                                </Badge>
                                            )}
                                            {candidate?.currentStatus == "Processing" && (
                                                <Badge variant="subtle" colorScheme="blue">
                                                    Processing
                                                </Badge>
                                            )}
                                            {candidate?.currentStatus == "On Bench" && (
                                                <Badge variant="subtle" colorScheme="orange">
                                                    On Bench
                                                </Badge>
                                            )}
                                        </Td>
                                        <Td style={{ cursor: "pointer" }}>
                                            <Flex direction={"column"}>
                                                <Text>{formatDateTime(candidate.createdAt)}</Text>
                                                <Text fontSize={"sm"} color={"#a3a3a3"}>
                                                    {formatDateTime(candidate.updatedAt)}
                                                </Text>
                                            </Flex>
                                        </Td>
                                        <Td>
                                            <Menu>
                                                <MenuButton>
                                                    <GrMore />
                                                </MenuButton>
                                                <MenuList minW={"50px"}>
                                                    <MenuItem
                                                        onClick={() =>
                                                            handleCandidatePreview(candidate)
                                                        }
                                                    >
                                                        Candidate Details
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => {
                                                            handleProcessCandidateModalPreview(
                                                                candidate
                                                            );
                                                        }}
                                                    >
                                                        Process Candidate
                                                    </MenuItem>

                                                    <MenuDivider />

                                                    <MenuItem
                                                        onClick={() => {
                                                            handleCandidateForm(candidate);
                                                        }}
                                                        color={"#d8a953"}
                                                    >
                                                        Update Candidate
                                                    </MenuItem>
                                                    <MenuItem
                                                        color={"#f25c5c"}
                                                        onClick={() => {
                                                            deleteCandidate(
                                                                candidate._id,
                                                                candidate.currentStatus
                                                            );
                                                        }}
                                                    >
                                                        Delete Candidate
                                                    </MenuItem>
                                                </MenuList>
                                            </Menu>
                                        </Td>
                                    </Tr>
                                ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </InfiniteScroll>
            {selectedCandidate && isCandidateDetailsModalOpen && (
                <CandidateDetails
                    candidate={selectedCandidate}
                    isOpen={isCandidateDetailsModalOpen}
                    onClose={handleCandidateViewCloseModal}
                />
            )}
            {selectedCandidate && isCandidateFormModalOpen && (
                <CandidateForm
                    candidate={selectedCandidate}
                    operation={"Update Candidate"}
                    isOpen={isCandidateFormModalOpen}
                    onClose={handleCandidateFormCloseModal}
                />
            )}
            {selectedCandidate && isProcessCandidateModalOpen && (
                <ProcessCandidate
                    candidate={selectedCandidate}
                    isOpen={isProcessCandidateModalOpen}
                    onClose={handleProcessCandidateModalClose}
                />
            )}
        </div>
    );
};

export default CandidateBody;
