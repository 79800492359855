import React, { useEffect, useState } from "react";

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Box,
    Text,
    Avatar,
    Flex,
    FormControl,
    FormLabel,
    Textarea,
    Checkbox,
} from "@chakra-ui/react";

import { useEnv } from "../../../context/EnvContext";
import getGenderIcon from "../../../Utils/GlobalFunctions/GenderIcon";

const RecordCanCompMBP = ({ isOpen, onClose, application, markCanComMBP, isUpdating }) => {
    const { backendUrl } = useEnv();
    const [formData, setFormData] = useState({ isCompletedMBP: true, remarks: "" });

    useEffect(() => {
        if (isUpdating)
            setFormData({
                isCompletedMBP: application.billingApproved,
                remarks: application.rejectionRemarks || "",
            });
    }, [application]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFormReset = () => {
        if (isUpdating)
            setFormData({
                isCompletedMBP: application.billingApproved,
                remarks: application.rejectionRemarks || "",
            });
    };

    const handleFormSubmit = async () => {
        // call submit function
        const res = await markCanComMBP(application, formData, isUpdating);
        console.log("res", res);
        if (res?.success) {
            onClose();
        }
    };

    console.log("formData", formData);
    return (
        <div>
            <Modal
                isLazy
                isOpen={isOpen}
                onClose={onClose}
                size={"xl"}
                style={{ minHeight: "100vh" }}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        {isUpdating ? "Update" : "Record"} Candidate Status of{" "}
                        <b>{application.candidate.stuId}</b>, <b>{application.applicationID}</b> for{" "}
                        <span style={{ fontSize: "15px" }}>
                            completing Minimum Billing Period{" "}
                            {/* <b>{application.jobDescription.clientDetails.client.name}</b> */}
                        </span>
                        <Flex justify={"space-between"} w={"100%"} mt={2} gap={2}>
                            <Box background={"teal"} w={"50%"} px={2} py={1} borderRadius={"md"}>
                                <Text fontSize={"md"}>{application.jobDescription.jobId}</Text>
                                <Text
                                    fontSize={"sm"}
                                    color={"#c9c9c9"}
                                    style={{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        maxWidth: "200px",
                                    }}
                                >
                                    {application.jobDescription.jobTitle}
                                </Text>
                            </Box>{" "}
                            <Flex
                                align={"center"}
                                background={"teal"}
                                w={"50%"}
                                px={2}
                                py={1}
                                borderRadius={"md"}
                            >
                                <Avatar
                                    src={`${backendUrl}${application.candidate?.dp}`}
                                    size="sm"
                                    name={application.candidate.name}
                                    mr={2}
                                    background={"green"}
                                    color={"white"}
                                />

                                <Box
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Flex gap={1}>
                                        <Text
                                            fontSize={"sm"}
                                            style={{
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                maxWidth: "270px",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: "#c9c9c9",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {application.candidate.stuId}
                                            </span>
                                            {" - "}
                                            {application.candidate.name}
                                        </Text>
                                        {getGenderIcon(application.candidate?.gender)}
                                    </Flex>
                                    <Text
                                        fontSize={"sm"}
                                        color={"#c9c9c9"}
                                        style={{
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            maxWidth: "270px",
                                        }}
                                    >
                                        {application.candidate.email}
                                    </Text>
                                </Box>
                            </Flex>
                        </Flex>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody style={{ paddingBottom: "20px" }}>
                        {/* <hr /> */}
                        <FormControl id="isCompletedMBP" isRequired>
                            <Flex align={"center"} gap={2}>
                                <FormLabel>Does Candidate Completed MBP ?</FormLabel>
                                <Checkbox
                                    name="isFresher"
                                    isChecked={formData.isCompletedMBP}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            isCompletedMBP: e.target.checked,
                                        })
                                    }
                                >
                                    Yes
                                </Checkbox>
                                <Checkbox
                                    ml={3}
                                    name="isCompletedMBP"
                                    colorScheme="red"
                                    isChecked={!formData.isCompletedMBP}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            isCompletedMBP: !e.target.checked,
                                        })
                                    }
                                >
                                    No
                                </Checkbox>
                            </Flex>
                            <FormControl id="remarks" mt={3}>
                                <FormLabel>Remarks:</FormLabel>
                                <Textarea
                                    name="remarks"
                                    value={formData.remarks}
                                    onChange={handleChange}
                                    placeholder="Write your Remark Here"
                                    size="sm"
                                    borderRadius={"md"}
                                />
                            </FormControl>
                        </FormControl>
                    </ModalBody>{" "}
                    <ModalFooter>
                        <Button colorScheme="red" mr={3} size="sm" onClick={handleFormReset}>
                            Reset
                        </Button>
                        <Button colorScheme="blue" size="sm" onClick={handleFormSubmit}>
                            Submit
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default RecordCanCompMBP;
