import React from "react";
import { Box, Button, Flex, Skeleton, Spinner, Tag, Text } from "@chakra-ui/react";
import DonutChart from "../../../Utils/GlobalComponents/Charts/DonutChart";
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Tooltip,
} from "@chakra-ui/react";
import DonutChartFull from "../../../Utils/GlobalComponents/Charts/DonutChartFull";

const ApplicationOnProcess = ({ loading, applicationsOnProcessByStatusCount }) => {
    return (
        <Flex
            h={"420px"}
            flexGrow={1}
            bg={"#005f53"}
            borderRadius={"md"}
            p={4}
            overflowY={"clip"}
            position={"relative"}
            direction={"column"}
            align={"center"}
            gap={2}
        >
            <Flex direction={"column"} w={"100%"}>
                <Text fontSize={"sm"} fontWeight={"bold"} color={"white"} textAlign={"left"}>
                    Applications on Process
                </Text>
                <Text fontSize={"xs"} mt="-1px" color={"#ffffff9c"} textAlign={"left"} mb={1}>
                    Number at Various Stages
                </Text>
            </Flex>
            <Box w={"200px"}>
                {loading ? (
                    <Spinner
                        thickness="40px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="1"
                        height="200px"
                        width="200px"
                    />
                ) : (
                    <DonutChartFull
                        size={"200px"}
                        cutout={"60%"}
                        dataSet={[
                            {
                                label: "Not Started",
                                data: applicationsOnProcessByStatusCount?.totalNotStarted || 0,
                                color: "#FF6384",
                            },
                            {
                                label: "CV Process",
                                data: applicationsOnProcessByStatusCount?.totalOnCVProcess || 0,
                                color: "#36A2EB",
                            },
                            {
                                label: "Interview Process",
                                data:
                                    applicationsOnProcessByStatusCount?.totalOnInterviewProcess ||
                                    0,
                                color: "#FFCE56",
                            },
                            {
                                label: "Offer Process",
                                data: applicationsOnProcessByStatusCount?.totalOnOfferProcess || 0,
                                color: "#4BC0C0",
                            },
                            {
                                label: "Placement Process",
                                data:
                                    applicationsOnProcessByStatusCount?.totalOnPlacementProcess ||
                                    0,
                                color: "#9966FF",
                            },
                            {
                                label: "Billing Process",
                                data:
                                    applicationsOnProcessByStatusCount?.totalOnBillingProcess || 0,
                                color: "#FF9F40",
                            },
                        ]}
                    />
                )}
            </Box>
            <TableContainer w={"100%"}>
                <Table size="sm">
                    <Tbody>
                        <Tr>
                            <Td
                                borderStyle={"dashed"}
                                pr={0}
                                pl={2}
                                py={"2px"}
                                fontSize={"xs"}
                                color={"#ffffff9c"}
                            >
                                <Box
                                    h={"10px"}
                                    w={"30px"}
                                    borderRadius={"5px"}
                                    bg={"#FF6384"}
                                ></Box>
                            </Td>
                            <Td
                                borderStyle={"dashed"}
                                px={0}
                                py={"2px"}
                                fontSize={"xs"}
                                color={"#ffffff9c"}
                                fontWeight={"500"}
                            >
                                Not Started
                            </Td>
                            <Td
                                borderStyle={"dashed"}
                                px={0}
                                py={"2px"}
                                fontSize={"xs"}
                                color={"#ffffff9c"}
                            >
                                {applicationsOnProcessByStatusCount?.totalNotStarted || 0}
                            </Td>
                        </Tr>
                        <Tr>
                            <Td
                                borderStyle={"dashed"}
                                pr={0}
                                pl={2}
                                py={"2px"}
                                fontSize={"xs"}
                                color={"#ffffff9c"}
                            >
                                <Box
                                    h={"10px"}
                                    w={"30px"}
                                    borderRadius={"5px"}
                                    bg={"#36A2EB"}
                                ></Box>
                            </Td>
                            <Td
                                borderStyle={"dashed"}
                                px={0}
                                py={"2px"}
                                fontSize={"xs"}
                                color={"#ffffff9c"}
                                fontWeight={"500"}
                            >
                                CV Process
                            </Td>
                            <Td
                                borderStyle={"dashed"}
                                px={0}
                                py={"2px"}
                                fontSize={"xs"}
                                color={"#ffffff9c"}
                            >
                                {applicationsOnProcessByStatusCount?.totalOnCVProcess || 0}
                            </Td>
                        </Tr>
                        <Tr>
                            <Td
                                borderStyle={"dashed"}
                                pr={0}
                                pl={2}
                                py={"2px"}
                                fontSize={"xs"}
                                color={"#ffffff9c"}
                            >
                                <Box
                                    h={"10px"}
                                    w={"30px"}
                                    borderRadius={"5px"}
                                    bg={"#FFCE56"}
                                ></Box>
                            </Td>
                            <Td
                                borderStyle={"dashed"}
                                px={0}
                                py={"2px"}
                                fontSize={"xs"}
                                color={"#ffffff9c"}
                                fontWeight={"500"}
                            >
                                Interview Process
                            </Td>
                            <Td
                                borderStyle={"dashed"}
                                px={0}
                                py={"2px"}
                                fontSize={"xs"}
                                color={"#ffffff9c"}
                            >
                                {applicationsOnProcessByStatusCount?.totalOnInterviewProcess || 0}
                            </Td>
                        </Tr>
                        <Tr>
                            <Td
                                borderStyle={"dashed"}
                                pr={0}
                                pl={2}
                                py={"2px"}
                                fontSize={"xs"}
                                color={"#ffffff9c"}
                            >
                                <Box
                                    h={"10px"}
                                    w={"30px"}
                                    borderRadius={"5px"}
                                    bg={"#4BC0C0"}
                                ></Box>
                            </Td>
                            <Td
                                borderStyle={"dashed"}
                                px={0}
                                py={"2px"}
                                fontSize={"xs"}
                                color={"#ffffff9c"}
                                fontWeight={"500"}
                            >
                                Offer Process
                            </Td>
                            <Td
                                borderStyle={"dashed"}
                                px={0}
                                py={"2px"}
                                fontSize={"xs"}
                                color={"#ffffff9c"}
                            >
                                {applicationsOnProcessByStatusCount?.totalOnOfferProcess || 0}
                            </Td>
                        </Tr>
                        <Tr>
                            <Td
                                borderStyle={"dashed"}
                                pr={0}
                                pl={2}
                                py={"2px"}
                                fontSize={"xs"}
                                color={"#ffffff9c"}
                            >
                                <Box
                                    h={"10px"}
                                    w={"30px"}
                                    borderRadius={"5px"}
                                    bg={"#9966FF"}
                                ></Box>
                            </Td>
                            <Td
                                borderStyle={"dashed"}
                                px={0}
                                py={"2px"}
                                fontSize={"xs"}
                                color={"#ffffff9c"}
                                fontWeight={"500"}
                            >
                                Placement Process
                            </Td>
                            <Td
                                borderStyle={"dashed"}
                                px={0}
                                py={"2px"}
                                fontSize={"xs"}
                                color={"#ffffff9c"}
                            >
                                {applicationsOnProcessByStatusCount?.totalOnPlacementProcess || 0}
                            </Td>
                        </Tr>
                        <Tr>
                            <Td
                                borderStyle={"dashed"}
                                pr={0}
                                pl={2}
                                py={"2px"}
                                fontSize={"xs"}
                                color={"#ffffff9c"}
                            >
                                <Box
                                    h={"10px"}
                                    w={"30px"}
                                    borderRadius={"5px"}
                                    bg={"#FF9F40"}
                                ></Box>
                            </Td>
                            <Td
                                borderStyle={"dashed"}
                                px={0}
                                py={"2px"}
                                fontSize={"xs"}
                                color={"#ffffff9c"}
                                fontWeight={"500"}
                            >
                                Billing Process
                            </Td>
                            <Td
                                borderStyle={"dashed"}
                                px={0}
                                py={"2px"}
                                fontSize={"xs"}
                                color={"#ffffff9c"}
                            >
                                {applicationsOnProcessByStatusCount?.totalOnBillingProcess || 0}
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
        </Flex>
    );
};

export default ApplicationOnProcess;
