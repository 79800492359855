import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Tabs, TabList, TabPanels, Tab, TabPanel, Text } from "@chakra-ui/react";

import { tagColor } from "../../../Utils/GlobalFunctions/TagColor";
import { useAuth } from "../../../context/AuthContext";
import EmployeeHeader from "./EmployeeHeader";
import { useUser } from "../../../context/UserContext";
import EmployeeBody from "./EmployeeBody";

const EmployeeContainer = () => {
    const { loading, fetchAllUsers, allEmployees, hasMoreEmployees, employeesPageNo } = useUser();
    const { userData } = useAuth();

    const [tabIndex, setTabIndex] = useState(0);
    const { status } = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const initialPath = pathname.split("/")[1];

    console.log("allEmployees", allEmployees);
    useEffect(() => {
        console.log("userData**", userData);
        // if (!userData?.token) return;
        switch (status) {
            case "All":
                fetchAllUsers("all", 1, 30);
                setTabIndex(0);
                break;
            case "ActiveBDs":
                fetchAllUsers("activeBDs", 1, 30);
                setTabIndex(1);
                break;
            case "ActiveRecruiters":
                fetchAllUsers("activeRecruiters", 1, 30);
                setTabIndex(2);
                break;
            case "Blocked":
                fetchAllUsers("blocked", 1, 30);
                setTabIndex(3);
                break;
            case "Deleted":
                fetchAllUsers("deleted", 1, 30);
                setTabIndex(4);
                break;
            default:
                fetchAllUsers("all", 1, 30);
                setTabIndex(0);
                break;
        }
    }, [status]);

    const handleTabsChange = (index) => {
        setTabIndex(index);
        const tabMapping = ["All", "ActiveBDs", "ActiveRecruiters", "Blocked", "Deleted"];
        navigate(`/${initialPath}/Employees/${tabMapping[index]}`);
    };

    return (
        <>
            <Tabs isLazy variant="enclosed" index={tabIndex} onChange={handleTabsChange}>
                <EmployeeHeader filterData={""} initialPath={initialPath} />
                <TabList>
                    <Tab>
                        <Text
                            bg={tagColor("").bg}
                            color={tagColor("").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            All
                        </Text>
                    </Tab>
                    <Tab>
                        <Text
                            bg={tagColor("blue").bg}
                            color={tagColor("blue").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            Active BDs
                        </Text>
                    </Tab>
                    <Tab>
                        <Text
                            bg={tagColor("green").bg}
                            color={tagColor("green").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            Active Recruiters
                        </Text>
                    </Tab>
                    <Tab>
                        <Text
                            bg={tagColor("orange").bg}
                            color={tagColor("orange").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            Blocked
                        </Text>
                    </Tab>
                    <Tab>
                        <Text
                            bg={tagColor("red").bg}
                            color={tagColor("red").clr}
                            px={2}
                            borderRadius={20}
                            fontWeight="bold"
                            fontSize={"sm"}
                        >
                            Deleted
                        </Text>
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <EmployeeBody
                            data={allEmployees.all}
                            hasMore={hasMoreEmployees.all}
                            pageNo={employeesPageNo.all}
                            status={"all"}
                        />
                    </TabPanel>
                    <TabPanel>
                        <EmployeeBody
                            data={allEmployees.activeBDs}
                            hasMore={hasMoreEmployees.activeBDs}
                            pageNo={employeesPageNo.activeBDs}
                            status={"activeBDs"}
                        />
                    </TabPanel>
                    <TabPanel>
                        <EmployeeBody
                            data={allEmployees.activeRecruiters}
                            hasMore={hasMoreEmployees.activeRecruiters}
                            pageNo={employeesPageNo.activeRecruiters}
                            status={"activeRecruiters"}
                        />
                    </TabPanel>
                    <TabPanel>
                        <EmployeeBody
                            data={allEmployees.blocked}
                            hasMore={hasMoreEmployees.blocked}
                            pageNo={employeesPageNo.blocked}
                            status={"blocked"}
                        />
                    </TabPanel>
                    <TabPanel>
                        <EmployeeBody
                            data={allEmployees.deleted}
                            hasMore={hasMoreEmployees.deleted}
                            pageNo={employeesPageNo.deleted}
                            status={"deleted"}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    );
};

export default EmployeeContainer;
