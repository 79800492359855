import { Box, Flex, Heading, Skeleton, Text, Tooltip } from "@chakra-ui/react";
import React from "react";

const APT = ({ loading, todaysWorkCount }) => {
    console.log(todaysWorkCount);
    return (
        <Box
            h={"200px"}
            minW={"200px"}
            bg={"#22242b"}
            borderRadius={"md"}
            p={4}
            position={"relative"}
        >
            {loading ? (
                <Skeleton h="100%" w="100%" borderRadius={"md"} />
            ) : (
                <>
                    <Text fontSize={"sm"} fontWeight={"bold"} color={"white"} textAlign={"left"}>
                        Todays Lineup
                    </Text>
                    <Heading as="h2" size="xl" textAlign={"left"} pl={1} mt={4}>
                        {todaysWorkCount?.applicationsJoiningToday || 0}
                    </Heading>
                    <Heading as="h5" size="xs" textAlign={"left"} pl={1} color={"#ffffff9c"}>
                        Interviews Scheduled
                    </Heading>
                    <Heading as="h2" size="xl" textAlign={"left"} pl={1} mt={4}>
                        {todaysWorkCount?.interviewsScheduledToday || 0}
                    </Heading>
                    <Heading as="h5" size="xs" textAlign={"left"} pl={1} color={"#ffffff9c"}>
                        Joining Today
                    </Heading>
                </>
            )}
        </Box>
    );
};

export default APT;
